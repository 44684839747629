/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { ModerateCardContainer_viewer$ref } from "./ModerateCardContainer_viewer.graphql";
declare const _QueueRoute_viewer$ref: unique symbol;
export type QueueRoute_viewer$ref = typeof _QueueRoute_viewer$ref;
export type QueueRoute_viewer = {
    readonly " $fragmentRefs": ModerateCardContainer_viewer$ref;
    readonly " $refType": QueueRoute_viewer$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "QueueRoute_viewer",
    "type": "User",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "FragmentSpread",
            "name": "ModerateCardContainer_viewer",
            "args": null
        }
    ]
} as any);
(node as any).hash = '58f34c32911483727a7e3bad3b35965c';
export default node;
