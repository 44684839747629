/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type MODERATION_QUEUE = "PENDING" | "REPORTED" | "UNMODERATED" | "%future added value";
export type ModerateCountsCommentEnteredSubscriptionVariables = {
    readonly storyID?: string | null;
};
export type ModerateCountsCommentEnteredSubscriptionResponse = {
    readonly commentEnteredModerationQueue: {
        readonly queue: MODERATION_QUEUE;
    };
};
export type ModerateCountsCommentEnteredSubscription = {
    readonly response: ModerateCountsCommentEnteredSubscriptionResponse;
    readonly variables: ModerateCountsCommentEnteredSubscriptionVariables;
};



/*
subscription ModerateCountsCommentEnteredSubscription(
  $storyID: ID
) {
  commentEnteredModerationQueue(storyID: $storyID) {
    queue
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "storyID",
            "type": "ID",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "commentEnteredModerationQueue",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "storyID",
                    "variableName": "storyID"
                }
            ],
            "concreteType": "CommentEnteredModerationQueuePayload",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "queue",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "ModerateCountsCommentEnteredSubscription",
            "type": "Subscription",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "ModerateCountsCommentEnteredSubscription",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "subscription",
            "name": "ModerateCountsCommentEnteredSubscription",
            "id": "be7ccafe815018bfce6b690281036986",
            "text": null,
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'a07763f8228a518c55d23b0c094318fc';
export default node;
