/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _SMTP_formValues$ref: unique symbol;
export type SMTP_formValues$ref = typeof _SMTP_formValues$ref;
export type SMTP_formValues = {
    readonly enabled: boolean;
    readonly smtp: {
        readonly host: string | null;
        readonly port: number | null;
        readonly secure: boolean | null;
        readonly authentication: boolean | null;
        readonly username: string | null;
        readonly password: string | null;
    };
    readonly " $refType": SMTP_formValues$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "SMTP_formValues",
    "type": "EmailConfiguration",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "enabled",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "smtp",
            "storageKey": null,
            "args": null,
            "concreteType": "SMTP",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "host",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "port",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "secure",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "authentication",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "username",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "password",
                    "args": null,
                    "storageKey": null
                }
            ]
        }
    ]
} as any);
(node as any).hash = '59262f8bb5c07d414648bcdb5a368723';
export default node;
