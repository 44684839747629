/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
export type STORY_STATUS = "CLOSED" | "OPEN" | "%future added value";
declare const _StoryStatusChangeContainer_story$ref: unique symbol;
export type StoryStatusChangeContainer_story$ref = typeof _StoryStatusChangeContainer_story$ref;
export type StoryStatusChangeContainer_story = {
    readonly id: string;
    readonly status: STORY_STATUS;
    readonly " $refType": StoryStatusChangeContainer_story$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "StoryStatusChangeContainer_story",
    "type": "Story",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = '47b16e9de01cfa2c6732c2440c2cd332';
export default node;
