/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
export type COMMENT_STATUS = "APPROVED" | "NONE" | "PREMOD" | "REJECTED" | "SYSTEM_WITHHELD" | "%future added value";
declare const _DecisionHistoryItemContainer_action$ref: unique symbol;
export type DecisionHistoryItemContainer_action$ref = typeof _DecisionHistoryItemContainer_action$ref;
export type DecisionHistoryItemContainer_action = {
    readonly id: string;
    readonly revision: {
        readonly id: string;
        readonly comment: {
            readonly id: string;
            readonly author: {
                readonly username: string | null;
            } | null;
        };
    };
    readonly createdAt: string;
    readonly status: COMMENT_STATUS;
    readonly " $refType": DecisionHistoryItemContainer_action$ref;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Fragment",
        "name": "DecisionHistoryItemContainer_action",
        "type": "CommentModerationAction",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            (v0 /*: any*/),
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "revision",
                "storageKey": null,
                "args": null,
                "concreteType": "CommentRevision",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "comment",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Comment",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "author",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "User",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "username",
                                        "args": null,
                                        "storageKey": null
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "createdAt",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "status",
                "args": null,
                "storageKey": null
            }
        ]
    } as any;
})();
(node as any).hash = '5cd8255f0e6b320f9c2e829656274394';
export default node;
