/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _StoryCreationConfig_formValues$ref: unique symbol;
export type StoryCreationConfig_formValues$ref = typeof _StoryCreationConfig_formValues$ref;
export type StoryCreationConfig_formValues = {
    readonly stories: {
        readonly scraping: {
            readonly enabled: boolean;
            readonly proxyURL: string | null;
            readonly customUserAgent: string | null;
        };
        readonly disableLazy: boolean;
    };
    readonly " $refType": StoryCreationConfig_formValues$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "StoryCreationConfig_formValues",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "stories",
            "storageKey": null,
            "args": null,
            "concreteType": "StoryConfiguration",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "scraping",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StoryScrapingConfiguration",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "enabled",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "proxyURL",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "customUserAgent",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "disableLazy",
                    "args": null,
                    "storageKey": null
                }
            ]
        }
    ]
} as any);
(node as any).hash = '94db99e3981c49898589617531239b4d';
export default node;
