/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _SignInWithGoogleContainer_auth$ref: unique symbol;
export type SignInWithGoogleContainer_auth$ref = typeof _SignInWithGoogleContainer_auth$ref;
export type SignInWithGoogleContainer_auth = {
    readonly integrations: {
        readonly google: {
            readonly redirectURL: string;
        };
    };
    readonly " $refType": SignInWithGoogleContainer_auth$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "SignInWithGoogleContainer_auth",
    "type": "Auth",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "integrations",
            "storageKey": null,
            "args": null,
            "concreteType": "AuthIntegrations",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "google",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "GoogleAuthIntegration",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "redirectURL",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        }
    ]
} as any);
(node as any).hash = '9a6278fcee90b132a509f5a927b230b1';
export default node;
