/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { CommentRevisionContainer_settings$ref } from "./CommentRevisionContainer_settings.graphql";
import { FlagDetailsContainer_settings$ref } from "./FlagDetailsContainer_settings.graphql";
import { LinkDetailsContainer_settings$ref } from "./LinkDetailsContainer_settings.graphql";
declare const _ModerateCardDetailsContainer_settings$ref: unique symbol;
export type ModerateCardDetailsContainer_settings$ref = typeof _ModerateCardDetailsContainer_settings$ref;
export type ModerateCardDetailsContainer_settings = {
    readonly " $fragmentRefs": FlagDetailsContainer_settings$ref & CommentRevisionContainer_settings$ref & LinkDetailsContainer_settings$ref;
    readonly " $refType": ModerateCardDetailsContainer_settings$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "ModerateCardDetailsContainer_settings",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "FragmentSpread",
            "name": "FlagDetailsContainer_settings",
            "args": null
        },
        {
            "kind": "FragmentSpread",
            "name": "CommentRevisionContainer_settings",
            "args": null
        },
        {
            "kind": "FragmentSpread",
            "name": "LinkDetailsContainer_settings",
            "args": null
        }
    ]
} as any);
(node as any).hash = '29265e91ffc9d7a6e9d939b09c5cb0fc';
export default node;
