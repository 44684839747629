/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _CommentRevisionContainer_comment$ref: unique symbol;
export type CommentRevisionContainer_comment$ref = typeof _CommentRevisionContainer_comment$ref;
export type CommentRevisionContainer_comment = {
    readonly revision: {
        readonly id: string;
    } | null;
    readonly revisionHistory: ReadonlyArray<{
        readonly id: string;
        readonly body: string | null;
        readonly createdAt: string;
    }>;
    readonly " $refType": CommentRevisionContainer_comment$ref;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Fragment",
        "name": "CommentRevisionContainer_comment",
        "type": "Comment",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "revision",
                "storageKey": null,
                "args": null,
                "concreteType": "CommentRevision",
                "plural": false,
                "selections": [
                    (v0 /*: any*/)
                ]
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "revisionHistory",
                "storageKey": null,
                "args": null,
                "concreteType": "CommentRevision",
                "plural": true,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "body",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                    }
                ]
            }
        ]
    } as any;
})();
(node as any).hash = 'db950ae3468ff98903d9ff3f0bd48628';
export default node;
