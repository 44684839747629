/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _UserDrawerNotesContainer_viewer$ref: unique symbol;
export type UserDrawerNotesContainer_viewer$ref = typeof _UserDrawerNotesContainer_viewer$ref;
export type UserDrawerNotesContainer_viewer = {
    readonly id: string;
    readonly " $refType": UserDrawerNotesContainer_viewer$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "UserDrawerNotesContainer_viewer",
    "type": "User",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = '8b9fa1c3e7efdcbb0188b5d262e83a8b';
export default node;
