/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { OrganizationConfigContainer_settings$ref } from "./OrganizationConfigContainer_settings.graphql";
export type OrganizationConfigRouteQueryVariables = {};
export type OrganizationConfigRouteQueryResponse = {
    readonly settings: {
        readonly " $fragmentRefs": OrganizationConfigContainer_settings$ref;
    };
};
export type OrganizationConfigRouteQuery = {
    readonly response: OrganizationConfigRouteQueryResponse;
    readonly variables: OrganizationConfigRouteQueryVariables;
};



/*
query OrganizationConfigRouteQuery {
  settings {
    ...OrganizationConfigContainer_settings
    id
  }
}

fragment OrganizationConfigContainer_settings on Settings {
  organization {
    name
    contactEmail
    url
  }
}
*/

const node: ConcreteRequest = ({
    "kind": "Request",
    "fragment": {
        "kind": "Fragment",
        "name": "OrganizationConfigRouteQuery",
        "type": "Query",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "settings",
                "storageKey": null,
                "args": null,
                "concreteType": "Settings",
                "plural": false,
                "selections": [
                    {
                        "kind": "FragmentSpread",
                        "name": "OrganizationConfigContainer_settings",
                        "args": null
                    }
                ]
            }
        ]
    },
    "operation": {
        "kind": "Operation",
        "name": "OrganizationConfigRouteQuery",
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "settings",
                "storageKey": null,
                "args": null,
                "concreteType": "Settings",
                "plural": false,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "organization",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Organization",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "name",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "contactEmail",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "url",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "id",
                        "args": null,
                        "storageKey": null
                    }
                ]
            }
        ]
    },
    "params": {
        "operationKind": "query",
        "name": "OrganizationConfigRouteQuery",
        "id": "994b52ce7ac90b81d75e5bc8a8bfe176",
        "text": null,
        "metadata": {}
    }
} as any);
(node as any).hash = '013c5285175b1997de092a7cc15cd6cb';
export default node;
