/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { InviteUsersContainer_settings$ref } from "./InviteUsersContainer_settings.graphql";
import { InviteUsersContainer_viewer$ref } from "./InviteUsersContainer_viewer.graphql";
import { UserRowContainer_settings$ref } from "./UserRowContainer_settings.graphql";
import { UserRowContainer_user$ref } from "./UserRowContainer_user.graphql";
import { UserRowContainer_viewer$ref } from "./UserRowContainer_viewer.graphql";
declare const _UserTableContainer_query$ref: unique symbol;
export type UserTableContainer_query$ref = typeof _UserTableContainer_query$ref;
export type UserTableContainer_query = {
    readonly viewer: {
        readonly " $fragmentRefs": UserRowContainer_viewer$ref & InviteUsersContainer_viewer$ref;
    } | null;
    readonly settings: {
        readonly " $fragmentRefs": InviteUsersContainer_settings$ref & UserRowContainer_settings$ref;
    };
    readonly users: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": UserRowContainer_user$ref;
            };
        }>;
    };
    readonly " $refType": UserTableContainer_query$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "UserTableContainer_query",
    "type": "Query",
    "metadata": {
        "connection": [
            {
                "count": "count",
                "cursor": "cursor",
                "direction": "forward",
                "path": [
                    "users"
                ]
            }
        ]
    },
    "argumentDefinitions": [
        {
            "kind": "LocalArgument",
            "name": "count",
            "type": "Int!",
            "defaultValue": 10
        },
        {
            "kind": "LocalArgument",
            "name": "cursor",
            "type": "Cursor",
            "defaultValue": null
        },
        {
            "kind": "LocalArgument",
            "name": "roleFilter",
            "type": "USER_ROLE",
            "defaultValue": null
        },
        {
            "kind": "LocalArgument",
            "name": "statusFilter",
            "type": "USER_STATUS",
            "defaultValue": null
        },
        {
            "kind": "LocalArgument",
            "name": "searchFilter",
            "type": "String",
            "defaultValue": null
        }
    ],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
                {
                    "kind": "FragmentSpread",
                    "name": "UserRowContainer_viewer",
                    "args": null
                },
                {
                    "kind": "FragmentSpread",
                    "name": "InviteUsersContainer_viewer",
                    "args": null
                }
            ]
        },
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "settings",
            "storageKey": null,
            "args": null,
            "concreteType": "Settings",
            "plural": false,
            "selections": [
                {
                    "kind": "FragmentSpread",
                    "name": "InviteUsersContainer_settings",
                    "args": null
                },
                {
                    "kind": "FragmentSpread",
                    "name": "UserRowContainer_settings",
                    "args": null
                }
            ]
        },
        {
            "kind": "LinkedField",
            "alias": "users",
            "name": "__UserTable_users_connection",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "query",
                    "variableName": "searchFilter"
                },
                {
                    "kind": "Variable",
                    "name": "role",
                    "variableName": "roleFilter"
                },
                {
                    "kind": "Variable",
                    "name": "status",
                    "variableName": "statusFilter"
                }
            ],
            "concreteType": "UsersConnection",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserEdge",
                    "plural": true,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "User",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "id",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "__typename",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "FragmentSpread",
                                    "name": "UserRowContainer_user",
                                    "args": null
                                }
                            ]
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "cursor",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "pageInfo",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "endCursor",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "hasNextPage",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        }
    ]
} as any);
(node as any).hash = '43878979751fe8d36b8093296bd3b7e4';
export default node;
