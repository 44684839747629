/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _SSOKeyFieldContainer_sso$ref: unique symbol;
export type SSOKeyFieldContainer_sso$ref = typeof _SSOKeyFieldContainer_sso$ref;
export type SSOKeyFieldContainer_sso = {
    readonly key: string | null;
    readonly keyGeneratedAt: string | null;
    readonly " $refType": SSOKeyFieldContainer_sso$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "SSOKeyFieldContainer_sso",
    "type": "SSOAuthIntegration",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "key",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "keyGeneratedAt",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = 'f8ed0a482773237ecd868de7195d51fa';
export default node;
