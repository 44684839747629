/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { RecentHistoryContainer_user$ref } from "./RecentHistoryContainer_user.graphql";
import { UserBadgesContainer_user$ref } from "./UserBadgesContainer_user.graphql";
import { UserStatusChangeContainer_user$ref } from "./UserStatusChangeContainer_user.graphql";
import { UserStatusDetailsContainer_user$ref } from "./UserStatusDetailsContainer_user.graphql";
declare const _UserHistoryDrawerContainer_user$ref: unique symbol;
export type UserHistoryDrawerContainer_user$ref = typeof _UserHistoryDrawerContainer_user$ref;
export type UserHistoryDrawerContainer_user = {
    readonly moderatorNotes: ReadonlyArray<{
        readonly id: string;
    }>;
    readonly id: string;
    readonly username: string | null;
    readonly email: string | null;
    readonly createdAt: string;
    readonly " $fragmentRefs": UserBadgesContainer_user$ref & UserStatusChangeContainer_user$ref & UserStatusDetailsContainer_user$ref & RecentHistoryContainer_user$ref;
    readonly " $refType": UserHistoryDrawerContainer_user$ref;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Fragment",
        "name": "UserHistoryDrawerContainer_user",
        "type": "User",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "moderatorNotes",
                "storageKey": null,
                "args": null,
                "concreteType": "ModeratorNote",
                "plural": true,
                "selections": [
                    (v0 /*: any*/)
                ]
            },
            (v0 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "username",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "email",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "createdAt",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "FragmentSpread",
                "name": "UserBadgesContainer_user",
                "args": null
            },
            {
                "kind": "FragmentSpread",
                "name": "UserStatusChangeContainer_user",
                "args": null
            },
            {
                "kind": "FragmentSpread",
                "name": "UserStatusDetailsContainer_user",
                "args": null
            },
            {
                "kind": "FragmentSpread",
                "name": "RecentHistoryContainer_user",
                "args": null
            }
        ]
    } as any;
})();
(node as any).hash = '2ec3af80620d9227536b99d3c5360ed9';
export default node;
