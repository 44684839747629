/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _From_formValues$ref: unique symbol;
export type From_formValues$ref = typeof _From_formValues$ref;
export type From_formValues = {
    readonly enabled: boolean;
    readonly fromName: string | null;
    readonly fromEmail: string | null;
    readonly " $refType": From_formValues$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "From_formValues",
    "type": "EmailConfiguration",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "enabled",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "fromName",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "fromEmail",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = '217b6ffa441b69b63855358cf785cc86';
export default node;
