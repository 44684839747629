/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { DecisionHistoryItemContainer_action$ref } from "./DecisionHistoryItemContainer_action.graphql";
declare const _DecisionHistoryContainer_viewer$ref: unique symbol;
export type DecisionHistoryContainer_viewer$ref = typeof _DecisionHistoryContainer_viewer$ref;
export type DecisionHistoryContainer_viewer = {
    readonly commentModerationActionHistory: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": DecisionHistoryItemContainer_action$ref;
            };
        }>;
    };
    readonly " $refType": DecisionHistoryContainer_viewer$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "DecisionHistoryContainer_viewer",
    "type": "User",
    "metadata": {
        "connection": [
            {
                "count": "count",
                "cursor": "cursor",
                "direction": "forward",
                "path": [
                    "commentModerationActionHistory"
                ]
            }
        ]
    },
    "argumentDefinitions": [
        {
            "kind": "LocalArgument",
            "name": "count",
            "type": "Int!",
            "defaultValue": 5
        },
        {
            "kind": "LocalArgument",
            "name": "cursor",
            "type": "Cursor",
            "defaultValue": null
        }
    ],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": "commentModerationActionHistory",
            "name": "__DecisionHistory_commentModerationActionHistory_connection",
            "storageKey": null,
            "args": null,
            "concreteType": "CommentModerationActionConnection",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CommentModerationActionEdge",
                    "plural": true,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CommentModerationAction",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "id",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "__typename",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "FragmentSpread",
                                    "name": "DecisionHistoryItemContainer_action",
                                    "args": null
                                }
                            ]
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "cursor",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "pageInfo",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "endCursor",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "hasNextPage",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        }
    ]
} as any);
(node as any).hash = '7b0bc3a77652e200dc68c4d028f9c79c';
export default node;
