/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { ModerateCardContainer_settings$ref } from "./ModerateCardContainer_settings.graphql";
declare const _UserHistoryDrawerRejectedComments_settings$ref: unique symbol;
export type UserHistoryDrawerRejectedComments_settings$ref = typeof _UserHistoryDrawerRejectedComments_settings$ref;
export type UserHistoryDrawerRejectedComments_settings = {
    readonly " $fragmentRefs": ModerateCardContainer_settings$ref;
    readonly " $refType": UserHistoryDrawerRejectedComments_settings$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "UserHistoryDrawerRejectedComments_settings",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "FragmentSpread",
            "name": "ModerateCardContainer_settings",
            "args": null
        }
    ]
} as any);
(node as any).hash = '321b459bb48e5f7511932b8896aa689c';
export default node;
