/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { InviteCompleteFormContainer_settings$ref } from "./InviteCompleteFormContainer_settings.graphql";
import { SuccessContainer_settings$ref } from "./SuccessContainer_settings.graphql";
export type InviteRouteQueryVariables = {};
export type InviteRouteQueryResponse = {
    readonly settings: {
        readonly " $fragmentRefs": InviteCompleteFormContainer_settings$ref & SuccessContainer_settings$ref;
    };
};
export type InviteRouteQuery = {
    readonly response: InviteRouteQueryResponse;
    readonly variables: InviteRouteQueryVariables;
};



/*
query InviteRouteQuery {
  settings {
    ...InviteCompleteFormContainer_settings
    ...SuccessContainer_settings
    id
  }
}

fragment InviteCompleteFormContainer_settings on Settings {
  organization {
    name
  }
}

fragment SuccessContainer_settings on Settings {
  organization {
    name
    url
  }
}
*/

const node: ConcreteRequest = ({
    "kind": "Request",
    "fragment": {
        "kind": "Fragment",
        "name": "InviteRouteQuery",
        "type": "Query",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "settings",
                "storageKey": null,
                "args": null,
                "concreteType": "Settings",
                "plural": false,
                "selections": [
                    {
                        "kind": "FragmentSpread",
                        "name": "InviteCompleteFormContainer_settings",
                        "args": null
                    },
                    {
                        "kind": "FragmentSpread",
                        "name": "SuccessContainer_settings",
                        "args": null
                    }
                ]
            }
        ]
    },
    "operation": {
        "kind": "Operation",
        "name": "InviteRouteQuery",
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "settings",
                "storageKey": null,
                "args": null,
                "concreteType": "Settings",
                "plural": false,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "organization",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Organization",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "name",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "url",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "id",
                        "args": null,
                        "storageKey": null
                    }
                ]
            }
        ]
    },
    "params": {
        "operationKind": "query",
        "name": "InviteRouteQuery",
        "id": "f6f9f9a35601a916e9157c51ca4766d7",
        "text": null,
        "metadata": {}
    }
} as any);
(node as any).hash = 'db63f815e30e55a9371a5b8f62688890';
export default node;
