/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _ModeratedByContainer_viewer$ref: unique symbol;
export type ModeratedByContainer_viewer$ref = typeof _ModeratedByContainer_viewer$ref;
export type ModeratedByContainer_viewer = {
    readonly id: string;
    readonly username: string | null;
    readonly " $refType": ModeratedByContainer_viewer$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "ModeratedByContainer_viewer",
    "type": "User",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "username",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = '467e701ffa7272030c96ed4feef8cdb4';
export default node;
