/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { UserDrawerAccountHistory_user$ref } from "./UserDrawerAccountHistory_user.graphql";
export type UserDrawerAccountHistoryQueryVariables = {
    readonly userID: string;
};
export type UserDrawerAccountHistoryQueryResponse = {
    readonly user: {
        readonly " $fragmentRefs": UserDrawerAccountHistory_user$ref;
    } | null;
};
export type UserDrawerAccountHistoryQuery = {
    readonly response: UserDrawerAccountHistoryQueryResponse;
    readonly variables: UserDrawerAccountHistoryQueryVariables;
};



/*
query UserDrawerAccountHistoryQuery(
  $userID: ID!
) {
  user(id: $userID) {
    ...UserDrawerAccountHistory_user
    id
  }
}

fragment UserDrawerAccountHistory_user on User {
  status {
    username {
      history {
        username
        createdAt
        createdBy {
          username
          id
        }
      }
    }
    ban {
      history {
        active
        createdBy {
          username
          id
        }
        createdAt
      }
    }
    premod {
      history {
        active
        createdBy {
          username
          id
        }
        createdAt
      }
    }
    suspension {
      history {
        active
        from {
          start
          finish
        }
        createdBy {
          username
          id
        }
        modifiedAt
        modifiedBy {
          username
          id
        }
        createdAt
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "userID",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "userID"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "username",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "createdAt",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v5 = [
        (v2 /*: any*/),
        (v4 /*: any*/)
    ], v6 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "createdBy",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": (v5 /*: any*/)
    } as any), v7 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "active",
        "args": null,
        "storageKey": null
    } as any), v8 = [
        (v7 /*: any*/),
        (v6 /*: any*/),
        (v3 /*: any*/)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "UserDrawerAccountHistoryQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "UserDrawerAccountHistory_user",
                            "args": null
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "UserDrawerAccountHistoryQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "status",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "UserStatus",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "username",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "UsernameStatus",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "history",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "UsernameHistory",
                                            "plural": true,
                                            "selections": [
                                                (v2 /*: any*/),
                                                (v3 /*: any*/),
                                                (v6 /*: any*/)
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "ban",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "BanStatus",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "history",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "BanStatusHistory",
                                            "plural": true,
                                            "selections": (v8 /*: any*/)
                                        }
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "premod",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "PremodStatus",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "history",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "PremodStatusHistory",
                                            "plural": true,
                                            "selections": (v8 /*: any*/)
                                        }
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "suspension",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "SuspensionStatus",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "history",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "SuspensionStatusHistory",
                                            "plural": true,
                                            "selections": [
                                                (v7 /*: any*/),
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "from",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "TimeRange",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "kind": "ScalarField",
                                                            "alias": null,
                                                            "name": "start",
                                                            "args": null,
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "kind": "ScalarField",
                                                            "alias": null,
                                                            "name": "finish",
                                                            "args": null,
                                                            "storageKey": null
                                                        }
                                                    ]
                                                },
                                                (v6 /*: any*/),
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "modifiedAt",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "modifiedBy",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "User",
                                                    "plural": false,
                                                    "selections": (v5 /*: any*/)
                                                },
                                                (v3 /*: any*/)
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        (v4 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "UserDrawerAccountHistoryQuery",
            "id": "f6433aec192fbc893c2ac78c5119870a",
            "text": null,
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'ce3871731c6080ad051a384610d29c60';
export default node;
