/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { ModerateCardContainer_viewer$ref } from "./ModerateCardContainer_viewer.graphql";
declare const _UserHistoryDrawerAllComments_viewer$ref: unique symbol;
export type UserHistoryDrawerAllComments_viewer$ref = typeof _UserHistoryDrawerAllComments_viewer$ref;
export type UserHistoryDrawerAllComments_viewer = {
    readonly " $fragmentRefs": ModerateCardContainer_viewer$ref;
    readonly " $refType": UserHistoryDrawerAllComments_viewer$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "UserHistoryDrawerAllComments_viewer",
    "type": "User",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "FragmentSpread",
            "name": "ModerateCardContainer_viewer",
            "args": null
        }
    ]
} as any);
(node as any).hash = 'e99c6e4646037c1d4aa0f89d823114e5';
export default node;
