/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _AccountFeaturesConfig_formValues$ref: unique symbol;
export type AccountFeaturesConfig_formValues$ref = typeof _AccountFeaturesConfig_formValues$ref;
export type AccountFeaturesConfig_formValues = {
    readonly accountFeatures: {
        readonly changeUsername: boolean;
        readonly deleteAccount: boolean;
        readonly downloadComments: boolean;
    };
    readonly " $refType": AccountFeaturesConfig_formValues$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "AccountFeaturesConfig_formValues",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "accountFeatures",
            "storageKey": null,
            "args": null,
            "concreteType": "CommenterAccountFeatures",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "changeUsername",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "deleteAccount",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "downloadComments",
                    "args": null,
                    "storageKey": null
                }
            ]
        }
    ]
} as any);
(node as any).hash = 'fec76a505a19da849531b34f0b278898';
export default node;
