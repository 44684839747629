/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
export type View = "ADD_EMAIL_ADDRESS" | "CREATE_PASSWORD" | "CREATE_USERNAME" | "SIGN_IN" | "%future added value";
declare const _LoginRouteLocal$ref: unique symbol;
export type LoginRouteLocal$ref = typeof _LoginRouteLocal$ref;
export type LoginRouteLocal = {
    readonly authView: View | null;
    readonly " $refType": LoginRouteLocal$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "LoginRouteLocal",
    "type": "Local",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ClientExtension",
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "authView",
                    "args": null,
                    "storageKey": null
                }
            ]
        }
    ]
} as any);
(node as any).hash = '5994ccee5a031fc78c89c9841fb14594';
export default node;
