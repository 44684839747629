/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MODERATOR" | "STAFF" | "%future added value";
declare const _NavigationContainer_viewer$ref: unique symbol;
export type NavigationContainer_viewer$ref = typeof _NavigationContainer_viewer$ref;
export type NavigationContainer_viewer = {
    readonly role: USER_ROLE;
    readonly " $refType": NavigationContainer_viewer$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "NavigationContainer_viewer",
    "type": "User",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "role",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = '534f7fccf30aabf81973cec7ab1003a5';
export default node;
