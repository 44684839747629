/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { UserTableContainer_query$ref } from "./UserTableContainer_query.graphql";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MODERATOR" | "STAFF" | "%future added value";
export type USER_STATUS = "ACTIVE" | "BANNED" | "PREMOD" | "SUSPENDED" | "%future added value";
export type UserTableContainerPaginationQueryVariables = {
    readonly count: number;
    readonly cursor?: unknown | null;
    readonly roleFilter?: USER_ROLE | null;
    readonly statusFilter?: USER_STATUS | null;
    readonly searchFilter?: string | null;
};
export type UserTableContainerPaginationQueryResponse = {
    readonly " $fragmentRefs": UserTableContainer_query$ref;
};
export type UserTableContainerPaginationQuery = {
    readonly response: UserTableContainerPaginationQueryResponse;
    readonly variables: UserTableContainerPaginationQueryVariables;
};



/*
query UserTableContainerPaginationQuery(
  $count: Int!
  $cursor: Cursor
  $roleFilter: USER_ROLE
  $statusFilter: USER_STATUS
  $searchFilter: String
) {
  ...UserTableContainer_query_4gvvZy
}

fragment UserTableContainer_query_4gvvZy on Query {
  viewer {
    ...UserRowContainer_viewer
    ...InviteUsersContainer_viewer
    id
  }
  settings {
    ...InviteUsersContainer_settings
    ...UserRowContainer_settings
    id
  }
  users(first: $count, after: $cursor, role: $roleFilter, status: $statusFilter, query: $searchFilter) {
    edges {
      node {
        id
        ...UserRowContainer_user
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment UserRowContainer_viewer on User {
  ...UserRoleChangeContainer_viewer
}

fragment InviteUsersContainer_viewer on User {
  role
}

fragment InviteUsersContainer_settings on Settings {
  email {
    enabled
  }
  auth {
    integrations {
      local {
        enabled
        allowRegistration
        targetFilter {
          admin
        }
      }
    }
  }
}

fragment UserRowContainer_settings on Settings {
  ...UserStatusChangeContainer_settings
}

fragment UserRowContainer_user on User {
  ...UserStatusChangeContainer_user
  ...UserRoleChangeContainer_user
  id
  username
  email
  createdAt
  deletedAt
}

fragment UserStatusChangeContainer_user on User {
  id
  role
  username
  status {
    ban {
      active
    }
    suspension {
      active
    }
    premod {
      active
    }
  }
  ...UserStatusContainer_user
}

fragment UserRoleChangeContainer_user on User {
  id
  role
}

fragment UserStatusContainer_user on User {
  status {
    current
  }
}

fragment UserStatusChangeContainer_settings on Settings {
  organization {
    name
  }
}

fragment UserRoleChangeContainer_viewer on User {
  id
  role
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "count",
            "type": "Int!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "cursor",
            "type": "Cursor",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "roleFilter",
            "type": "USER_ROLE",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "statusFilter",
            "type": "USER_STATUS",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "searchFilter",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "role",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "enabled",
        "args": null,
        "storageKey": null
    } as any), v4 = [
        ({
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any),
        ({
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any),
        ({
            "kind": "Variable",
            "name": "query",
            "variableName": "searchFilter"
        } as any),
        ({
            "kind": "Variable",
            "name": "role",
            "variableName": "roleFilter"
        } as any),
        ({
            "kind": "Variable",
            "name": "status",
            "variableName": "statusFilter"
        } as any)
    ], v5 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "active",
            "args": null,
            "storageKey": null
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "UserTableContainerPaginationQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "FragmentSpread",
                    "name": "UserTableContainer_query",
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "count",
                            "variableName": "count"
                        },
                        {
                            "kind": "Variable",
                            "name": "cursor",
                            "variableName": "cursor"
                        },
                        {
                            "kind": "Variable",
                            "name": "roleFilter",
                            "variableName": "roleFilter"
                        },
                        {
                            "kind": "Variable",
                            "name": "searchFilter",
                            "variableName": "searchFilter"
                        },
                        {
                            "kind": "Variable",
                            "name": "statusFilter",
                            "variableName": "statusFilter"
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "UserTableContainerPaginationQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "viewer",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/)
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "settings",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Settings",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "email",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "EmailConfiguration",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/)
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "auth",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Auth",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "integrations",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "AuthIntegrations",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "local",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "LocalAuthIntegration",
                                            "plural": false,
                                            "selections": [
                                                (v3 /*: any*/),
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "allowRegistration",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "targetFilter",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "AuthenticationTargetFilter",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "kind": "ScalarField",
                                                            "alias": null,
                                                            "name": "admin",
                                                            "args": null,
                                                            "storageKey": null
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "organization",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Organization",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "name",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        (v1 /*: any*/)
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "users",
                    "storageKey": null,
                    "args": (v4 /*: any*/),
                    "concreteType": "UsersConnection",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "UserEdge",
                            "plural": true,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "plural": false,
                                    "selections": [
                                        (v1 /*: any*/),
                                        (v2 /*: any*/),
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "username",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "status",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "UserStatus",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "ban",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "BanStatus",
                                                    "plural": false,
                                                    "selections": (v5 /*: any*/)
                                                },
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "suspension",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "SuspensionStatus",
                                                    "plural": false,
                                                    "selections": (v5 /*: any*/)
                                                },
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "premod",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "PremodStatus",
                                                    "plural": false,
                                                    "selections": (v5 /*: any*/)
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "current",
                                                    "args": null,
                                                    "storageKey": null
                                                }
                                            ]
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "email",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "createdAt",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "deletedAt",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "__typename",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "cursor",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "pageInfo",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "endCursor",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "hasNextPage",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                },
                {
                    "kind": "LinkedHandle",
                    "alias": null,
                    "name": "users",
                    "args": (v4 /*: any*/),
                    "handle": "connection",
                    "key": "UserTable_users",
                    "filters": [
                        "role",
                        "status",
                        "query"
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "UserTableContainerPaginationQuery",
            "id": "db9b9ae5bd172b04f7f3aa777ef0eb4e",
            "text": null,
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'bdd4e1fbccb5f645ddd872aa9b34ebca';
export default node;
