/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _SessionConfig_formValues$ref: unique symbol;
export type SessionConfig_formValues$ref = typeof _SessionConfig_formValues$ref;
export type SessionConfig_formValues = {
    readonly sessionDuration: number;
    readonly " $refType": SessionConfig_formValues$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "SessionConfig_formValues",
    "type": "Auth",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "sessionDuration",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = '613b91f551c61ec11001db27756e30b7';
export default node;
