/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _AnnouncementConfigContainer_settings$ref: unique symbol;
export type AnnouncementConfigContainer_settings$ref = typeof _AnnouncementConfigContainer_settings$ref;
export type AnnouncementConfigContainer_settings = {
    readonly announcement: {
        readonly content: string;
        readonly duration: number;
        readonly createdAt: string;
    } | null;
    readonly " $refType": AnnouncementConfigContainer_settings$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "AnnouncementConfigContainer_settings",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "announcement",
            "storageKey": null,
            "args": null,
            "concreteType": "Announcement",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "content",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "duration",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "createdAt",
                    "args": null,
                    "storageKey": null
                }
            ]
        }
    ]
} as any);
(node as any).hash = '3a1539284cfe9976f8d6bc202162e586';
export default node;
