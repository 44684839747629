/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _StaffConfig_formValues$ref: unique symbol;
export type StaffConfig_formValues$ref = typeof _StaffConfig_formValues$ref;
export type StaffConfig_formValues = {
    readonly staff: {
        readonly label: string;
    };
    readonly " $refType": StaffConfig_formValues$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "StaffConfig_formValues",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "staff",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffConfiguration",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "label",
                    "args": null,
                    "storageKey": null
                }
            ]
        }
    ]
} as any);
(node as any).hash = '79a94adccad3348db34a6f581e2c4844';
export default node;
