/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { ModeratedByContainer_viewer$ref } from "./ModeratedByContainer_viewer.graphql";
declare const _ModerateCardContainer_viewer$ref: unique symbol;
export type ModerateCardContainer_viewer$ref = typeof _ModerateCardContainer_viewer$ref;
export type ModerateCardContainer_viewer = {
    readonly " $fragmentRefs": ModeratedByContainer_viewer$ref;
    readonly " $refType": ModerateCardContainer_viewer$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "ModerateCardContainer_viewer",
    "type": "User",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "FragmentSpread",
            "name": "ModeratedByContainer_viewer",
            "args": null
        }
    ]
} as any);
(node as any).hash = '4648b242237c415876dfa840d17d8849';
export default node;
