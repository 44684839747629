/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { RecentHistoryContainer_settings$ref } from "./RecentHistoryContainer_settings.graphql";
import { UserStatusChangeContainer_settings$ref } from "./UserStatusChangeContainer_settings.graphql";
declare const _UserHistoryDrawerContainer_settings$ref: unique symbol;
export type UserHistoryDrawerContainer_settings$ref = typeof _UserHistoryDrawerContainer_settings$ref;
export type UserHistoryDrawerContainer_settings = {
    readonly organization: {
        readonly name: string;
    };
    readonly " $fragmentRefs": RecentHistoryContainer_settings$ref & UserStatusChangeContainer_settings$ref;
    readonly " $refType": UserHistoryDrawerContainer_settings$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "UserHistoryDrawerContainer_settings",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "organization",
            "storageKey": null,
            "args": null,
            "concreteType": "Organization",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                }
            ]
        },
        {
            "kind": "FragmentSpread",
            "name": "RecentHistoryContainer_settings",
            "args": null
        },
        {
            "kind": "FragmentSpread",
            "name": "UserStatusChangeContainer_settings",
            "args": null
        }
    ]
} as any);
(node as any).hash = '26fd70e7fc4bdb0aed1e72848d47df58';
export default node;
