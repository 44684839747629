/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { ModerateCardDetailsContainer_comment$ref } from "./ModerateCardDetailsContainer_comment.graphql";
export type COMMENT_STATUS = "APPROVED" | "NONE" | "PREMOD" | "REJECTED" | "SYSTEM_WITHHELD" | "%future added value";
declare const _MarkersContainer_comment$ref: unique symbol;
export type MarkersContainer_comment$ref = typeof _MarkersContainer_comment$ref;
export type MarkersContainer_comment = {
    readonly status: COMMENT_STATUS;
    readonly editing: {
        readonly edited: boolean;
    };
    readonly revision: {
        readonly actionCounts: {
            readonly flag: {
                readonly reasons: {
                    readonly COMMENT_DETECTED_TOXIC: number;
                    readonly COMMENT_DETECTED_SPAM: number;
                    readonly COMMENT_DETECTED_RECENT_HISTORY: number;
                    readonly COMMENT_DETECTED_LINKS: number;
                    readonly COMMENT_DETECTED_BANNED_WORD: number;
                    readonly COMMENT_DETECTED_SUSPECT_WORD: number;
                    readonly COMMENT_REPORTED_OFFENSIVE: number;
                    readonly COMMENT_REPORTED_SPAM: number;
                    readonly COMMENT_DETECTED_NEW_COMMENTER: number;
                    readonly COMMENT_DETECTED_REPEAT_POST: number;
                };
            };
        };
        readonly metadata: {
            readonly perspective: {
                readonly score: number;
            } | null;
        };
    } | null;
    readonly " $fragmentRefs": ModerateCardDetailsContainer_comment$ref;
    readonly " $refType": MarkersContainer_comment$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "MarkersContainer_comment",
    "type": "Comment",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "editing",
            "storageKey": null,
            "args": null,
            "concreteType": "EditInfo",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "edited",
                    "args": null,
                    "storageKey": null
                }
            ]
        },
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "revision",
            "storageKey": null,
            "args": null,
            "concreteType": "CommentRevision",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "actionCounts",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionCounts",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "flag",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "FlagActionCounts",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "reasons",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "FlagReasonActionCounts",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "COMMENT_DETECTED_TOXIC",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "COMMENT_DETECTED_SPAM",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "COMMENT_DETECTED_RECENT_HISTORY",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "COMMENT_DETECTED_LINKS",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "COMMENT_DETECTED_BANNED_WORD",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "COMMENT_DETECTED_SUSPECT_WORD",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "COMMENT_REPORTED_OFFENSIVE",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "COMMENT_REPORTED_SPAM",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "COMMENT_DETECTED_NEW_COMMENTER",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "COMMENT_DETECTED_REPEAT_POST",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "metadata",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CommentRevisionMetadata",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "perspective",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CommentRevisionPerspectiveMetadata",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "score",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "kind": "FragmentSpread",
            "name": "ModerateCardDetailsContainer_comment",
            "args": null
        }
    ]
} as any);
(node as any).hash = 'e725dc50de4c3961ea0ae06fada42a82';
export default node;
