/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { ModerateCardDetailsContainer_comment$ref } from "./ModerateCardDetailsContainer_comment.graphql";
export type COMMENT_STATUS = "APPROVED" | "NONE" | "PREMOD" | "REJECTED" | "SYSTEM_WITHHELD" | "%future added value";
export type MODERATION_QUEUE = "PENDING" | "REPORTED" | "UNMODERATED" | "%future added value";
export type QueueCommentLeftSubscriptionVariables = {
    readonly storyID?: string | null;
    readonly queue: MODERATION_QUEUE;
};
export type QueueCommentLeftSubscriptionResponse = {
    readonly commentLeftModerationQueue: {
        readonly comment: {
            readonly id: string;
            readonly status: COMMENT_STATUS;
            readonly editing: {
                readonly edited: boolean;
            };
            readonly revision: {
                readonly actionCounts: {
                    readonly flag: {
                        readonly reasons: {
                            readonly COMMENT_DETECTED_TOXIC: number;
                            readonly COMMENT_DETECTED_SPAM: number;
                            readonly COMMENT_DETECTED_RECENT_HISTORY: number;
                            readonly COMMENT_DETECTED_LINKS: number;
                            readonly COMMENT_DETECTED_BANNED_WORD: number;
                            readonly COMMENT_DETECTED_SUSPECT_WORD: number;
                            readonly COMMENT_REPORTED_OFFENSIVE: number;
                            readonly COMMENT_REPORTED_SPAM: number;
                            readonly COMMENT_DETECTED_NEW_COMMENTER: number;
                            readonly COMMENT_DETECTED_REPEAT_POST: number;
                        };
                    };
                };
                readonly metadata: {
                    readonly perspective: {
                        readonly score: number;
                    } | null;
                };
            } | null;
            readonly statusLiveUpdated: boolean | null;
            readonly statusHistory: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly moderator: {
                            readonly id: string;
                            readonly username: string | null;
                        } | null;
                    };
                }>;
            };
            readonly " $fragmentRefs": ModerateCardDetailsContainer_comment$ref;
        };
    };
};
export type QueueCommentLeftSubscription = {
    readonly response: QueueCommentLeftSubscriptionResponse;
    readonly variables: QueueCommentLeftSubscriptionVariables;
};



/*
subscription QueueCommentLeftSubscription(
  $storyID: ID
  $queue: MODERATION_QUEUE!
) {
  commentLeftModerationQueue(storyID: $storyID, queue: $queue) {
    comment {
      id
      status
      ...ModerateCardDetailsContainer_comment
      editing {
        edited
      }
      revision {
        actionCounts {
          flag {
            reasons {
              COMMENT_DETECTED_TOXIC
              COMMENT_DETECTED_SPAM
              COMMENT_DETECTED_RECENT_HISTORY
              COMMENT_DETECTED_LINKS
              COMMENT_DETECTED_BANNED_WORD
              COMMENT_DETECTED_SUSPECT_WORD
              COMMENT_REPORTED_OFFENSIVE
              COMMENT_REPORTED_SPAM
              COMMENT_DETECTED_NEW_COMMENTER
              COMMENT_DETECTED_REPEAT_POST
            }
          }
        }
        metadata {
          perspective {
            score
          }
        }
        id
      }
      statusHistory(first: 1) {
        edges {
          node {
            moderator {
              id
              username
            }
            id
          }
        }
      }
    }
  }
}

fragment ModerateCardDetailsContainer_comment on Comment {
  ...FlagDetailsContainer_comment
  ...CommentRevisionContainer_comment
  ...LinkDetailsContainer_comment
}

fragment FlagDetailsContainer_comment on Comment {
  flags {
    nodes {
      flagger {
        username
        id
      }
      reason
      additionalDetails
    }
  }
  revision {
    metadata {
      perspective {
        score
      }
    }
    id
  }
}

fragment CommentRevisionContainer_comment on Comment {
  revision {
    id
  }
  revisionHistory {
    id
    body
    createdAt
  }
}

fragment LinkDetailsContainer_comment on Comment {
  id
  story {
    id
    url
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "storyID",
            "type": "ID",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "queue",
            "type": "MODERATION_QUEUE!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "queue",
            "variableName": "queue"
        } as any),
        ({
            "kind": "Variable",
            "name": "storyID",
            "variableName": "storyID"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "status",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "editing",
        "storageKey": null,
        "args": null,
        "concreteType": "EditInfo",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "edited",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v5 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "actionCounts",
        "storageKey": null,
        "args": null,
        "concreteType": "ActionCounts",
        "plural": false,
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "flag",
                "storageKey": null,
                "args": null,
                "concreteType": "FlagActionCounts",
                "plural": false,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "reasons",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FlagReasonActionCounts",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "COMMENT_DETECTED_TOXIC",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "COMMENT_DETECTED_SPAM",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "COMMENT_DETECTED_RECENT_HISTORY",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "COMMENT_DETECTED_LINKS",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "COMMENT_DETECTED_BANNED_WORD",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "COMMENT_DETECTED_SUSPECT_WORD",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "COMMENT_REPORTED_OFFENSIVE",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "COMMENT_REPORTED_SPAM",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "COMMENT_DETECTED_NEW_COMMENTER",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "COMMENT_DETECTED_REPEAT_POST",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    }
                ]
            }
        ]
    } as any), v6 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "metadata",
        "storageKey": null,
        "args": null,
        "concreteType": "CommentRevisionMetadata",
        "plural": false,
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "perspective",
                "storageKey": null,
                "args": null,
                "concreteType": "CommentRevisionPerspectiveMetadata",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "score",
                        "args": null,
                        "storageKey": null
                    }
                ]
            }
        ]
    } as any), v7 = [
        ({
            "kind": "Literal",
            "name": "first",
            "value": 1
        } as any)
    ], v8 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "username",
        "args": null,
        "storageKey": null
    } as any), v9 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "moderator",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
            (v2 /*: any*/),
            (v8 /*: any*/)
        ]
    } as any), v10 = ({
        "kind": "ClientExtension",
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "statusLiveUpdated",
                "args": null,
                "storageKey": null
            }
        ]
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "QueueCommentLeftSubscription",
            "type": "Subscription",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "commentLeftModerationQueue",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CommentLeftModerationQueuePayload",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "comment",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Comment",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "revision",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "CommentRevision",
                                    "plural": false,
                                    "selections": [
                                        (v5 /*: any*/),
                                        (v6 /*: any*/)
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "statusHistory",
                                    "storageKey": "statusHistory(first:1)",
                                    "args": (v7 /*: any*/),
                                    "concreteType": "CommentModerationActionConnection",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "edges",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "CommentModerationActionEdge",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "node",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "CommentModerationAction",
                                                    "plural": false,
                                                    "selections": [
                                                        (v9 /*: any*/)
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "kind": "FragmentSpread",
                                    "name": "ModerateCardDetailsContainer_comment",
                                    "args": null
                                },
                                (v10 /*: any*/)
                            ]
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "QueueCommentLeftSubscription",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "commentLeftModerationQueue",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CommentLeftModerationQueuePayload",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "comment",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Comment",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "flags",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "FlagsConnection",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "nodes",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "Flag",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "flagger",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "User",
                                                    "plural": false,
                                                    "selections": [
                                                        (v8 /*: any*/),
                                                        (v2 /*: any*/)
                                                    ]
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "reason",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "additionalDetails",
                                                    "args": null,
                                                    "storageKey": null
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "revision",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "CommentRevision",
                                    "plural": false,
                                    "selections": [
                                        (v6 /*: any*/),
                                        (v2 /*: any*/),
                                        (v5 /*: any*/)
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "revisionHistory",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "CommentRevision",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "body",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "createdAt",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "story",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Story",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "url",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                },
                                (v4 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "statusHistory",
                                    "storageKey": "statusHistory(first:1)",
                                    "args": (v7 /*: any*/),
                                    "concreteType": "CommentModerationActionConnection",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "edges",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "CommentModerationActionEdge",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "node",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "CommentModerationAction",
                                                    "plural": false,
                                                    "selections": [
                                                        (v9 /*: any*/),
                                                        (v2 /*: any*/)
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                (v10 /*: any*/)
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "subscription",
            "name": "QueueCommentLeftSubscription",
            "id": "0b91fd75a837339a270ca712d97e26ae",
            "text": null,
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'c785db8df75c9b29549ec71b7bd0ec36';
export default node;
