/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { MarkersContainer_settings$ref } from "./MarkersContainer_settings.graphql";
declare const _ModerateCardContainer_settings$ref: unique symbol;
export type ModerateCardContainer_settings$ref = typeof _ModerateCardContainer_settings$ref;
export type ModerateCardContainer_settings = {
    readonly wordList: {
        readonly banned: ReadonlyArray<string>;
        readonly suspect: ReadonlyArray<string>;
    };
    readonly " $fragmentRefs": MarkersContainer_settings$ref;
    readonly " $refType": ModerateCardContainer_settings$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "ModerateCardContainer_settings",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "wordList",
            "storageKey": null,
            "args": null,
            "concreteType": "WordList",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "banned",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "suspect",
                    "args": null,
                    "storageKey": null
                }
            ]
        },
        {
            "kind": "FragmentSpread",
            "name": "MarkersContainer_settings",
            "args": null
        }
    ]
} as any);
(node as any).hash = '47ae372f104f22d3b07761b98e8db39b';
export default node;
