/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { RestrictedContainer_viewer$ref } from "./RestrictedContainer_viewer.graphql";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MODERATOR" | "STAFF" | "%future added value";
export type AuthCheckRouteQueryVariables = {};
export type AuthCheckRouteQueryResponse = {
    readonly viewer: {
        readonly username: string | null;
        readonly email: string | null;
        readonly profiles: ReadonlyArray<{
            readonly __typename: string;
        }>;
        readonly role: USER_ROLE;
        readonly " $fragmentRefs": RestrictedContainer_viewer$ref;
    } | null;
    readonly settings: {
        readonly auth: {
            readonly integrations: {
                readonly local: {
                    readonly enabled: boolean;
                    readonly targetFilter: {
                        readonly admin: boolean;
                        readonly stream: boolean;
                    };
                };
            };
        };
    };
};
export type AuthCheckRouteQuery = {
    readonly response: AuthCheckRouteQueryResponse;
    readonly variables: AuthCheckRouteQueryVariables;
};



/*
query AuthCheckRouteQuery {
  viewer {
    ...RestrictedContainer_viewer
    username
    email
    profiles {
      __typename
    }
    role
    id
  }
  settings {
    auth {
      integrations {
        local {
          enabled
          targetFilter {
            admin
            stream
          }
        }
      }
    }
    id
  }
}

fragment RestrictedContainer_viewer on User {
  username
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "username",
        "args": null,
        "storageKey": null
    } as any), v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
    } as any), v2 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "profiles",
        "storageKey": null,
        "args": null,
        "concreteType": null,
        "plural": true,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "__typename",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "role",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "auth",
        "storageKey": null,
        "args": null,
        "concreteType": "Auth",
        "plural": false,
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "integrations",
                "storageKey": null,
                "args": null,
                "concreteType": "AuthIntegrations",
                "plural": false,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "local",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "LocalAuthIntegration",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "enabled",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "targetFilter",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "AuthenticationTargetFilter",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "admin",
                                        "args": null,
                                        "storageKey": null
                                    },
                                    {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "stream",
                                        "args": null,
                                        "storageKey": null
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "AuthCheckRouteQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "viewer",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "kind": "FragmentSpread",
                            "name": "RestrictedContainer_viewer",
                            "args": null
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "settings",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Settings",
                    "plural": false,
                    "selections": [
                        (v4 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "AuthCheckRouteQuery",
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "viewer",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v5 /*: any*/)
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "settings",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Settings",
                    "plural": false,
                    "selections": [
                        (v4 /*: any*/),
                        (v5 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "AuthCheckRouteQuery",
            "id": "781dba8d9bbacab6f08b7c4b24cf18a8",
            "text": null,
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '5c7eeffe18eca94dd009ed7c70c61cd2';
export default node;
