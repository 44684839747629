/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { RejectedQueueRoute_query$ref } from "./RejectedQueueRoute_query.graphql";
export type RejectedQueueRouteQueryVariables = {
    readonly storyID?: string | null;
};
export type RejectedQueueRouteQueryResponse = {
    readonly " $fragmentRefs": RejectedQueueRoute_query$ref;
};
export type RejectedQueueRouteQuery = {
    readonly response: RejectedQueueRouteQueryResponse;
    readonly variables: RejectedQueueRouteQueryVariables;
};



/*
query RejectedQueueRouteQuery(
  $storyID: ID
) {
  ...RejectedQueueRoute_query_2TMrIp
}

fragment RejectedQueueRoute_query_2TMrIp on Query {
  comments(status: REJECTED, storyID: $storyID, first: 5) {
    edges {
      node {
        id
        ...ModerateCardContainer_comment
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  settings {
    ...ModerateCardContainer_settings
    id
  }
  viewer {
    ...ModerateCardContainer_viewer
    id
  }
}

fragment ModerateCardContainer_comment on Comment {
  id
  author {
    id
    username
    status {
      current
    }
  }
  createdAt
  body
  tags {
    code
  }
  status
  revision {
    id
  }
  editing {
    edited
  }
  parent {
    author {
      id
      username
    }
    id
  }
  story {
    id
    metadata {
      title
    }
  }
  permalink
  deleted
  ...MarkersContainer_comment
  ...ModeratedByContainer_comment
  ...CommentAuthorContainer_comment
}

fragment ModerateCardContainer_settings on Settings {
  wordList {
    banned
    suspect
  }
  ...MarkersContainer_settings
}

fragment ModerateCardContainer_viewer on User {
  ...ModeratedByContainer_viewer
}

fragment ModeratedByContainer_viewer on User {
  id
  username
}

fragment MarkersContainer_settings on Settings {
  ...ModerateCardDetailsContainer_settings
}

fragment ModerateCardDetailsContainer_settings on Settings {
  ...FlagDetailsContainer_settings
  ...CommentRevisionContainer_settings
  ...LinkDetailsContainer_settings
}

fragment FlagDetailsContainer_settings on Settings {
  integrations {
    perspective {
      threshold
    }
  }
}

fragment CommentRevisionContainer_settings on Settings {
  wordList {
    banned
    suspect
  }
}

fragment LinkDetailsContainer_settings on Settings {
  organization {
    url
  }
}

fragment MarkersContainer_comment on Comment {
  ...ModerateCardDetailsContainer_comment
  status
  editing {
    edited
  }
  revision {
    actionCounts {
      flag {
        reasons {
          COMMENT_DETECTED_TOXIC
          COMMENT_DETECTED_SPAM
          COMMENT_DETECTED_RECENT_HISTORY
          COMMENT_DETECTED_LINKS
          COMMENT_DETECTED_BANNED_WORD
          COMMENT_DETECTED_SUSPECT_WORD
          COMMENT_REPORTED_OFFENSIVE
          COMMENT_REPORTED_SPAM
          COMMENT_DETECTED_NEW_COMMENTER
          COMMENT_DETECTED_REPEAT_POST
        }
      }
    }
    metadata {
      perspective {
        score
      }
    }
    id
  }
}

fragment ModeratedByContainer_comment on Comment {
  id
  statusHistory(first: 1) {
    edges {
      node {
        moderator {
          id
          username
        }
        id
      }
    }
  }
}

fragment CommentAuthorContainer_comment on Comment {
  author {
    id
    username
    status {
      ban {
        active
      }
    }
  }
}

fragment ModerateCardDetailsContainer_comment on Comment {
  ...FlagDetailsContainer_comment
  ...CommentRevisionContainer_comment
  ...LinkDetailsContainer_comment
}

fragment FlagDetailsContainer_comment on Comment {
  flags {
    nodes {
      flagger {
        username
        id
      }
      reason
      additionalDetails
    }
  }
  revision {
    metadata {
      perspective {
        score
      }
    }
    id
  }
}

fragment CommentRevisionContainer_comment on Comment {
  revision {
    id
  }
  revisionHistory {
    id
    body
    createdAt
  }
}

fragment LinkDetailsContainer_comment on Comment {
  id
  story {
    id
    url
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "storyID",
            "type": "ID",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "Variable",
        "name": "storyID",
        "variableName": "storyID"
    } as any), v2 = [
        ({
            "kind": "Literal",
            "name": "first",
            "value": 5
        } as any),
        ({
            "kind": "Literal",
            "name": "status",
            "value": "REJECTED"
        } as any),
        (v1 /*: any*/)
    ], v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "username",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "createdAt",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "body",
        "args": null,
        "storageKey": null
    } as any), v7 = [
        (v3 /*: any*/),
        (v4 /*: any*/)
    ], v8 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "url",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "RejectedQueueRouteQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "FragmentSpread",
                    "name": "RejectedQueueRoute_query",
                    "args": [
                        (v1 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "RejectedQueueRouteQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "comments",
                    "storageKey": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "CommentsConnection",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CommentEdge",
                            "plural": true,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Comment",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "author",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "plural": false,
                                            "selections": [
                                                (v3 /*: any*/),
                                                (v4 /*: any*/),
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "status",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "UserStatus",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "kind": "ScalarField",
                                                            "alias": null,
                                                            "name": "current",
                                                            "args": null,
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "kind": "LinkedField",
                                                            "alias": null,
                                                            "name": "ban",
                                                            "storageKey": null,
                                                            "args": null,
                                                            "concreteType": "BanStatus",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "kind": "ScalarField",
                                                                    "alias": null,
                                                                    "name": "active",
                                                                    "args": null,
                                                                    "storageKey": null
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        (v5 /*: any*/),
                                        (v6 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "tags",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "Tag",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "code",
                                                    "args": null,
                                                    "storageKey": null
                                                }
                                            ]
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "status",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "revision",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "CommentRevision",
                                            "plural": false,
                                            "selections": [
                                                (v3 /*: any*/),
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "metadata",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "CommentRevisionMetadata",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "kind": "LinkedField",
                                                            "alias": null,
                                                            "name": "perspective",
                                                            "storageKey": null,
                                                            "args": null,
                                                            "concreteType": "CommentRevisionPerspectiveMetadata",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "kind": "ScalarField",
                                                                    "alias": null,
                                                                    "name": "score",
                                                                    "args": null,
                                                                    "storageKey": null
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "actionCounts",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "ActionCounts",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "kind": "LinkedField",
                                                            "alias": null,
                                                            "name": "flag",
                                                            "storageKey": null,
                                                            "args": null,
                                                            "concreteType": "FlagActionCounts",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "kind": "LinkedField",
                                                                    "alias": null,
                                                                    "name": "reasons",
                                                                    "storageKey": null,
                                                                    "args": null,
                                                                    "concreteType": "FlagReasonActionCounts",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        {
                                                                            "kind": "ScalarField",
                                                                            "alias": null,
                                                                            "name": "COMMENT_DETECTED_TOXIC",
                                                                            "args": null,
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "kind": "ScalarField",
                                                                            "alias": null,
                                                                            "name": "COMMENT_DETECTED_SPAM",
                                                                            "args": null,
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "kind": "ScalarField",
                                                                            "alias": null,
                                                                            "name": "COMMENT_DETECTED_RECENT_HISTORY",
                                                                            "args": null,
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "kind": "ScalarField",
                                                                            "alias": null,
                                                                            "name": "COMMENT_DETECTED_LINKS",
                                                                            "args": null,
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "kind": "ScalarField",
                                                                            "alias": null,
                                                                            "name": "COMMENT_DETECTED_BANNED_WORD",
                                                                            "args": null,
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "kind": "ScalarField",
                                                                            "alias": null,
                                                                            "name": "COMMENT_DETECTED_SUSPECT_WORD",
                                                                            "args": null,
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "kind": "ScalarField",
                                                                            "alias": null,
                                                                            "name": "COMMENT_REPORTED_OFFENSIVE",
                                                                            "args": null,
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "kind": "ScalarField",
                                                                            "alias": null,
                                                                            "name": "COMMENT_REPORTED_SPAM",
                                                                            "args": null,
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "kind": "ScalarField",
                                                                            "alias": null,
                                                                            "name": "COMMENT_DETECTED_NEW_COMMENTER",
                                                                            "args": null,
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "kind": "ScalarField",
                                                                            "alias": null,
                                                                            "name": "COMMENT_DETECTED_REPEAT_POST",
                                                                            "args": null,
                                                                            "storageKey": null
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "editing",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "EditInfo",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "edited",
                                                    "args": null,
                                                    "storageKey": null
                                                }
                                            ]
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "parent",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "Comment",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "author",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "User",
                                                    "plural": false,
                                                    "selections": (v7 /*: any*/)
                                                },
                                                (v3 /*: any*/)
                                            ]
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "story",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "Story",
                                            "plural": false,
                                            "selections": [
                                                (v3 /*: any*/),
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "metadata",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "StoryMetadata",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "kind": "ScalarField",
                                                            "alias": null,
                                                            "name": "title",
                                                            "args": null,
                                                            "storageKey": null
                                                        }
                                                    ]
                                                },
                                                (v8 /*: any*/)
                                            ]
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "permalink",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "deleted",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "flags",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "FlagsConnection",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "nodes",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "Flag",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "kind": "LinkedField",
                                                            "alias": null,
                                                            "name": "flagger",
                                                            "storageKey": null,
                                                            "args": null,
                                                            "concreteType": "User",
                                                            "plural": false,
                                                            "selections": [
                                                                (v4 /*: any*/),
                                                                (v3 /*: any*/)
                                                            ]
                                                        },
                                                        {
                                                            "kind": "ScalarField",
                                                            "alias": null,
                                                            "name": "reason",
                                                            "args": null,
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "kind": "ScalarField",
                                                            "alias": null,
                                                            "name": "additionalDetails",
                                                            "args": null,
                                                            "storageKey": null
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "revisionHistory",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "CommentRevision",
                                            "plural": true,
                                            "selections": [
                                                (v3 /*: any*/),
                                                (v6 /*: any*/),
                                                (v5 /*: any*/)
                                            ]
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "statusHistory",
                                            "storageKey": "statusHistory(first:1)",
                                            "args": [
                                                {
                                                    "kind": "Literal",
                                                    "name": "first",
                                                    "value": 1
                                                }
                                            ],
                                            "concreteType": "CommentModerationActionConnection",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "edges",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "CommentModerationActionEdge",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "kind": "LinkedField",
                                                            "alias": null,
                                                            "name": "node",
                                                            "storageKey": null,
                                                            "args": null,
                                                            "concreteType": "CommentModerationAction",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "kind": "LinkedField",
                                                                    "alias": null,
                                                                    "name": "moderator",
                                                                    "storageKey": null,
                                                                    "args": null,
                                                                    "concreteType": "User",
                                                                    "plural": false,
                                                                    "selections": (v7 /*: any*/)
                                                                },
                                                                (v3 /*: any*/)
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "__typename",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ClientExtension",
                                            "selections": [
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "statusLiveUpdated",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "enteredLive",
                                                    "args": null,
                                                    "storageKey": null
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "cursor",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "pageInfo",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "endCursor",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "hasNextPage",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                },
                {
                    "kind": "LinkedHandle",
                    "alias": null,
                    "name": "comments",
                    "args": (v2 /*: any*/),
                    "handle": "connection",
                    "key": "RejectedQueue_comments",
                    "filters": [
                        "status",
                        "storyID"
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "settings",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Settings",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "wordList",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "WordList",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "banned",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "suspect",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "integrations",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ExternalIntegrations",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "perspective",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "PerspectiveExternalIntegration",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "threshold",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "organization",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Organization",
                            "plural": false,
                            "selections": [
                                (v8 /*: any*/)
                            ]
                        },
                        (v3 /*: any*/)
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "viewer",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": (v7 /*: any*/)
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "RejectedQueueRouteQuery",
            "id": "195036273cbc200963574a3ed0df271e",
            "text": null,
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'd9d8ca2759741444d1bd7988407b91f9';
export default node;
