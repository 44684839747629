/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _ModerateNavigationContainer_moderationQueues$ref: unique symbol;
export type ModerateNavigationContainer_moderationQueues$ref = typeof _ModerateNavigationContainer_moderationQueues$ref;
export type ModerateNavigationContainer_moderationQueues = {
    readonly unmoderated: {
        readonly count: number;
    };
    readonly reported: {
        readonly count: number;
    };
    readonly pending: {
        readonly count: number;
    };
    readonly " $refType": ModerateNavigationContainer_moderationQueues$ref;
};



const node: ReaderFragment = (function () {
    var v0 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "count",
            "args": null,
            "storageKey": null
        } as any)
    ];
    return {
        "kind": "Fragment",
        "name": "ModerateNavigationContainer_moderationQueues",
        "type": "ModerationQueues",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "unmoderated",
                "storageKey": null,
                "args": null,
                "concreteType": "ModerationQueue",
                "plural": false,
                "selections": (v0 /*: any*/)
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "reported",
                "storageKey": null,
                "args": null,
                "concreteType": "ModerationQueue",
                "plural": false,
                "selections": (v0 /*: any*/)
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "pending",
                "storageKey": null,
                "args": null,
                "concreteType": "ModerationQueue",
                "plural": false,
                "selections": (v0 /*: any*/)
            }
        ]
    } as any;
})();
(node as any).hash = 'aeb84c03667ee2c423325a71860659e0';
export default node;
