/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _CommentLengthConfig_formValues$ref: unique symbol;
export type CommentLengthConfig_formValues$ref = typeof _CommentLengthConfig_formValues$ref;
export type CommentLengthConfig_formValues = {
    readonly charCount: {
        readonly enabled: boolean;
        readonly min: number | null;
        readonly max: number | null;
    };
    readonly " $refType": CommentLengthConfig_formValues$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "CommentLengthConfig_formValues",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "charCount",
            "storageKey": null,
            "args": null,
            "concreteType": "CharCount",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "enabled",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "min",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "max",
                    "args": null,
                    "storageKey": null
                }
            ]
        }
    ]
} as any);
(node as any).hash = 'f5d630534e37b7cbd3e71753e76f0275';
export default node;
