/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _OrganizationContactEmailConfig_formValues$ref: unique symbol;
export type OrganizationContactEmailConfig_formValues$ref = typeof _OrganizationContactEmailConfig_formValues$ref;
export type OrganizationContactEmailConfig_formValues = {
    readonly organization: {
        readonly contactEmail: string;
    };
    readonly " $refType": OrganizationContactEmailConfig_formValues$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "OrganizationContactEmailConfig_formValues",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "organization",
            "storageKey": null,
            "args": null,
            "concreteType": "Organization",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "contactEmail",
                    "args": null,
                    "storageKey": null
                }
            ]
        }
    ]
} as any);
(node as any).hash = '756672cad5b730ca9f26e3280908e440';
export default node;
