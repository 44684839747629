/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { GeneralConfigContainer_settings$ref } from "./GeneralConfigContainer_settings.graphql";
export type GeneralConfigRouteQueryVariables = {};
export type GeneralConfigRouteQueryResponse = {
    readonly settings: {
        readonly " $fragmentRefs": GeneralConfigContainer_settings$ref;
    };
};
export type GeneralConfigRouteQuery = {
    readonly response: GeneralConfigRouteQueryResponse;
    readonly variables: GeneralConfigRouteQueryVariables;
};



/*
query GeneralConfigRouteQuery {
  settings {
    ...GeneralConfigContainer_settings
    id
  }
}

fragment GeneralConfigContainer_settings on Settings {
  ...AnnouncementConfigContainer_settings
  locale
  communityGuidelines {
    enabled
    content
  }
  charCount {
    enabled
    min
    max
  }
  editCommentWindowLength
  closeCommenting {
    message
    auto
    timeout
  }
  disableCommenting {
    enabled
    message
  }
  reaction {
    label
    labelActive
    sortLabel
    icon
    iconActive
  }
  staff {
    label
  }
  ...ReactionConfigContainer_settings
}

fragment AnnouncementConfigContainer_settings on Settings {
  announcement {
    content
    duration
    createdAt
    id
  }
}

fragment ReactionConfigContainer_settings on Settings {
  reaction {
    icon
    iconActive
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "content",
        "args": null,
        "storageKey": null
    } as any), v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "enabled",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "message",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "label",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GeneralConfigRouteQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "settings",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Settings",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "GeneralConfigContainer_settings",
                            "args": null
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "GeneralConfigRouteQuery",
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "settings",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Settings",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "announcement",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Announcement",
                            "plural": false,
                            "selections": [
                                (v0 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "duration",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "createdAt",
                                    "args": null,
                                    "storageKey": null
                                },
                                (v1 /*: any*/)
                            ]
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "locale",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "communityGuidelines",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CommunityGuidelines",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v0 /*: any*/)
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "charCount",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CharCount",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "min",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "max",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "editCommentWindowLength",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "closeCommenting",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CloseCommenting",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "auto",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "timeout",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "disableCommenting",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "DisableCommenting",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/)
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "reaction",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ReactionConfiguration",
                            "plural": false,
                            "selections": [
                                (v4 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "labelActive",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "sortLabel",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "icon",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "iconActive",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "staff",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "StaffConfiguration",
                            "plural": false,
                            "selections": [
                                (v4 /*: any*/)
                            ]
                        },
                        (v1 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "GeneralConfigRouteQuery",
            "id": "ca1a8c0fb981a606b65e0f29d4814e44",
            "text": null,
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '12e04d40d68e02a4e570c7ca3fbb3dd6';
export default node;
