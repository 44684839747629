/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { UserRoleChangeContainer_viewer$ref } from "./UserRoleChangeContainer_viewer.graphql";
declare const _UserRowContainer_viewer$ref: unique symbol;
export type UserRowContainer_viewer$ref = typeof _UserRowContainer_viewer$ref;
export type UserRowContainer_viewer = {
    readonly " $fragmentRefs": UserRoleChangeContainer_viewer$ref;
    readonly " $refType": UserRowContainer_viewer$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "UserRowContainer_viewer",
    "type": "User",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "FragmentSpread",
            "name": "UserRoleChangeContainer_viewer",
            "args": null
        }
    ]
} as any);
(node as any).hash = 'b75da73dac8a95be2968be5e0cf99af9';
export default node;
