/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type SetEmailInput = {
    readonly email: string;
    readonly clientMutationId: string;
};
export type SetEmailMutationVariables = {
    readonly input: SetEmailInput;
};
export type SetEmailMutationResponse = {
    readonly setEmail: {
        readonly user: {
            readonly email: string | null;
        };
        readonly clientMutationId: string;
    };
};
export type SetEmailMutation = {
    readonly response: SetEmailMutationResponse;
    readonly variables: SetEmailMutationVariables;
};



/*
mutation SetEmailMutation(
  $input: SetEmailInput!
) {
  setEmail(input: $input) {
    user {
      email
      id
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "SetEmailInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "SetEmailMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "setEmail",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "SetEmailPayload",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "User",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/)
                            ]
                        },
                        (v3 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "SetEmailMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "setEmail",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "SetEmailPayload",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "User",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "id",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        (v3 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "mutation",
            "name": "SetEmailMutation",
            "id": "aae63014db06ebc085403a2d8b5a42a8",
            "text": null,
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '9d22b9ac878839bd30505594d45f4f4c';
export default node;
