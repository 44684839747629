/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
export type MODERATION_MODE = "POST" | "PRE" | "%future added value";
declare const _ModerationConfigContainer_settings$ref: unique symbol;
export type ModerationConfigContainer_settings$ref = typeof _ModerationConfigContainer_settings$ref;
export type ModerationConfigContainer_settings = {
    readonly integrations: {
        readonly akismet: {
            readonly enabled: boolean;
            readonly key: string | null;
            readonly site: string | null;
        };
        readonly perspective: {
            readonly enabled: boolean;
            readonly endpoint: string | null;
            readonly key: string | null;
            readonly model: string | null;
            readonly threshold: number | null;
            readonly doNotStore: boolean | null;
            readonly sendFeedback: boolean | null;
        };
    };
    readonly moderation: MODERATION_MODE | null;
    readonly premodLinksEnable: boolean | null;
    readonly recentCommentHistory: {
        readonly enabled: boolean;
        readonly timeFrame: number;
        readonly triggerRejectionRate: number;
    };
    readonly newCommenters: {
        readonly premodEnabled: boolean;
        readonly approvedCommentsThreshold: number;
    };
    readonly " $refType": ModerationConfigContainer_settings$ref;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "enabled",
        "args": null,
        "storageKey": null
    } as any), v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "key",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Fragment",
        "name": "ModerationConfigContainer_settings",
        "type": "Settings",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "integrations",
                "storageKey": null,
                "args": null,
                "concreteType": "ExternalIntegrations",
                "plural": false,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "akismet",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AkismetExternalIntegration",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            (v1 /*: any*/),
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "site",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    },
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "perspective",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PerspectiveExternalIntegration",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "endpoint",
                                "args": null,
                                "storageKey": null
                            },
                            (v1 /*: any*/),
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "model",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "threshold",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "doNotStore",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "sendFeedback",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    }
                ]
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "moderation",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "premodLinksEnable",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "recentCommentHistory",
                "storageKey": null,
                "args": null,
                "concreteType": "RecentCommentHistoryConfiguration",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "timeFrame",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "triggerRejectionRate",
                        "args": null,
                        "storageKey": null
                    }
                ]
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "newCommenters",
                "storageKey": null,
                "args": null,
                "concreteType": "NewCommentersConfiguration",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "premodEnabled",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "approvedCommentsThreshold",
                        "args": null,
                        "storageKey": null
                    }
                ]
            }
        ]
    } as any;
})();
(node as any).hash = '361b6a08ac8d640710b552e234a74a9d';
export default node;
