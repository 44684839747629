/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _UserStatusDetailsContainer_user$ref: unique symbol;
export type UserStatusDetailsContainer_user$ref = typeof _UserStatusDetailsContainer_user$ref;
export type UserStatusDetailsContainer_user = {
    readonly status: {
        readonly ban: {
            readonly active: boolean;
            readonly history: ReadonlyArray<{
                readonly active: boolean;
                readonly createdAt: string;
                readonly createdBy: {
                    readonly username: string | null;
                } | null;
            }>;
        };
        readonly suspension: {
            readonly until: string | null;
            readonly active: boolean;
            readonly history: ReadonlyArray<{
                readonly active: boolean;
                readonly from: {
                    readonly start: string;
                    readonly finish: string;
                };
                readonly createdBy: {
                    readonly username: string | null;
                } | null;
            }>;
        };
    };
    readonly " $refType": UserStatusDetailsContainer_user$ref;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "active",
        "args": null,
        "storageKey": null
    } as any), v1 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "createdBy",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "username",
                "args": null,
                "storageKey": null
            }
        ]
    } as any);
    return {
        "kind": "Fragment",
        "name": "UserStatusDetailsContainer_user",
        "type": "User",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "status",
                "storageKey": null,
                "args": null,
                "concreteType": "UserStatus",
                "plural": false,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "ban",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BanStatus",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "history",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BanStatusHistory",
                                "plural": true,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "createdAt",
                                        "args": null,
                                        "storageKey": null
                                    },
                                    (v1 /*: any*/)
                                ]
                            }
                        ]
                    },
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "suspension",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "SuspensionStatus",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "until",
                                "args": null,
                                "storageKey": null
                            },
                            (v0 /*: any*/),
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "history",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "SuspensionStatusHistory",
                                "plural": true,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "from",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "TimeRange",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "start",
                                                "args": null,
                                                "storageKey": null
                                            },
                                            {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "finish",
                                                "args": null,
                                                "storageKey": null
                                            }
                                        ]
                                    },
                                    (v1 /*: any*/)
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    } as any;
})();
(node as any).hash = 'f3d489c29f941d9f614f33727b8cf454';
export default node;
