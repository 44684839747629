/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type COMMENT_STATUS = "APPROVED" | "NONE" | "PREMOD" | "REJECTED" | "SYSTEM_WITHHELD" | "%future added value";
export type TAG = "FEATURED" | "STAFF" | "%future added value";
export type FeatureCommentInput = {
    readonly commentID: string;
    readonly commentRevisionID: string;
    readonly clientMutationId: string;
};
export type FeatureCommentMutationVariables = {
    readonly input: FeatureCommentInput;
    readonly storyID?: string | null;
};
export type FeatureCommentMutationResponse = {
    readonly featureComment: {
        readonly comment: {
            readonly tags: ReadonlyArray<{
                readonly code: TAG;
            }>;
            readonly status: COMMENT_STATUS;
        } | null;
        readonly moderationQueues: {
            readonly unmoderated: {
                readonly count: number;
            };
            readonly reported: {
                readonly count: number;
            };
            readonly pending: {
                readonly count: number;
            };
        } | null;
        readonly clientMutationId: string;
    };
};
export type FeatureCommentMutation = {
    readonly response: FeatureCommentMutationResponse;
    readonly variables: FeatureCommentMutationVariables;
};



/*
mutation FeatureCommentMutation(
  $input: FeatureCommentInput!
  $storyID: ID
) {
  featureComment(input: $input) {
    comment {
      tags {
        code
      }
      status
      id
    }
    moderationQueues(storyID: $storyID) {
      unmoderated {
        count
        id
      }
      reported {
        count
        id
      }
      pending {
        count
        id
      }
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "FeatureCommentInput!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "storyID",
            "type": "ID",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any)
    ], v2 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "tags",
        "storageKey": null,
        "args": null,
        "concreteType": "Tag",
        "plural": true,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "code",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "status",
        "args": null,
        "storageKey": null
    } as any), v4 = [
        ({
            "kind": "Variable",
            "name": "storyID",
            "variableName": "storyID"
        } as any)
    ], v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "count",
        "args": null,
        "storageKey": null
    } as any), v6 = [
        (v5 /*: any*/)
    ], v7 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
    } as any), v8 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v9 = [
        (v5 /*: any*/),
        (v8 /*: any*/)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "FeatureCommentMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "featureComment",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "FeatureCommentPayload",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "comment",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Comment",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/)
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "moderationQueues",
                            "storageKey": null,
                            "args": (v4 /*: any*/),
                            "concreteType": "ModerationQueues",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "unmoderated",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "ModerationQueue",
                                    "plural": false,
                                    "selections": (v6 /*: any*/)
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "reported",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "ModerationQueue",
                                    "plural": false,
                                    "selections": (v6 /*: any*/)
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "pending",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "ModerationQueue",
                                    "plural": false,
                                    "selections": (v6 /*: any*/)
                                }
                            ]
                        },
                        (v7 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "FeatureCommentMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "featureComment",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "FeatureCommentPayload",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "comment",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Comment",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v8 /*: any*/)
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "moderationQueues",
                            "storageKey": null,
                            "args": (v4 /*: any*/),
                            "concreteType": "ModerationQueues",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "unmoderated",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "ModerationQueue",
                                    "plural": false,
                                    "selections": (v9 /*: any*/)
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "reported",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "ModerationQueue",
                                    "plural": false,
                                    "selections": (v9 /*: any*/)
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "pending",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "ModerationQueue",
                                    "plural": false,
                                    "selections": (v9 /*: any*/)
                                }
                            ]
                        },
                        (v7 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "mutation",
            "name": "FeatureCommentMutation",
            "id": "48cf4a4858292df50f08dcd82b73fda0",
            "text": null,
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '8dbbb05f06489c479b5dba77976813fa';
export default node;
