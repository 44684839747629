/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { ModerateNavigationContainer_moderationQueues$ref } from "./ModerateNavigationContainer_moderationQueues.graphql";
import { ModerateNavigationContainer_story$ref } from "./ModerateNavigationContainer_story.graphql";
import { ModerateSearchBarContainer_story$ref } from "./ModerateSearchBarContainer_story.graphql";
export type ModerateContainerQueryVariables = {
    readonly storyID?: string | null;
    readonly includeStory: boolean;
};
export type ModerateContainerQueryResponse = {
    readonly story?: {
        readonly " $fragmentRefs": ModerateNavigationContainer_story$ref & ModerateSearchBarContainer_story$ref;
    } | null;
    readonly moderationQueues: {
        readonly " $fragmentRefs": ModerateNavigationContainer_moderationQueues$ref;
    };
};
export type ModerateContainerQuery = {
    readonly response: ModerateContainerQueryResponse;
    readonly variables: ModerateContainerQueryVariables;
};



/*
query ModerateContainerQuery(
  $storyID: ID
  $includeStory: Boolean!
) {
  story(id: $storyID) @include(if: $includeStory) {
    ...ModerateNavigationContainer_story
    ...ModerateSearchBarContainer_story
    id
  }
  moderationQueues(storyID: $storyID) {
    ...ModerateNavigationContainer_moderationQueues
  }
}

fragment ModerateNavigationContainer_story on Story {
  id
}

fragment ModerateSearchBarContainer_story on Story {
  id
  metadata {
    title
    author
  }
}

fragment ModerateNavigationContainer_moderationQueues on ModerationQueues {
  unmoderated {
    count
    id
  }
  reported {
    count
    id
  }
  pending {
    count
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "storyID",
            "type": "ID",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "includeStory",
            "type": "Boolean!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "storyID",
            "variableName": "storyID"
        } as any)
    ], v2 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "storyID"
        } as any)
    ], v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v4 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "count",
            "args": null,
            "storageKey": null
        } as any),
        (v3 /*: any*/)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "ModerateContainerQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "moderationQueues",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ModerationQueues",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "ModerateNavigationContainer_moderationQueues",
                            "args": null
                        }
                    ]
                },
                {
                    "kind": "Condition",
                    "passingValue": true,
                    "condition": "includeStory",
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "story",
                            "storageKey": null,
                            "args": (v2 /*: any*/),
                            "concreteType": "Story",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "FragmentSpread",
                                    "name": "ModerateNavigationContainer_story",
                                    "args": null
                                },
                                {
                                    "kind": "FragmentSpread",
                                    "name": "ModerateSearchBarContainer_story",
                                    "args": null
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "ModerateContainerQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "moderationQueues",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ModerationQueues",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "unmoderated",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ModerationQueue",
                            "plural": false,
                            "selections": (v4 /*: any*/)
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "reported",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ModerationQueue",
                            "plural": false,
                            "selections": (v4 /*: any*/)
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "pending",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ModerationQueue",
                            "plural": false,
                            "selections": (v4 /*: any*/)
                        }
                    ]
                },
                {
                    "kind": "Condition",
                    "passingValue": true,
                    "condition": "includeStory",
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "story",
                            "storageKey": null,
                            "args": (v2 /*: any*/),
                            "concreteType": "Story",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "metadata",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "StoryMetadata",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "title",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "author",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "ModerateContainerQuery",
            "id": "81c31a6f6c3d142099bb61f1aa49b8bf",
            "text": null,
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '1635333d10d8b4482dbff9363a41e1eb';
export default node;
