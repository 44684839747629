/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { ModerateCardContainer_comment$ref } from "./ModerateCardContainer_comment.graphql";
declare const _UserHistoryDrawerRejectedComments_user$ref: unique symbol;
export type UserHistoryDrawerRejectedComments_user$ref = typeof _UserHistoryDrawerRejectedComments_user$ref;
export type UserHistoryDrawerRejectedComments_user = {
    readonly username: string | null;
    readonly rejectedComments: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": ModerateCardContainer_comment$ref;
            };
        }>;
    };
    readonly " $refType": UserHistoryDrawerRejectedComments_user$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "UserHistoryDrawerRejectedComments_user",
    "type": "User",
    "metadata": {
        "connection": [
            {
                "count": "count",
                "cursor": "cursor",
                "direction": "forward",
                "path": [
                    "rejectedComments"
                ]
            }
        ]
    },
    "argumentDefinitions": [
        {
            "kind": "LocalArgument",
            "name": "count",
            "type": "Int!",
            "defaultValue": 5
        },
        {
            "kind": "LocalArgument",
            "name": "cursor",
            "type": "Cursor",
            "defaultValue": null
        }
    ],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "username",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "LinkedField",
            "alias": "rejectedComments",
            "name": "__UserHistoryDrawer_rejectedComments_connection",
            "storageKey": null,
            "args": null,
            "concreteType": "CommentsConnection",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CommentEdge",
                    "plural": true,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Comment",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "id",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "__typename",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "FragmentSpread",
                                    "name": "ModerateCardContainer_comment",
                                    "args": null
                                }
                            ]
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "cursor",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "pageInfo",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "endCursor",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "hasNextPage",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        }
    ]
} as any);
(node as any).hash = 'eeeff082aa39e2dd7c18e75827a1f508';
export default node;
