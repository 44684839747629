/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { ModerateCardContainer_settings$ref } from "./ModerateCardContainer_settings.graphql";
declare const _QueueRoute_settings$ref: unique symbol;
export type QueueRoute_settings$ref = typeof _QueueRoute_settings$ref;
export type QueueRoute_settings = {
    readonly " $fragmentRefs": ModerateCardContainer_settings$ref;
    readonly " $refType": QueueRoute_settings$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "QueueRoute_settings",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "FragmentSpread",
            "name": "ModerateCardContainer_settings",
            "args": null
        }
    ]
} as any);
(node as any).hash = 'e9c28d2775d4c784153a74439f2c4780';
export default node;
