/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { UserStatusChangeContainer_settings$ref } from "./UserStatusChangeContainer_settings.graphql";
declare const _UserRowContainer_settings$ref: unique symbol;
export type UserRowContainer_settings$ref = typeof _UserRowContainer_settings$ref;
export type UserRowContainer_settings = {
    readonly " $fragmentRefs": UserStatusChangeContainer_settings$ref;
    readonly " $refType": UserRowContainer_settings$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "UserRowContainer_settings",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "FragmentSpread",
            "name": "UserStatusChangeContainer_settings",
            "args": null
        }
    ]
} as any);
(node as any).hash = '3104aa0ca76ec8bd835f07f8ab737500';
export default node;
