/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _ModerateNavigationContainer_story$ref: unique symbol;
export type ModerateNavigationContainer_story$ref = typeof _ModerateNavigationContainer_story$ref;
export type ModerateNavigationContainer_story = {
    readonly id: string;
    readonly " $refType": ModerateNavigationContainer_story$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "ModerateNavigationContainer_story",
    "type": "Story",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = 'f0a70c21a5f63d560f1603eb47cd916c';
export default node;
