/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { NavigationContainer_viewer$ref } from "./NavigationContainer_viewer.graphql";
import { UserMenuContainer_viewer$ref } from "./UserMenuContainer_viewer.graphql";
export type MainRouteQueryVariables = {};
export type MainRouteQueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": UserMenuContainer_viewer$ref & NavigationContainer_viewer$ref;
    } | null;
};
export type MainRouteQuery = {
    readonly response: MainRouteQueryResponse;
    readonly variables: MainRouteQueryVariables;
};



/*
query MainRouteQuery {
  viewer {
    ...UserMenuContainer_viewer
    ...NavigationContainer_viewer
    id
  }
}

fragment UserMenuContainer_viewer on User {
  username
}

fragment NavigationContainer_viewer on User {
  role
}
*/

const node: ConcreteRequest = ({
    "kind": "Request",
    "fragment": {
        "kind": "Fragment",
        "name": "MainRouteQuery",
        "type": "Query",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "viewer",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                    {
                        "kind": "FragmentSpread",
                        "name": "UserMenuContainer_viewer",
                        "args": null
                    },
                    {
                        "kind": "FragmentSpread",
                        "name": "NavigationContainer_viewer",
                        "args": null
                    }
                ]
            }
        ]
    },
    "operation": {
        "kind": "Operation",
        "name": "MainRouteQuery",
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "viewer",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "username",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "role",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "id",
                        "args": null,
                        "storageKey": null
                    }
                ]
            }
        ]
    },
    "params": {
        "operationKind": "query",
        "name": "MainRouteQuery",
        "id": "46ebfb697fcb264da811750093514c3e",
        "text": null,
        "metadata": {}
    }
} as any);
(node as any).hash = '2368fc40a3c961b64cc68ed766b1c838';
export default node;
