/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _UserDrawerAccountHistory_user$ref: unique symbol;
export type UserDrawerAccountHistory_user$ref = typeof _UserDrawerAccountHistory_user$ref;
export type UserDrawerAccountHistory_user = {
    readonly status: {
        readonly username: {
            readonly history: ReadonlyArray<{
                readonly username: string;
                readonly createdAt: string;
                readonly createdBy: {
                    readonly username: string | null;
                };
            }>;
        };
        readonly ban: {
            readonly history: ReadonlyArray<{
                readonly active: boolean;
                readonly createdBy: {
                    readonly username: string | null;
                } | null;
                readonly createdAt: string;
            }>;
        };
        readonly premod: {
            readonly history: ReadonlyArray<{
                readonly active: boolean;
                readonly createdBy: {
                    readonly username: string | null;
                };
                readonly createdAt: string;
            }>;
        };
        readonly suspension: {
            readonly history: ReadonlyArray<{
                readonly active: boolean;
                readonly from: {
                    readonly start: string;
                    readonly finish: string;
                };
                readonly createdBy: {
                    readonly username: string | null;
                } | null;
                readonly modifiedAt: string | null;
                readonly modifiedBy: {
                    readonly username: string | null;
                } | null;
                readonly createdAt: string;
            }>;
        };
    };
    readonly " $refType": UserDrawerAccountHistory_user$ref;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "username",
        "args": null,
        "storageKey": null
    } as any), v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "createdAt",
        "args": null,
        "storageKey": null
    } as any), v2 = [
        (v0 /*: any*/)
    ], v3 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "createdBy",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": (v2 /*: any*/)
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "active",
        "args": null,
        "storageKey": null
    } as any), v5 = [
        (v4 /*: any*/),
        (v3 /*: any*/),
        (v1 /*: any*/)
    ];
    return {
        "kind": "Fragment",
        "name": "UserDrawerAccountHistory_user",
        "type": "User",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "status",
                "storageKey": null,
                "args": null,
                "concreteType": "UserStatus",
                "plural": false,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "username",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UsernameStatus",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "history",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "UsernameHistory",
                                "plural": true,
                                "selections": [
                                    (v0 /*: any*/),
                                    (v1 /*: any*/),
                                    (v3 /*: any*/)
                                ]
                            }
                        ]
                    },
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "ban",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BanStatus",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "history",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BanStatusHistory",
                                "plural": true,
                                "selections": (v5 /*: any*/)
                            }
                        ]
                    },
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "premod",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PremodStatus",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "history",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "PremodStatusHistory",
                                "plural": true,
                                "selections": (v5 /*: any*/)
                            }
                        ]
                    },
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "suspension",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "SuspensionStatus",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "history",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "SuspensionStatusHistory",
                                "plural": true,
                                "selections": [
                                    (v4 /*: any*/),
                                    {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "from",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "TimeRange",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "start",
                                                "args": null,
                                                "storageKey": null
                                            },
                                            {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "finish",
                                                "args": null,
                                                "storageKey": null
                                            }
                                        ]
                                    },
                                    (v3 /*: any*/),
                                    {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "modifiedAt",
                                        "args": null,
                                        "storageKey": null
                                    },
                                    {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "modifiedBy",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "User",
                                        "plural": false,
                                        "selections": (v2 /*: any*/)
                                    },
                                    (v1 /*: any*/)
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    } as any;
})();
(node as any).hash = '925221473c98dd257c79e0c987bc84af';
export default node;
