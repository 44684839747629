/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _CommentAuthorContainer_comment$ref: unique symbol;
export type CommentAuthorContainer_comment$ref = typeof _CommentAuthorContainer_comment$ref;
export type CommentAuthorContainer_comment = {
    readonly author: {
        readonly id: string;
        readonly username: string | null;
        readonly status: {
            readonly ban: {
                readonly active: boolean;
            };
        };
    } | null;
    readonly " $refType": CommentAuthorContainer_comment$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "CommentAuthorContainer_comment",
    "type": "Comment",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "author",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "username",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "status",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserStatus",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "ban",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BanStatus",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "active",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
} as any);
(node as any).hash = 'b4d8dfe8cbee1d49eaa672a7182df131';
export default node;
