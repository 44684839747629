/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _CommentStreamLiveUpdates_formValues$ref: unique symbol;
export type CommentStreamLiveUpdates_formValues$ref = typeof _CommentStreamLiveUpdates_formValues$ref;
export type CommentStreamLiveUpdates_formValues = {
    readonly live: {
        readonly enabled: boolean;
    };
    readonly " $refType": CommentStreamLiveUpdates_formValues$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "CommentStreamLiveUpdates_formValues",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "live",
            "storageKey": null,
            "args": null,
            "concreteType": "LiveConfiguration",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "enabled",
                    "args": null,
                    "storageKey": null
                }
            ]
        }
    ]
} as any);
(node as any).hash = 'd418e7df06e4bad2cf01d0845f3ca116';
export default node;
