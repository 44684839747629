/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _BannedWordListConfig_formValues$ref: unique symbol;
export type BannedWordListConfig_formValues$ref = typeof _BannedWordListConfig_formValues$ref;
export type BannedWordListConfig_formValues = {
    readonly wordList: {
        readonly banned: ReadonlyArray<string>;
    };
    readonly " $refType": BannedWordListConfig_formValues$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "BannedWordListConfig_formValues",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "wordList",
            "storageKey": null,
            "args": null,
            "concreteType": "WordList",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "banned",
                    "args": null,
                    "storageKey": null
                }
            ]
        }
    ]
} as any);
(node as any).hash = '4b57e274f511f8f121ed7556696e06c8';
export default node;
