/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { DecisionHistoryContainer_viewer$ref } from "./DecisionHistoryContainer_viewer.graphql";
export type DecisionHistoryContainerPaginationQueryVariables = {
    readonly count: number;
    readonly cursor?: unknown | null;
};
export type DecisionHistoryContainerPaginationQueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": DecisionHistoryContainer_viewer$ref;
    } | null;
};
export type DecisionHistoryContainerPaginationQuery = {
    readonly response: DecisionHistoryContainerPaginationQueryResponse;
    readonly variables: DecisionHistoryContainerPaginationQueryVariables;
};



/*
query DecisionHistoryContainerPaginationQuery(
  $count: Int!
  $cursor: Cursor
) {
  viewer {
    ...DecisionHistoryContainer_viewer_1G22uz
    id
  }
}

fragment DecisionHistoryContainer_viewer_1G22uz on User {
  commentModerationActionHistory(first: $count, after: $cursor) {
    edges {
      node {
        id
        ...DecisionHistoryItemContainer_action
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment DecisionHistoryItemContainer_action on CommentModerationAction {
  id
  revision {
    id
    comment {
      id
      author {
        username
        id
      }
    }
  }
  createdAt
  status
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "count",
            "type": "Int!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "cursor",
            "type": "Cursor",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any),
        ({
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "DecisionHistoryContainerPaginationQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "viewer",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "DecisionHistoryContainer_viewer",
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "count",
                                    "variableName": "count"
                                },
                                {
                                    "kind": "Variable",
                                    "name": "cursor",
                                    "variableName": "cursor"
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "DecisionHistoryContainerPaginationQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "viewer",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "commentModerationActionHistory",
                            "storageKey": null,
                            "args": (v1 /*: any*/),
                            "concreteType": "CommentModerationActionConnection",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "edges",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "CommentModerationActionEdge",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "node",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "CommentModerationAction",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "revision",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "CommentRevision",
                                                    "plural": false,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        {
                                                            "kind": "LinkedField",
                                                            "alias": null,
                                                            "name": "comment",
                                                            "storageKey": null,
                                                            "args": null,
                                                            "concreteType": "Comment",
                                                            "plural": false,
                                                            "selections": [
                                                                (v2 /*: any*/),
                                                                {
                                                                    "kind": "LinkedField",
                                                                    "alias": null,
                                                                    "name": "author",
                                                                    "storageKey": null,
                                                                    "args": null,
                                                                    "concreteType": "User",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        {
                                                                            "kind": "ScalarField",
                                                                            "alias": null,
                                                                            "name": "username",
                                                                            "args": null,
                                                                            "storageKey": null
                                                                        },
                                                                        (v2 /*: any*/)
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "createdAt",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "status",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "__typename",
                                                    "args": null,
                                                    "storageKey": null
                                                }
                                            ]
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "cursor",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "pageInfo",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "PageInfo",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "endCursor",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "hasNextPage",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "kind": "LinkedHandle",
                            "alias": null,
                            "name": "commentModerationActionHistory",
                            "args": (v1 /*: any*/),
                            "handle": "connection",
                            "key": "DecisionHistory_commentModerationActionHistory",
                            "filters": null
                        },
                        (v2 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "DecisionHistoryContainerPaginationQuery",
            "id": "2ededa9fd096ecdbed22a830d02758c1",
            "text": null,
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '89d68b316552716e39b596d6c776a079';
export default node;
