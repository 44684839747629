/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _ReactionConfigContainer_settings$ref: unique symbol;
export type ReactionConfigContainer_settings$ref = typeof _ReactionConfigContainer_settings$ref;
export type ReactionConfigContainer_settings = {
    readonly reaction: {
        readonly icon: string;
        readonly iconActive: string | null;
    };
    readonly " $refType": ReactionConfigContainer_settings$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "ReactionConfigContainer_settings",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "reaction",
            "storageKey": null,
            "args": null,
            "concreteType": "ReactionConfiguration",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "icon",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "iconActive",
                    "args": null,
                    "storageKey": null
                }
            ]
        }
    ]
} as any);
(node as any).hash = 'a76d6844165af51388ee6cf432fc28ab';
export default node;
