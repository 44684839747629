/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { ModerateCardContainer_comment$ref } from "./ModerateCardContainer_comment.graphql";
import { ModerateCardContainer_settings$ref } from "./ModerateCardContainer_settings.graphql";
import { ModerateCardContainer_viewer$ref } from "./ModerateCardContainer_viewer.graphql";
declare const _RejectedQueueRoute_query$ref: unique symbol;
export type RejectedQueueRoute_query$ref = typeof _RejectedQueueRoute_query$ref;
export type RejectedQueueRoute_query = {
    readonly comments: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": ModerateCardContainer_comment$ref;
            };
        }>;
    };
    readonly settings: {
        readonly " $fragmentRefs": ModerateCardContainer_settings$ref;
    };
    readonly viewer: {
        readonly " $fragmentRefs": ModerateCardContainer_viewer$ref;
    } | null;
    readonly " $refType": RejectedQueueRoute_query$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "RejectedQueueRoute_query",
    "type": "Query",
    "metadata": {
        "connection": [
            {
                "count": "count",
                "cursor": "cursor",
                "direction": "forward",
                "path": [
                    "comments"
                ]
            }
        ]
    },
    "argumentDefinitions": [
        {
            "kind": "LocalArgument",
            "name": "count",
            "type": "Int!",
            "defaultValue": 5
        },
        {
            "kind": "LocalArgument",
            "name": "cursor",
            "type": "Cursor",
            "defaultValue": null
        },
        {
            "kind": "LocalArgument",
            "name": "storyID",
            "type": "ID",
            "defaultValue": null
        }
    ],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": "comments",
            "name": "__RejectedQueue_comments_connection",
            "storageKey": null,
            "args": [
                {
                    "kind": "Literal",
                    "name": "status",
                    "value": "REJECTED"
                },
                {
                    "kind": "Variable",
                    "name": "storyID",
                    "variableName": "storyID"
                }
            ],
            "concreteType": "CommentsConnection",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CommentEdge",
                    "plural": true,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Comment",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "id",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "__typename",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "FragmentSpread",
                                    "name": "ModerateCardContainer_comment",
                                    "args": null
                                }
                            ]
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "cursor",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "pageInfo",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "endCursor",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "hasNextPage",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        },
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "settings",
            "storageKey": null,
            "args": null,
            "concreteType": "Settings",
            "plural": false,
            "selections": [
                {
                    "kind": "FragmentSpread",
                    "name": "ModerateCardContainer_settings",
                    "args": null
                }
            ]
        },
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
                {
                    "kind": "FragmentSpread",
                    "name": "ModerateCardContainer_viewer",
                    "args": null
                }
            ]
        }
    ]
} as any);
(node as any).hash = 'ca2161fff526cb173dcce720507699dc';
export default node;
