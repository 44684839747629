/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { UserDrawerNotesContainer_user$ref } from "./UserDrawerNotesContainer_user.graphql";
import { UserDrawerNotesContainer_viewer$ref } from "./UserDrawerNotesContainer_viewer.graphql";
export type UserDrawerNotesQueryVariables = {
    readonly userID: string;
};
export type UserDrawerNotesQueryResponse = {
    readonly user: {
        readonly " $fragmentRefs": UserDrawerNotesContainer_user$ref;
    } | null;
    readonly viewer: {
        readonly " $fragmentRefs": UserDrawerNotesContainer_viewer$ref;
    } | null;
};
export type UserDrawerNotesQuery = {
    readonly response: UserDrawerNotesQueryResponse;
    readonly variables: UserDrawerNotesQueryVariables;
};



/*
query UserDrawerNotesQuery(
  $userID: ID!
) {
  user(id: $userID) {
    ...UserDrawerNotesContainer_user
    id
  }
  viewer {
    ...UserDrawerNotesContainer_viewer
    id
  }
}

fragment UserDrawerNotesContainer_user on User {
  id
  moderatorNotes {
    id
    body
    createdAt
    createdBy {
      username
      id
    }
  }
}

fragment UserDrawerNotesContainer_viewer on User {
  id
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "userID",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "userID"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "UserDrawerNotesQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "UserDrawerNotesContainer_user",
                            "args": null
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "viewer",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "UserDrawerNotesContainer_viewer",
                            "args": null
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "UserDrawerNotesQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "moderatorNotes",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ModeratorNote",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "body",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "createdAt",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "createdBy",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "username",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        (v2 /*: any*/)
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "viewer",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "UserDrawerNotesQuery",
            "id": "8f6744cfeb774c321386483853406157",
            "text": null,
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '00043feb368684c4006b4da48154ea4a';
export default node;
