/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { UserStatusContainer_user$ref } from "./UserStatusContainer_user.graphql";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MODERATOR" | "STAFF" | "%future added value";
declare const _UserStatusChangeContainer_user$ref: unique symbol;
export type UserStatusChangeContainer_user$ref = typeof _UserStatusChangeContainer_user$ref;
export type UserStatusChangeContainer_user = {
    readonly id: string;
    readonly role: USER_ROLE;
    readonly username: string | null;
    readonly status: {
        readonly ban: {
            readonly active: boolean;
        };
        readonly suspension: {
            readonly active: boolean;
        };
        readonly premod: {
            readonly active: boolean;
        };
    };
    readonly " $fragmentRefs": UserStatusContainer_user$ref;
    readonly " $refType": UserStatusChangeContainer_user$ref;
};



const node: ReaderFragment = (function () {
    var v0 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "active",
            "args": null,
            "storageKey": null
        } as any)
    ];
    return {
        "kind": "Fragment",
        "name": "UserStatusChangeContainer_user",
        "type": "User",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "role",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "username",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "status",
                "storageKey": null,
                "args": null,
                "concreteType": "UserStatus",
                "plural": false,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "ban",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BanStatus",
                        "plural": false,
                        "selections": (v0 /*: any*/)
                    },
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "suspension",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "SuspensionStatus",
                        "plural": false,
                        "selections": (v0 /*: any*/)
                    },
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "premod",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PremodStatus",
                        "plural": false,
                        "selections": (v0 /*: any*/)
                    }
                ]
            },
            {
                "kind": "FragmentSpread",
                "name": "UserStatusContainer_user",
                "args": null
            }
        ]
    } as any;
})();
(node as any).hash = '21a8ecf1b45196e5594933de80820232';
export default node;
