/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { StoryTableContainer_query$ref } from "./StoryTableContainer_query.graphql";
export type STORY_STATUS = "CLOSED" | "OPEN" | "%future added value";
export type StoryTableContainerPaginationQueryVariables = {
    readonly count: number;
    readonly cursor?: unknown | null;
    readonly statusFilter?: STORY_STATUS | null;
    readonly searchFilter?: string | null;
};
export type StoryTableContainerPaginationQueryResponse = {
    readonly " $fragmentRefs": StoryTableContainer_query$ref;
};
export type StoryTableContainerPaginationQuery = {
    readonly response: StoryTableContainerPaginationQueryResponse;
    readonly variables: StoryTableContainerPaginationQueryVariables;
};



/*
query StoryTableContainerPaginationQuery(
  $count: Int!
  $cursor: Cursor
  $statusFilter: STORY_STATUS
  $searchFilter: String
) {
  ...StoryTableContainer_query_2VLuTh
}

fragment StoryTableContainer_query_2VLuTh on Query {
  viewer {
    ...StoryRowContainer_viewer
    id
  }
  stories(first: $count, after: $cursor, status: $statusFilter, query: $searchFilter) {
    edges {
      node {
        id
        ...StoryRowContainer_story
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment StoryRowContainer_viewer on User {
  id
  ...StoryStatusChangeContainer_viewer
}

fragment StoryRowContainer_story on Story {
  id
  metadata {
    title
    author
    publishedAt
  }
  isClosed
  ...StoryStatusChangeContainer_story
}

fragment StoryStatusChangeContainer_story on Story {
  id
  status
}

fragment StoryStatusChangeContainer_viewer on User {
  id
  role
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "count",
            "type": "Int!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "cursor",
            "type": "Cursor",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "statusFilter",
            "type": "STORY_STATUS",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "searchFilter",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = [
        ({
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any),
        ({
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any),
        ({
            "kind": "Variable",
            "name": "query",
            "variableName": "searchFilter"
        } as any),
        ({
            "kind": "Variable",
            "name": "status",
            "variableName": "statusFilter"
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "StoryTableContainerPaginationQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "FragmentSpread",
                    "name": "StoryTableContainer_query",
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "count",
                            "variableName": "count"
                        },
                        {
                            "kind": "Variable",
                            "name": "cursor",
                            "variableName": "cursor"
                        },
                        {
                            "kind": "Variable",
                            "name": "searchFilter",
                            "variableName": "searchFilter"
                        },
                        {
                            "kind": "Variable",
                            "name": "statusFilter",
                            "variableName": "statusFilter"
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "StoryTableContainerPaginationQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "viewer",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "role",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "stories",
                    "storageKey": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "StoriesConnection",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "StoryEdge",
                            "plural": true,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Story",
                                    "plural": false,
                                    "selections": [
                                        (v1 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "metadata",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "StoryMetadata",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "title",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "author",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "publishedAt",
                                                    "args": null,
                                                    "storageKey": null
                                                }
                                            ]
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "isClosed",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "status",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "__typename",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "cursor",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "pageInfo",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "endCursor",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "hasNextPage",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                },
                {
                    "kind": "LinkedHandle",
                    "alias": null,
                    "name": "stories",
                    "args": (v2 /*: any*/),
                    "handle": "connection",
                    "key": "StoryTable_stories",
                    "filters": [
                        "status",
                        "query"
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "StoryTableContainerPaginationQuery",
            "id": "861ce5589c0672d8b051a19b0ef8f4ba",
            "text": null,
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'cf132a6243dce3b8afb00674557b9b56';
export default node;
