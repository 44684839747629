/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _CustomCSSConfig_formValues$ref: unique symbol;
export type CustomCSSConfig_formValues$ref = typeof _CustomCSSConfig_formValues$ref;
export type CustomCSSConfig_formValues = {
    readonly customCSSURL: string | null;
    readonly " $refType": CustomCSSConfig_formValues$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "CustomCSSConfig_formValues",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "customCSSURL",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = 'd118185ac6db458cfa588548dc4e3f34';
export default node;
