/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { AdvancedConfigContainer_settings$ref } from "./AdvancedConfigContainer_settings.graphql";
export type AdvancedConfigRouteQueryVariables = {};
export type AdvancedConfigRouteQueryResponse = {
    readonly settings: {
        readonly " $fragmentRefs": AdvancedConfigContainer_settings$ref;
    };
};
export type AdvancedConfigRouteQuery = {
    readonly response: AdvancedConfigRouteQueryResponse;
    readonly variables: AdvancedConfigRouteQueryVariables;
};



/*
query AdvancedConfigRouteQuery {
  settings {
    ...AdvancedConfigContainer_settings
    id
  }
}

fragment AdvancedConfigContainer_settings on Settings {
  customCSSURL
  allowedDomains
  live {
    enabled
  }
  stories {
    scraping {
      enabled
      proxyURL
      customUserAgent
    }
    disableLazy
  }
  ...EmbedCodeContainer_settings
  ...CommentStreamLiveUpdatesContainer_settings
}

fragment EmbedCodeContainer_settings on Settings {
  staticURI
}

fragment CommentStreamLiveUpdatesContainer_settings on Settings {
  live {
    configurable
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "enabled",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "AdvancedConfigRouteQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "settings",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Settings",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "AdvancedConfigContainer_settings",
                            "args": null
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "AdvancedConfigRouteQuery",
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "settings",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Settings",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "customCSSURL",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "allowedDomains",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "live",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "LiveConfiguration",
                            "plural": false,
                            "selections": [
                                (v0 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "configurable",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "stories",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "StoryConfiguration",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "scraping",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "StoryScrapingConfiguration",
                                    "plural": false,
                                    "selections": [
                                        (v0 /*: any*/),
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "proxyURL",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "customUserAgent",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "disableLazy",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "staticURI",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "AdvancedConfigRouteQuery",
            "id": "cc7caa7feb7c26931ea2f5fe47ba4716",
            "text": null,
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'ca6346567a101832b8b3826d41af699c';
export default node;
