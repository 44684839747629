/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { CommentStreamLiveUpdatesContainer_settings$ref } from "./CommentStreamLiveUpdatesContainer_settings.graphql";
import { EmbedCodeContainer_settings$ref } from "./EmbedCodeContainer_settings.graphql";
declare const _AdvancedConfigContainer_settings$ref: unique symbol;
export type AdvancedConfigContainer_settings$ref = typeof _AdvancedConfigContainer_settings$ref;
export type AdvancedConfigContainer_settings = {
    readonly customCSSURL: string | null;
    readonly allowedDomains: ReadonlyArray<string>;
    readonly live: {
        readonly enabled: boolean;
    };
    readonly stories: {
        readonly scraping: {
            readonly enabled: boolean;
            readonly proxyURL: string | null;
            readonly customUserAgent: string | null;
        };
        readonly disableLazy: boolean;
    };
    readonly " $fragmentRefs": EmbedCodeContainer_settings$ref & CommentStreamLiveUpdatesContainer_settings$ref;
    readonly " $refType": AdvancedConfigContainer_settings$ref;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "enabled",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Fragment",
        "name": "AdvancedConfigContainer_settings",
        "type": "Settings",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "customCSSURL",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "allowedDomains",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "live",
                "storageKey": null,
                "args": null,
                "concreteType": "LiveConfiguration",
                "plural": false,
                "selections": [
                    (v0 /*: any*/)
                ]
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "stories",
                "storageKey": null,
                "args": null,
                "concreteType": "StoryConfiguration",
                "plural": false,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "scraping",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StoryScrapingConfiguration",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "proxyURL",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "customUserAgent",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "disableLazy",
                        "args": null,
                        "storageKey": null
                    }
                ]
            },
            {
                "kind": "FragmentSpread",
                "name": "EmbedCodeContainer_settings",
                "args": null
            },
            {
                "kind": "FragmentSpread",
                "name": "CommentStreamLiveUpdatesContainer_settings",
                "args": null
            }
        ]
    } as any;
})();
(node as any).hash = '3231f185c8f8b5bd8b0c4efdcfbbc293';
export default node;
