/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
export type MODERATION_MODE = "POST" | "PRE" | "%future added value";
declare const _PreModerationConfig_formValues$ref: unique symbol;
export type PreModerationConfig_formValues$ref = typeof _PreModerationConfig_formValues$ref;
export type PreModerationConfig_formValues = {
    readonly moderation: MODERATION_MODE | null;
    readonly premodLinksEnable: boolean | null;
    readonly " $refType": PreModerationConfig_formValues$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "PreModerationConfig_formValues",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "moderation",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "premodLinksEnable",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = 'e8b4e469b4d8085b27c51b9f172da014';
export default node;
