/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type SearchStoryFetchQueryVariables = {
    readonly query: string;
    readonly limit: number;
};
export type SearchStoryFetchQueryResponse = {
    readonly stories: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly metadata: {
                    readonly title: string | null;
                    readonly author: string | null;
                } | null;
            };
        }>;
        readonly pageInfo: {
            readonly hasNextPage: boolean;
        };
    };
};
export type SearchStoryFetchQuery = {
    readonly response: SearchStoryFetchQueryResponse;
    readonly variables: SearchStoryFetchQueryVariables;
};



/*
query SearchStoryFetchQuery(
  $query: String!
  $limit: Int!
) {
  stories(query: $query, first: $limit) {
    edges {
      node {
        id
        metadata {
          title
          author
        }
      }
    }
    pageInfo {
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "query",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "limit",
            "type": "Int!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "stories",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "first",
                    "variableName": "limit"
                },
                {
                    "kind": "Variable",
                    "name": "query",
                    "variableName": "query"
                }
            ],
            "concreteType": "StoriesConnection",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StoryEdge",
                    "plural": true,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Story",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "id",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "metadata",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "StoryMetadata",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "title",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "author",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "pageInfo",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "hasNextPage",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "SearchStoryFetchQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "SearchStoryFetchQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "SearchStoryFetchQuery",
            "id": "6e831c14d2aaae9c0d0d61d692458591",
            "text": null,
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'dae4dda994c4a0d1d90d1ea153c2b2cc';
export default node;
