/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _EmbedCodeContainer_settings$ref: unique symbol;
export type EmbedCodeContainer_settings$ref = typeof _EmbedCodeContainer_settings$ref;
export type EmbedCodeContainer_settings = {
    readonly staticURI: string | null;
    readonly " $refType": EmbedCodeContainer_settings$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "EmbedCodeContainer_settings",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "staticURI",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = 'f465e9f6c233b0018245765b5be73a8c';
export default node;
