/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
export type View = "ADD_EMAIL_ADDRESS" | "CREATE_PASSWORD" | "CREATE_USERNAME" | "SIGN_IN" | "%future added value";
declare const _AccountCompletionContainerLocal$ref: unique symbol;
export type AccountCompletionContainerLocal$ref = typeof _AccountCompletionContainerLocal$ref;
export type AccountCompletionContainerLocal = {
    readonly accessToken: string | null;
    readonly authView: View | null;
    readonly redirectPath: string | null;
    readonly " $refType": AccountCompletionContainerLocal$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "AccountCompletionContainerLocal",
    "type": "Local",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ClientExtension",
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "accessToken",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "authView",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "redirectPath",
                    "args": null,
                    "storageKey": null
                }
            ]
        }
    ]
} as any);
(node as any).hash = '4597f8ed3c1383be7ee632e5f03f3b95';
export default node;
