/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _CommentStreamLiveUpdatesContainer_settings$ref: unique symbol;
export type CommentStreamLiveUpdatesContainer_settings$ref = typeof _CommentStreamLiveUpdatesContainer_settings$ref;
export type CommentStreamLiveUpdatesContainer_settings = {
    readonly live: {
        readonly configurable: boolean;
    };
    readonly " $refType": CommentStreamLiveUpdatesContainer_settings$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "CommentStreamLiveUpdatesContainer_settings",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "live",
            "storageKey": null,
            "args": null,
            "concreteType": "LiveConfiguration",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "configurable",
                    "args": null,
                    "storageKey": null
                }
            ]
        }
    ]
} as any);
(node as any).hash = '949160225b15bb16f69e635cc0092da8';
export default node;
