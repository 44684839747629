/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _PerspectiveConfig_formValues$ref: unique symbol;
export type PerspectiveConfig_formValues$ref = typeof _PerspectiveConfig_formValues$ref;
export type PerspectiveConfig_formValues = {
    readonly integrations: {
        readonly perspective: {
            readonly enabled: boolean;
            readonly endpoint: string | null;
            readonly key: string | null;
            readonly model: string | null;
            readonly threshold: number | null;
            readonly doNotStore: boolean | null;
            readonly sendFeedback: boolean | null;
        };
    };
    readonly " $refType": PerspectiveConfig_formValues$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "PerspectiveConfig_formValues",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "integrations",
            "storageKey": null,
            "args": null,
            "concreteType": "ExternalIntegrations",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "perspective",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PerspectiveExternalIntegration",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "enabled",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "endpoint",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "key",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "model",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "threshold",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "doNotStore",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "sendFeedback",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        }
    ]
} as any);
(node as any).hash = '9ca742819520737478420dec24bfaa2e';
export default node;
