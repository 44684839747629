/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MODERATOR" | "STAFF" | "%future added value";
declare const _InviteUsersContainer_viewer$ref: unique symbol;
export type InviteUsersContainer_viewer$ref = typeof _InviteUsersContainer_viewer$ref;
export type InviteUsersContainer_viewer = {
    readonly role: USER_ROLE;
    readonly " $refType": InviteUsersContainer_viewer$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "InviteUsersContainer_viewer",
    "type": "User",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "role",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = '3163a41639bc310cbc6c5887fee55cf8';
export default node;
