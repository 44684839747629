/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { StoryStatusChangeContainer_viewer$ref } from "./StoryStatusChangeContainer_viewer.graphql";
declare const _StoryRowContainer_viewer$ref: unique symbol;
export type StoryRowContainer_viewer$ref = typeof _StoryRowContainer_viewer$ref;
export type StoryRowContainer_viewer = {
    readonly id: string;
    readonly " $fragmentRefs": StoryStatusChangeContainer_viewer$ref;
    readonly " $refType": StoryRowContainer_viewer$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "StoryRowContainer_viewer",
    "type": "User",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "FragmentSpread",
            "name": "StoryStatusChangeContainer_viewer",
            "args": null
        }
    ]
} as any);
(node as any).hash = '7bcb1d902ee8017d8de3558b9ca35f3d';
export default node;
