/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _GoogleConfigContainer_auth$ref: unique symbol;
export type GoogleConfigContainer_auth$ref = typeof _GoogleConfigContainer_auth$ref;
export type GoogleConfigContainer_auth = {
    readonly integrations: {
        readonly google: {
            readonly callbackURL: string;
        };
    };
    readonly " $refType": GoogleConfigContainer_auth$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "GoogleConfigContainer_auth",
    "type": "Auth",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "integrations",
            "storageKey": null,
            "args": null,
            "concreteType": "AuthIntegrations",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "google",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "GoogleAuthIntegration",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "callbackURL",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        }
    ]
} as any);
(node as any).hash = '62093243da4a030bc139f307c62a286e';
export default node;
