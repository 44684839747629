/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _EmailConfigContainer_email$ref: unique symbol;
export type EmailConfigContainer_email$ref = typeof _EmailConfigContainer_email$ref;
export type EmailConfigContainer_email = {
    readonly enabled: boolean;
    readonly fromName: string | null;
    readonly fromEmail: string | null;
    readonly smtp: {
        readonly host: string | null;
        readonly port: number | null;
        readonly secure: boolean | null;
        readonly authentication: boolean | null;
        readonly username: string | null;
        readonly password: string | null;
    };
    readonly " $refType": EmailConfigContainer_email$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "EmailConfigContainer_email",
    "type": "EmailConfiguration",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "enabled",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "fromName",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "fromEmail",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "smtp",
            "storageKey": null,
            "args": null,
            "concreteType": "SMTP",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "host",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "port",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "secure",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "authentication",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "username",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "password",
                    "args": null,
                    "storageKey": null
                }
            ]
        }
    ]
} as any);
(node as any).hash = '7be489e312039634145b9c750a095434';
export default node;
