/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _SignInWithFacebookContainer_auth$ref: unique symbol;
export type SignInWithFacebookContainer_auth$ref = typeof _SignInWithFacebookContainer_auth$ref;
export type SignInWithFacebookContainer_auth = {
    readonly integrations: {
        readonly facebook: {
            readonly redirectURL: string;
        };
    };
    readonly " $refType": SignInWithFacebookContainer_auth$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "SignInWithFacebookContainer_auth",
    "type": "Auth",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "integrations",
            "storageKey": null,
            "args": null,
            "concreteType": "AuthIntegrations",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "facebook",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FacebookAuthIntegration",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "redirectURL",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        }
    ]
} as any);
(node as any).hash = 'c900b31de00905cf8fa7f64e3e5da29c';
export default node;
