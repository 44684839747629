/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MODERATOR" | "STAFF" | "%future added value";
export type InviteUsersInput = {
    readonly emails: ReadonlyArray<string>;
    readonly role: USER_ROLE;
    readonly clientMutationId: string;
};
export type InviteUsersMutationVariables = {
    readonly input: InviteUsersInput;
};
export type InviteUsersMutationResponse = {
    readonly inviteUsers: {
        readonly clientMutationId: string;
        readonly invites: ReadonlyArray<{
            readonly id: string;
            readonly email: string;
        } | null>;
    };
};
export type InviteUsersMutation = {
    readonly response: InviteUsersMutationResponse;
    readonly variables: InviteUsersMutationVariables;
};



/*
mutation InviteUsersMutation(
  $input: InviteUsersInput!
) {
  inviteUsers(input: $input) {
    clientMutationId
    invites {
      id
      email
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "InviteUsersInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "inviteUsers",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "InviteUsersPayload",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "clientMutationId",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "invites",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Invite",
                    "plural": true,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "email",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "InviteUsersMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "InviteUsersMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "InviteUsersMutation",
            "id": "9951e8dabb46431923c365989f541aab",
            "text": null,
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'de744cb7e39f30cc173d52b014b49848';
export default node;
