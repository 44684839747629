/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type SetUsernameInput = {
    readonly username: string;
    readonly clientMutationId: string;
};
export type SetUsernameMutationVariables = {
    readonly input: SetUsernameInput;
};
export type SetUsernameMutationResponse = {
    readonly setUsername: {
        readonly user: {
            readonly username: string | null;
        };
        readonly clientMutationId: string;
    };
};
export type SetUsernameMutation = {
    readonly response: SetUsernameMutationResponse;
    readonly variables: SetUsernameMutationVariables;
};



/*
mutation SetUsernameMutation(
  $input: SetUsernameInput!
) {
  setUsername(input: $input) {
    user {
      username
      id
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "SetUsernameInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "username",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "SetUsernameMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "setUsername",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "SetUsernamePayload",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "User",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/)
                            ]
                        },
                        (v3 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "SetUsernameMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "setUsername",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "SetUsernamePayload",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "User",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "id",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        (v3 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "mutation",
            "name": "SetUsernameMutation",
            "id": "e337fed48a0ebc48295caf3d434056f7",
            "text": null,
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '56444e0788d0cd2cd3d0ba824525f3b2';
export default node;
