/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _UserDrawerNotesContainer_user$ref: unique symbol;
export type UserDrawerNotesContainer_user$ref = typeof _UserDrawerNotesContainer_user$ref;
export type UserDrawerNotesContainer_user = {
    readonly id: string;
    readonly moderatorNotes: ReadonlyArray<{
        readonly id: string;
        readonly body: string;
        readonly createdAt: string;
        readonly createdBy: {
            readonly username: string | null;
            readonly id: string;
        };
    }>;
    readonly " $refType": UserDrawerNotesContainer_user$ref;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Fragment",
        "name": "UserDrawerNotesContainer_user",
        "type": "User",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            (v0 /*: any*/),
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "moderatorNotes",
                "storageKey": null,
                "args": null,
                "concreteType": "ModeratorNote",
                "plural": true,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "body",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "createdBy",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "username",
                                "args": null,
                                "storageKey": null
                            },
                            (v0 /*: any*/)
                        ]
                    }
                ]
            }
        ]
    } as any;
})();
(node as any).hash = '5d13921bf0f07480b17533ea9ed373f7';
export default node;
