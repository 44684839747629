/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type DiscoverOIDCConfigurationFetchQueryVariables = {
    readonly issuer: string;
};
export type DiscoverOIDCConfigurationFetchQueryResponse = {
    readonly discoverOIDCConfiguration: {
        readonly issuer: string;
        readonly authorizationURL: string;
        readonly tokenURL: string | null;
        readonly jwksURI: string;
    } | null;
};
export type DiscoverOIDCConfigurationFetchQuery = {
    readonly response: DiscoverOIDCConfigurationFetchQueryResponse;
    readonly variables: DiscoverOIDCConfigurationFetchQueryVariables;
};



/*
query DiscoverOIDCConfigurationFetchQuery(
  $issuer: String!
) {
  discoverOIDCConfiguration(issuer: $issuer) {
    issuer
    authorizationURL
    tokenURL
    jwksURI
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "issuer",
            "type": "String!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "discoverOIDCConfiguration",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "issuer",
                    "variableName": "issuer"
                }
            ],
            "concreteType": "DiscoveredOIDCConfiguration",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "issuer",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "authorizationURL",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "tokenURL",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "jwksURI",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "DiscoverOIDCConfigurationFetchQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "DiscoverOIDCConfigurationFetchQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "DiscoverOIDCConfigurationFetchQuery",
            "id": "7b764c8c2f09c1a2cdba1305e307c2be",
            "text": null,
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '29dbfe522d0835d3fd12eb81455de0f5';
export default node;
