/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { StoryTableContainer_query$ref } from "./StoryTableContainer_query.graphql";
export type StoriesRouteQueryVariables = {
    readonly searchFilter?: string | null;
};
export type StoriesRouteQueryResponse = {
    readonly " $fragmentRefs": StoryTableContainer_query$ref;
};
export type StoriesRouteQuery = {
    readonly response: StoriesRouteQueryResponse;
    readonly variables: StoriesRouteQueryVariables;
};



/*
query StoriesRouteQuery(
  $searchFilter: String
) {
  ...StoryTableContainer_query_3Tn0NJ
}

fragment StoryTableContainer_query_3Tn0NJ on Query {
  viewer {
    ...StoryRowContainer_viewer
    id
  }
  stories(first: 10, query: $searchFilter) {
    edges {
      node {
        id
        ...StoryRowContainer_story
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment StoryRowContainer_viewer on User {
  id
  ...StoryStatusChangeContainer_viewer
}

fragment StoryRowContainer_story on Story {
  id
  metadata {
    title
    author
    publishedAt
  }
  isClosed
  ...StoryStatusChangeContainer_story
}

fragment StoryStatusChangeContainer_story on Story {
  id
  status
}

fragment StoryStatusChangeContainer_viewer on User {
  id
  role
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "searchFilter",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = [
        ({
            "kind": "Literal",
            "name": "first",
            "value": 10
        } as any),
        ({
            "kind": "Variable",
            "name": "query",
            "variableName": "searchFilter"
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "StoriesRouteQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "FragmentSpread",
                    "name": "StoryTableContainer_query",
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "searchFilter",
                            "variableName": "searchFilter"
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "StoriesRouteQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "viewer",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "role",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "stories",
                    "storageKey": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "StoriesConnection",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "StoryEdge",
                            "plural": true,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Story",
                                    "plural": false,
                                    "selections": [
                                        (v1 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "metadata",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "StoryMetadata",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "title",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "author",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "publishedAt",
                                                    "args": null,
                                                    "storageKey": null
                                                }
                                            ]
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "isClosed",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "status",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "__typename",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "cursor",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "pageInfo",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "endCursor",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "hasNextPage",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                },
                {
                    "kind": "LinkedHandle",
                    "alias": null,
                    "name": "stories",
                    "args": (v2 /*: any*/),
                    "handle": "connection",
                    "key": "StoryTable_stories",
                    "filters": [
                        "status",
                        "query"
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "StoriesRouteQuery",
            "id": "72763221abade0fe98edb8776183f940",
            "text": null,
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'd402bd5fc96864cdd41d3ec701ef8212';
export default node;
