/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type USER_STATUS = "ACTIVE" | "BANNED" | "PREMOD" | "SUSPENDED" | "%future added value";
export type SuspendUserInput = {
    readonly userID: string;
    readonly timeout: number;
    readonly clientMutationId: string;
    readonly message: string;
};
export type SuspendUserMutationVariables = {
    readonly input: SuspendUserInput;
};
export type SuspendUserMutationResponse = {
    readonly suspendUser: {
        readonly user: {
            readonly id: string;
            readonly status: {
                readonly current: ReadonlyArray<USER_STATUS>;
                readonly suspension: {
                    readonly active: boolean;
                    readonly history: ReadonlyArray<{
                        readonly active: boolean;
                        readonly createdAt: string;
                        readonly from: {
                            readonly start: string;
                            readonly finish: string;
                        };
                        readonly createdBy: {
                            readonly id: string;
                            readonly username: string | null;
                        } | null;
                    }>;
                };
            };
        };
        readonly clientMutationId: string;
    };
};
export type SuspendUserMutation = {
    readonly response: SuspendUserMutationResponse;
    readonly variables: SuspendUserMutationVariables;
};



/*
mutation SuspendUserMutation(
  $input: SuspendUserInput!
) {
  suspendUser(input: $input) {
    user {
      id
      status {
        current
        suspension {
          active
          history {
            active
            createdAt
            from {
              start
              finish
            }
            createdBy {
              id
              username
            }
          }
        }
      }
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "SuspendUserInput!",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "active",
        "args": null,
        "storageKey": null
    } as any), v3 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "suspendUser",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "SuspendUserPayload",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "status",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "UserStatus",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "current",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "suspension",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "SuspensionStatus",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "history",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "SuspensionStatusHistory",
                                            "plural": true,
                                            "selections": [
                                                (v2 /*: any*/),
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "createdAt",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "from",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "TimeRange",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "kind": "ScalarField",
                                                            "alias": null,
                                                            "name": "start",
                                                            "args": null,
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "kind": "ScalarField",
                                                            "alias": null,
                                                            "name": "finish",
                                                            "args": null,
                                                            "storageKey": null
                                                        }
                                                    ]
                                                },
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "createdBy",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "User",
                                                    "plural": false,
                                                    "selections": [
                                                        (v1 /*: any*/),
                                                        {
                                                            "kind": "ScalarField",
                                                            "alias": null,
                                                            "name": "username",
                                                            "args": null,
                                                            "storageKey": null
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "clientMutationId",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "SuspendUserMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v3 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "SuspendUserMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v3 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "SuspendUserMutation",
            "id": "8c23d58421acfe618377415c0bbb61c9",
            "text": null,
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '43ccad44a40a0b81f25baf7ecab8d298';
export default node;
