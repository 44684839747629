/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _SignInContainerLocal$ref: unique symbol;
export type SignInContainerLocal$ref = typeof _SignInContainerLocal$ref;
export type SignInContainerLocal = {
    readonly authError: string | null;
    readonly " $refType": SignInContainerLocal$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "SignInContainerLocal",
    "type": "Local",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ClientExtension",
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "authError",
                    "args": null,
                    "storageKey": null
                }
            ]
        }
    ]
} as any);
(node as any).hash = '6a84dbbf1fa5835e4490c797d57e8830';
export default node;
