/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { WordListConfigContainer_settings$ref } from "./WordListConfigContainer_settings.graphql";
export type WordListConfigRouteQueryVariables = {};
export type WordListConfigRouteQueryResponse = {
    readonly settings: {
        readonly " $fragmentRefs": WordListConfigContainer_settings$ref;
    };
};
export type WordListConfigRouteQuery = {
    readonly response: WordListConfigRouteQueryResponse;
    readonly variables: WordListConfigRouteQueryVariables;
};



/*
query WordListConfigRouteQuery {
  settings {
    ...WordListConfigContainer_settings
    id
  }
}

fragment WordListConfigContainer_settings on Settings {
  wordList {
    suspect
    banned
  }
}
*/

const node: ConcreteRequest = ({
    "kind": "Request",
    "fragment": {
        "kind": "Fragment",
        "name": "WordListConfigRouteQuery",
        "type": "Query",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "settings",
                "storageKey": null,
                "args": null,
                "concreteType": "Settings",
                "plural": false,
                "selections": [
                    {
                        "kind": "FragmentSpread",
                        "name": "WordListConfigContainer_settings",
                        "args": null
                    }
                ]
            }
        ]
    },
    "operation": {
        "kind": "Operation",
        "name": "WordListConfigRouteQuery",
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "settings",
                "storageKey": null,
                "args": null,
                "concreteType": "Settings",
                "plural": false,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "wordList",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "WordList",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "suspect",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "banned",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "id",
                        "args": null,
                        "storageKey": null
                    }
                ]
            }
        ]
    },
    "params": {
        "operationKind": "query",
        "name": "WordListConfigRouteQuery",
        "id": "a33c1c7d03edd191d95a21ab2660a66c",
        "text": null,
        "metadata": {}
    }
} as any);
(node as any).hash = 'e1f093a1dde24d4d01851b1cc7d70d6c';
export default node;
