/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _FlagDetailsContainer_settings$ref: unique symbol;
export type FlagDetailsContainer_settings$ref = typeof _FlagDetailsContainer_settings$ref;
export type FlagDetailsContainer_settings = {
    readonly integrations: {
        readonly perspective: {
            readonly threshold: number | null;
        };
    };
    readonly " $refType": FlagDetailsContainer_settings$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "FlagDetailsContainer_settings",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "integrations",
            "storageKey": null,
            "args": null,
            "concreteType": "ExternalIntegrations",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "perspective",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PerspectiveExternalIntegration",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "threshold",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        }
    ]
} as any);
(node as any).hash = '6560692c88ed8b225d5000ef8804b957';
export default node;
