/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { AdvancedConfigContainer_settings$ref } from "./AdvancedConfigContainer_settings.graphql";
import { AuthConfigContainer_auth$ref } from "./AuthConfigContainer_auth.graphql";
import { EmailConfigContainer_email$ref } from "./EmailConfigContainer_email.graphql";
import { GeneralConfigContainer_settings$ref } from "./GeneralConfigContainer_settings.graphql";
import { ModerationConfigContainer_settings$ref } from "./ModerationConfigContainer_settings.graphql";
import { OrganizationConfigContainer_settings$ref } from "./OrganizationConfigContainer_settings.graphql";
import { SlackConfigContainer_settings$ref } from "./SlackConfigContainer_settings.graphql";
import { WordListConfigContainer_settings$ref } from "./WordListConfigContainer_settings.graphql";
export type MODERATION_MODE = "POST" | "PRE" | "%future added value";
export type UpdateSettingsInput = {
    readonly settings: SettingsInput;
    readonly clientMutationId: string;
};
export type SettingsInput = {
    readonly live?: LiveConfigurationInput | null;
    readonly allowedDomains?: ReadonlyArray<string> | null;
    readonly moderation?: MODERATION_MODE | null;
    readonly communityGuidelines?: SettingsCommunityGuidelinesInput | null;
    readonly premodLinksEnable?: boolean | null;
    readonly customCSSURL?: string | null;
    readonly disableCommenting?: SettingsDisableCommentingInput | null;
    readonly editCommentWindowLength?: number | null;
    readonly organization?: SettingsOrganizationInput | null;
    readonly closeCommenting?: SettingsCloseCommentingInput | null;
    readonly wordList?: SettingsWordListInput | null;
    readonly email?: SettingsEmailConfigurationInput | null;
    readonly auth?: SettingsAuthInput | null;
    readonly integrations?: SettingsExternalIntegrationsInput | null;
    readonly recentCommentHistory?: RecentCommentHistoryConfigurationInput | null;
    readonly charCount?: SettingsCharCountInput | null;
    readonly stories?: StoryConfigurationInput | null;
    readonly reaction?: ReactionConfigurationInput | null;
    readonly staff?: StaffConfigurationInput | null;
    readonly accountFeatures?: CommenterAccountFeaturesInput | null;
    readonly slack?: SlackConfigurationInput | null;
    readonly locale?: string | null;
    readonly newCommenters?: NewCommentersConfigurationInput | null;
};
export type LiveConfigurationInput = {
    readonly enabled?: boolean | null;
};
export type SettingsCommunityGuidelinesInput = {
    readonly enabled?: boolean | null;
    readonly content?: string | null;
};
export type SettingsDisableCommentingInput = {
    readonly enabled?: boolean | null;
    readonly message?: string | null;
};
export type SettingsOrganizationInput = {
    readonly name?: string | null;
    readonly contactEmail?: string | null;
    readonly url?: string | null;
};
export type SettingsCloseCommentingInput = {
    readonly auto?: boolean | null;
    readonly timeout?: number | null;
    readonly message?: string | null;
};
export type SettingsWordListInput = {
    readonly banned?: ReadonlyArray<string> | null;
    readonly suspect?: ReadonlyArray<string> | null;
};
export type SettingsEmailConfigurationInput = {
    readonly enabled?: boolean | null;
    readonly smtp?: SettingsSMTPInput | null;
    readonly fromName?: string | null;
    readonly fromEmail?: string | null;
};
export type SettingsSMTPInput = {
    readonly secure?: boolean | null;
    readonly host?: string | null;
    readonly port?: number | null;
    readonly authentication?: boolean | null;
    readonly username?: string | null;
    readonly password?: string | null;
};
export type SettingsAuthInput = {
    readonly integrations?: SettingsAuthIntegrationsInput | null;
    readonly sessionDuration: number;
};
export type SettingsAuthIntegrationsInput = {
    readonly local?: SettingsLocalAuthIntegrationInput | null;
    readonly sso?: SettingsSSOAuthIntegrationInput | null;
    readonly oidc?: SettingsOIDCAuthIntegrationInput | null;
    readonly google?: SettingsGoogleAuthIntegrationInput | null;
    readonly facebook?: SettingsFacebookAuthIntegrationInput | null;
};
export type SettingsLocalAuthIntegrationInput = {
    readonly enabled?: boolean | null;
    readonly allowRegistration?: boolean | null;
    readonly targetFilter?: SettingsAuthenticationTargetFilterInput | null;
};
export type SettingsAuthenticationTargetFilterInput = {
    readonly admin: boolean;
    readonly stream: boolean;
};
export type SettingsSSOAuthIntegrationInput = {
    readonly enabled?: boolean | null;
    readonly allowRegistration?: boolean | null;
    readonly targetFilter?: SettingsAuthenticationTargetFilterInput | null;
};
export type SettingsOIDCAuthIntegrationInput = {
    readonly enabled?: boolean | null;
    readonly allowRegistration?: boolean | null;
    readonly targetFilter?: SettingsAuthenticationTargetFilterInput | null;
    readonly name?: string | null;
    readonly clientID?: string | null;
    readonly clientSecret?: string | null;
    readonly authorizationURL?: string | null;
    readonly tokenURL?: string | null;
    readonly jwksURI?: string | null;
    readonly issuer?: string | null;
};
export type SettingsGoogleAuthIntegrationInput = {
    readonly enabled?: boolean | null;
    readonly allowRegistration?: boolean | null;
    readonly targetFilter?: SettingsAuthenticationTargetFilterInput | null;
    readonly clientID?: string | null;
    readonly clientSecret?: string | null;
};
export type SettingsFacebookAuthIntegrationInput = {
    readonly enabled?: boolean | null;
    readonly allowRegistration?: boolean | null;
    readonly targetFilter?: SettingsAuthenticationTargetFilterInput | null;
    readonly clientID?: string | null;
    readonly clientSecret?: string | null;
};
export type SettingsExternalIntegrationsInput = {
    readonly akismet?: SettingsAkismetExternalIntegrationInput | null;
    readonly perspective?: SettingsPerspectiveExternalIntegrationInput | null;
};
export type SettingsAkismetExternalIntegrationInput = {
    readonly enabled?: boolean | null;
    readonly key?: string | null;
    readonly site?: string | null;
};
export type SettingsPerspectiveExternalIntegrationInput = {
    readonly enabled?: boolean | null;
    readonly endpoint?: string | null;
    readonly key?: string | null;
    readonly model?: string | null;
    readonly threshold?: number | null;
    readonly doNotStore?: boolean | null;
    readonly sendFeedback?: boolean | null;
};
export type RecentCommentHistoryConfigurationInput = {
    readonly enabled?: boolean | null;
    readonly timeFrame?: number | null;
    readonly triggerRejectionRate?: number | null;
};
export type SettingsCharCountInput = {
    readonly enabled?: boolean | null;
    readonly min?: number | null;
    readonly max?: number | null;
};
export type StoryConfigurationInput = {
    readonly scraping?: StoryScrapingConfigurationInput | null;
    readonly disableLazy?: boolean | null;
};
export type StoryScrapingConfigurationInput = {
    readonly enabled?: boolean | null;
    readonly proxyURL?: string | null;
    readonly customUserAgent?: string | null;
};
export type ReactionConfigurationInput = {
    readonly icon?: string | null;
    readonly iconActive?: string | null;
    readonly label?: string | null;
    readonly labelActive?: string | null;
    readonly sortLabel?: string | null;
    readonly color?: string | null;
};
export type StaffConfigurationInput = {
    readonly label?: string | null;
};
export type CommenterAccountFeaturesInput = {
    readonly changeUsername?: boolean | null;
    readonly downloadComments?: boolean | null;
    readonly deleteAccount?: boolean | null;
};
export type SlackConfigurationInput = {
    readonly channels?: ReadonlyArray<SlackChannelConfigurationInput> | null;
};
export type SlackChannelConfigurationInput = {
    readonly enabled?: boolean | null;
    readonly name?: string | null;
    readonly hookURL?: string | null;
    readonly triggers?: SlackTriggersConfigurationInput | null;
};
export type SlackTriggersConfigurationInput = {
    readonly reportedComments?: boolean | null;
    readonly pendingComments?: boolean | null;
    readonly featuredComments?: boolean | null;
};
export type NewCommentersConfigurationInput = {
    readonly premodEnabled?: boolean | null;
    readonly approvedCommentsThreshold?: number | null;
};
export type UpdateSettingsMutationVariables = {
    readonly input: UpdateSettingsInput;
};
export type UpdateSettingsMutationResponse = {
    readonly updateSettings: {
        readonly settings: {
            readonly auth: {
                readonly " $fragmentRefs": AuthConfigContainer_auth$ref;
            };
            readonly email: {
                readonly " $fragmentRefs": EmailConfigContainer_email$ref;
            };
            readonly " $fragmentRefs": ModerationConfigContainer_settings$ref & GeneralConfigContainer_settings$ref & OrganizationConfigContainer_settings$ref & WordListConfigContainer_settings$ref & AdvancedConfigContainer_settings$ref & SlackConfigContainer_settings$ref;
        } | null;
        readonly clientMutationId: string;
    };
};
export type UpdateSettingsMutation = {
    readonly response: UpdateSettingsMutationResponse;
    readonly variables: UpdateSettingsMutationVariables;
};



/*
mutation UpdateSettingsMutation(
  $input: UpdateSettingsInput!
) {
  updateSettings(input: $input) {
    settings {
      auth {
        ...AuthConfigContainer_auth
      }
      email {
        ...EmailConfigContainer_email
      }
      ...ModerationConfigContainer_settings
      ...GeneralConfigContainer_settings
      ...OrganizationConfigContainer_settings
      ...WordListConfigContainer_settings
      ...AdvancedConfigContainer_settings
      ...SlackConfigContainer_settings
      id
    }
    clientMutationId
  }
}

fragment AuthConfigContainer_auth on Auth {
  integrations {
    facebook {
      enabled
      allowRegistration
      targetFilter {
        admin
        stream
      }
      clientID
      clientSecret
    }
    google {
      enabled
      allowRegistration
      targetFilter {
        admin
        stream
      }
      clientID
      clientSecret
    }
    sso {
      enabled
      allowRegistration
      targetFilter {
        admin
        stream
      }
    }
    local {
      enabled
      allowRegistration
      targetFilter {
        admin
        stream
      }
    }
    oidc {
      enabled
      allowRegistration
      targetFilter {
        admin
        stream
      }
      name
      clientID
      clientSecret
      authorizationURL
      tokenURL
      jwksURI
      issuer
    }
  }
  sessionDuration
  ...FacebookConfigContainer_auth
  ...GoogleConfigContainer_auth
  ...SSOConfigContainer_auth
  ...OIDCConfigContainer_auth
}

fragment EmailConfigContainer_email on EmailConfiguration {
  enabled
  fromName
  fromEmail
  smtp {
    host
    port
    secure
    authentication
    username
    password
  }
}

fragment ModerationConfigContainer_settings on Settings {
  integrations {
    akismet {
      enabled
      key
      site
    }
    perspective {
      enabled
      endpoint
      key
      model
      threshold
      doNotStore
      sendFeedback
    }
  }
  moderation
  premodLinksEnable
  recentCommentHistory {
    enabled
    timeFrame
    triggerRejectionRate
  }
  newCommenters {
    premodEnabled
    approvedCommentsThreshold
  }
}

fragment GeneralConfigContainer_settings on Settings {
  ...AnnouncementConfigContainer_settings
  locale
  communityGuidelines {
    enabled
    content
  }
  charCount {
    enabled
    min
    max
  }
  editCommentWindowLength
  closeCommenting {
    message
    auto
    timeout
  }
  disableCommenting {
    enabled
    message
  }
  reaction {
    label
    labelActive
    sortLabel
    icon
    iconActive
  }
  staff {
    label
  }
  ...ReactionConfigContainer_settings
}

fragment OrganizationConfigContainer_settings on Settings {
  organization {
    name
    contactEmail
    url
  }
}

fragment WordListConfigContainer_settings on Settings {
  wordList {
    suspect
    banned
  }
}

fragment AdvancedConfigContainer_settings on Settings {
  customCSSURL
  allowedDomains
  live {
    enabled
  }
  stories {
    scraping {
      enabled
      proxyURL
      customUserAgent
    }
    disableLazy
  }
  ...EmbedCodeContainer_settings
  ...CommentStreamLiveUpdatesContainer_settings
}

fragment SlackConfigContainer_settings on Settings {
  slack {
    channels {
      enabled
      name
      hookURL
      triggers {
        reportedComments
        pendingComments
        featuredComments
      }
    }
  }
}

fragment EmbedCodeContainer_settings on Settings {
  staticURI
}

fragment CommentStreamLiveUpdatesContainer_settings on Settings {
  live {
    configurable
  }
}

fragment AnnouncementConfigContainer_settings on Settings {
  announcement {
    content
    duration
    createdAt
    id
  }
}

fragment ReactionConfigContainer_settings on Settings {
  reaction {
    icon
    iconActive
  }
}

fragment FacebookConfigContainer_auth on Auth {
  integrations {
    facebook {
      callbackURL
    }
  }
}

fragment GoogleConfigContainer_auth on Auth {
  integrations {
    google {
      callbackURL
    }
  }
}

fragment SSOConfigContainer_auth on Auth {
  integrations {
    sso {
      ...SSOKeyFieldContainer_sso
    }
  }
}

fragment OIDCConfigContainer_auth on Auth {
  integrations {
    oidc {
      callbackURL
    }
  }
}

fragment SSOKeyFieldContainer_sso on SSOAuthIntegration {
  key
  keyGeneratedAt
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "UpdateSettingsInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "enabled",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "allowRegistration",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "targetFilter",
        "storageKey": null,
        "args": null,
        "concreteType": "AuthenticationTargetFilter",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "admin",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "stream",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "clientID",
        "args": null,
        "storageKey": null
    } as any), v7 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "clientSecret",
        "args": null,
        "storageKey": null
    } as any), v8 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "callbackURL",
        "args": null,
        "storageKey": null
    } as any), v9 = [
        (v3 /*: any*/),
        (v4 /*: any*/),
        (v5 /*: any*/),
        (v6 /*: any*/),
        (v7 /*: any*/),
        (v8 /*: any*/)
    ], v10 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "key",
        "args": null,
        "storageKey": null
    } as any), v11 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any), v12 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "content",
        "args": null,
        "storageKey": null
    } as any), v13 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v14 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "message",
        "args": null,
        "storageKey": null
    } as any), v15 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "label",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "UpdateSettingsMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "updateSettings",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateSettingsPayload",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "settings",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Settings",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "auth",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Auth",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "FragmentSpread",
                                            "name": "AuthConfigContainer_auth",
                                            "args": null
                                        }
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "email",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "EmailConfiguration",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "FragmentSpread",
                                            "name": "EmailConfigContainer_email",
                                            "args": null
                                        }
                                    ]
                                },
                                {
                                    "kind": "FragmentSpread",
                                    "name": "ModerationConfigContainer_settings",
                                    "args": null
                                },
                                {
                                    "kind": "FragmentSpread",
                                    "name": "GeneralConfigContainer_settings",
                                    "args": null
                                },
                                {
                                    "kind": "FragmentSpread",
                                    "name": "OrganizationConfigContainer_settings",
                                    "args": null
                                },
                                {
                                    "kind": "FragmentSpread",
                                    "name": "WordListConfigContainer_settings",
                                    "args": null
                                },
                                {
                                    "kind": "FragmentSpread",
                                    "name": "AdvancedConfigContainer_settings",
                                    "args": null
                                },
                                {
                                    "kind": "FragmentSpread",
                                    "name": "SlackConfigContainer_settings",
                                    "args": null
                                }
                            ]
                        },
                        (v2 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "UpdateSettingsMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "updateSettings",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateSettingsPayload",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "settings",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Settings",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "auth",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Auth",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "integrations",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "AuthIntegrations",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "facebook",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "FacebookAuthIntegration",
                                                    "plural": false,
                                                    "selections": (v9 /*: any*/)
                                                },
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "google",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "GoogleAuthIntegration",
                                                    "plural": false,
                                                    "selections": (v9 /*: any*/)
                                                },
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "sso",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "SSOAuthIntegration",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        (v4 /*: any*/),
                                                        (v5 /*: any*/),
                                                        (v10 /*: any*/),
                                                        {
                                                            "kind": "ScalarField",
                                                            "alias": null,
                                                            "name": "keyGeneratedAt",
                                                            "args": null,
                                                            "storageKey": null
                                                        }
                                                    ]
                                                },
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "local",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "LocalAuthIntegration",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        (v4 /*: any*/),
                                                        (v5 /*: any*/)
                                                    ]
                                                },
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "oidc",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "OIDCAuthIntegration",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        (v4 /*: any*/),
                                                        (v5 /*: any*/),
                                                        (v11 /*: any*/),
                                                        (v6 /*: any*/),
                                                        (v7 /*: any*/),
                                                        {
                                                            "kind": "ScalarField",
                                                            "alias": null,
                                                            "name": "authorizationURL",
                                                            "args": null,
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "kind": "ScalarField",
                                                            "alias": null,
                                                            "name": "tokenURL",
                                                            "args": null,
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "kind": "ScalarField",
                                                            "alias": null,
                                                            "name": "jwksURI",
                                                            "args": null,
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "kind": "ScalarField",
                                                            "alias": null,
                                                            "name": "issuer",
                                                            "args": null,
                                                            "storageKey": null
                                                        },
                                                        (v8 /*: any*/)
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "sessionDuration",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "email",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "EmailConfiguration",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "fromName",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "fromEmail",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "smtp",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "SMTP",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "host",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "port",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "secure",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "authentication",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "username",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "password",
                                                    "args": null,
                                                    "storageKey": null
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "integrations",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "ExternalIntegrations",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "akismet",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "AkismetExternalIntegration",
                                            "plural": false,
                                            "selections": [
                                                (v3 /*: any*/),
                                                (v10 /*: any*/),
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "site",
                                                    "args": null,
                                                    "storageKey": null
                                                }
                                            ]
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "perspective",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "PerspectiveExternalIntegration",
                                            "plural": false,
                                            "selections": [
                                                (v3 /*: any*/),
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "endpoint",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                (v10 /*: any*/),
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "model",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "threshold",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "doNotStore",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "sendFeedback",
                                                    "args": null,
                                                    "storageKey": null
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "moderation",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "premodLinksEnable",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "recentCommentHistory",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "RecentCommentHistoryConfiguration",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "timeFrame",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "triggerRejectionRate",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "newCommenters",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "NewCommentersConfiguration",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "premodEnabled",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "approvedCommentsThreshold",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "announcement",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Announcement",
                                    "plural": false,
                                    "selections": [
                                        (v12 /*: any*/),
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "duration",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "createdAt",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        (v13 /*: any*/)
                                    ]
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "locale",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "communityGuidelines",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "CommunityGuidelines",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v12 /*: any*/)
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "charCount",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "CharCount",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "min",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "max",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "editCommentWindowLength",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "closeCommenting",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "CloseCommenting",
                                    "plural": false,
                                    "selections": [
                                        (v14 /*: any*/),
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "auto",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "timeout",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "disableCommenting",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "DisableCommenting",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v14 /*: any*/)
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "reaction",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "ReactionConfiguration",
                                    "plural": false,
                                    "selections": [
                                        (v15 /*: any*/),
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "labelActive",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "sortLabel",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "icon",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "iconActive",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "staff",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "StaffConfiguration",
                                    "plural": false,
                                    "selections": [
                                        (v15 /*: any*/)
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "organization",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Organization",
                                    "plural": false,
                                    "selections": [
                                        (v11 /*: any*/),
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "contactEmail",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "url",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "wordList",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "WordList",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "suspect",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "banned",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "customCSSURL",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "allowedDomains",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "live",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "LiveConfiguration",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "configurable",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "stories",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "StoryConfiguration",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "scraping",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "StoryScrapingConfiguration",
                                            "plural": false,
                                            "selections": [
                                                (v3 /*: any*/),
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "proxyURL",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "customUserAgent",
                                                    "args": null,
                                                    "storageKey": null
                                                }
                                            ]
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "disableLazy",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "staticURI",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "slack",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "SlackConfiguration",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "channels",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "SlackChannel",
                                            "plural": true,
                                            "selections": [
                                                (v3 /*: any*/),
                                                (v11 /*: any*/),
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "hookURL",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "triggers",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "SlackChannelTriggers",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "kind": "ScalarField",
                                                            "alias": null,
                                                            "name": "reportedComments",
                                                            "args": null,
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "kind": "ScalarField",
                                                            "alias": null,
                                                            "name": "pendingComments",
                                                            "args": null,
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "kind": "ScalarField",
                                                            "alias": null,
                                                            "name": "featuredComments",
                                                            "args": null,
                                                            "storageKey": null
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                (v13 /*: any*/)
                            ]
                        },
                        (v2 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "mutation",
            "name": "UpdateSettingsMutation",
            "id": "d453932245e656ca9c89380878f633b1",
            "text": null,
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'd60b8055f71e8034cad1a79eb52a6223';
export default node;
