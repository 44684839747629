/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { ModerateCardDetailsContainer_settings$ref } from "./ModerateCardDetailsContainer_settings.graphql";
declare const _MarkersContainer_settings$ref: unique symbol;
export type MarkersContainer_settings$ref = typeof _MarkersContainer_settings$ref;
export type MarkersContainer_settings = {
    readonly " $fragmentRefs": ModerateCardDetailsContainer_settings$ref;
    readonly " $refType": MarkersContainer_settings$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "MarkersContainer_settings",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "FragmentSpread",
            "name": "ModerateCardDetailsContainer_settings",
            "args": null
        }
    ]
} as any);
(node as any).hash = '23385fa53e1d04cdfcca7a7c4333a930';
export default node;
