/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _AkismetConfig_formValues$ref: unique symbol;
export type AkismetConfig_formValues$ref = typeof _AkismetConfig_formValues$ref;
export type AkismetConfig_formValues = {
    readonly integrations: {
        readonly akismet: {
            readonly enabled: boolean;
            readonly key: string | null;
            readonly site: string | null;
        };
    };
    readonly " $refType": AkismetConfig_formValues$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "AkismetConfig_formValues",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "integrations",
            "storageKey": null,
            "args": null,
            "concreteType": "ExternalIntegrations",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "akismet",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AkismetExternalIntegration",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "enabled",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "key",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "site",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        }
    ]
} as any);
(node as any).hash = 'b46c182c99db20ee4004ed28e2f5b465';
export default node;
