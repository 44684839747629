/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { ModerateCardContainer_viewer$ref } from "./ModerateCardContainer_viewer.graphql";
declare const _UserHistoryDrawerRejectedComments_viewer$ref: unique symbol;
export type UserHistoryDrawerRejectedComments_viewer$ref = typeof _UserHistoryDrawerRejectedComments_viewer$ref;
export type UserHistoryDrawerRejectedComments_viewer = {
    readonly " $fragmentRefs": ModerateCardContainer_viewer$ref;
    readonly " $refType": UserHistoryDrawerRejectedComments_viewer$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "UserHistoryDrawerRejectedComments_viewer",
    "type": "User",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "FragmentSpread",
            "name": "ModerateCardContainer_viewer",
            "args": null
        }
    ]
} as any);
(node as any).hash = '36bb118b317a0785c1061fe06a760e0f';
export default node;
