/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _LocaleConfig_formValues$ref: unique symbol;
export type LocaleConfig_formValues$ref = typeof _LocaleConfig_formValues$ref;
export type LocaleConfig_formValues = {
    readonly locale: string;
    readonly " $refType": LocaleConfig_formValues$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "LocaleConfig_formValues",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "locale",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = '7063b6816d596111858f3d1d69645050';
export default node;
