/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _OrganizationConfigContainer_settings$ref: unique symbol;
export type OrganizationConfigContainer_settings$ref = typeof _OrganizationConfigContainer_settings$ref;
export type OrganizationConfigContainer_settings = {
    readonly organization: {
        readonly name: string;
        readonly contactEmail: string;
        readonly url: string;
    };
    readonly " $refType": OrganizationConfigContainer_settings$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "OrganizationConfigContainer_settings",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "organization",
            "storageKey": null,
            "args": null,
            "concreteType": "Organization",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "contactEmail",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "url",
                    "args": null,
                    "storageKey": null
                }
            ]
        }
    ]
} as any);
(node as any).hash = 'caa0ee142b8273501eb5c4fd331d68cb';
export default node;
