/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
export type USER_STATUS = "ACTIVE" | "BANNED" | "PREMOD" | "SUSPENDED" | "%future added value";
declare const _UserStatusContainer_user$ref: unique symbol;
export type UserStatusContainer_user$ref = typeof _UserStatusContainer_user$ref;
export type UserStatusContainer_user = {
    readonly status: {
        readonly current: ReadonlyArray<USER_STATUS>;
    };
    readonly " $refType": UserStatusContainer_user$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "UserStatusContainer_user",
    "type": "User",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "status",
            "storageKey": null,
            "args": null,
            "concreteType": "UserStatus",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "current",
                    "args": null,
                    "storageKey": null
                }
            ]
        }
    ]
} as any);
(node as any).hash = '5aeff630f1ea53ffc7412032e11c7f90';
export default node;
