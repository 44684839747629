/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _SuccessContainer_settings$ref: unique symbol;
export type SuccessContainer_settings$ref = typeof _SuccessContainer_settings$ref;
export type SuccessContainer_settings = {
    readonly organization: {
        readonly name: string;
        readonly url: string;
    };
    readonly " $refType": SuccessContainer_settings$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "SuccessContainer_settings",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "organization",
            "storageKey": null,
            "args": null,
            "concreteType": "Organization",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "url",
                    "args": null,
                    "storageKey": null
                }
            ]
        }
    ]
} as any);
(node as any).hash = '86425ee1931de62d94e8d03dbc09b3b6';
export default node;
