/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { AnnouncementConfigContainer_settings$ref } from "./AnnouncementConfigContainer_settings.graphql";
import { ReactionConfigContainer_settings$ref } from "./ReactionConfigContainer_settings.graphql";
declare const _GeneralConfigContainer_settings$ref: unique symbol;
export type GeneralConfigContainer_settings$ref = typeof _GeneralConfigContainer_settings$ref;
export type GeneralConfigContainer_settings = {
    readonly locale: string;
    readonly communityGuidelines: {
        readonly enabled: boolean;
        readonly content: string | null;
    };
    readonly charCount: {
        readonly enabled: boolean;
        readonly min: number | null;
        readonly max: number | null;
    };
    readonly editCommentWindowLength: number;
    readonly closeCommenting: {
        readonly message: string;
        readonly auto: boolean;
        readonly timeout: number;
    };
    readonly disableCommenting: {
        readonly enabled: boolean;
        readonly message: string;
    };
    readonly reaction: {
        readonly label: string;
        readonly labelActive: string;
        readonly sortLabel: string;
        readonly icon: string;
        readonly iconActive: string | null;
    };
    readonly staff: {
        readonly label: string;
    };
    readonly " $fragmentRefs": AnnouncementConfigContainer_settings$ref & ReactionConfigContainer_settings$ref;
    readonly " $refType": GeneralConfigContainer_settings$ref;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "enabled",
        "args": null,
        "storageKey": null
    } as any), v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "message",
        "args": null,
        "storageKey": null
    } as any), v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "label",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Fragment",
        "name": "GeneralConfigContainer_settings",
        "type": "Settings",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "locale",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "communityGuidelines",
                "storageKey": null,
                "args": null,
                "concreteType": "CommunityGuidelines",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "content",
                        "args": null,
                        "storageKey": null
                    }
                ]
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "charCount",
                "storageKey": null,
                "args": null,
                "concreteType": "CharCount",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "min",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "max",
                        "args": null,
                        "storageKey": null
                    }
                ]
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "editCommentWindowLength",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "closeCommenting",
                "storageKey": null,
                "args": null,
                "concreteType": "CloseCommenting",
                "plural": false,
                "selections": [
                    (v1 /*: any*/),
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "auto",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "timeout",
                        "args": null,
                        "storageKey": null
                    }
                ]
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "disableCommenting",
                "storageKey": null,
                "args": null,
                "concreteType": "DisableCommenting",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    (v1 /*: any*/)
                ]
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "reaction",
                "storageKey": null,
                "args": null,
                "concreteType": "ReactionConfiguration",
                "plural": false,
                "selections": [
                    (v2 /*: any*/),
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "labelActive",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "sortLabel",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "icon",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "iconActive",
                        "args": null,
                        "storageKey": null
                    }
                ]
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "staff",
                "storageKey": null,
                "args": null,
                "concreteType": "StaffConfiguration",
                "plural": false,
                "selections": [
                    (v2 /*: any*/)
                ]
            },
            {
                "kind": "FragmentSpread",
                "name": "AnnouncementConfigContainer_settings",
                "args": null
            },
            {
                "kind": "FragmentSpread",
                "name": "ReactionConfigContainer_settings",
                "args": null
            }
        ]
    } as any;
})();
(node as any).hash = '2d2e22f2167507e8a98ea4333fbe7d1c';
export default node;
