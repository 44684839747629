/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { EmailConfigContainer_email$ref } from "./EmailConfigContainer_email.graphql";
export type EmailConfigRouteQueryVariables = {};
export type EmailConfigRouteQueryResponse = {
    readonly settings: {
        readonly email: {
            readonly " $fragmentRefs": EmailConfigContainer_email$ref;
        };
    };
};
export type EmailConfigRouteQuery = {
    readonly response: EmailConfigRouteQueryResponse;
    readonly variables: EmailConfigRouteQueryVariables;
};



/*
query EmailConfigRouteQuery {
  settings {
    email {
      ...EmailConfigContainer_email
    }
    id
  }
}

fragment EmailConfigContainer_email on EmailConfiguration {
  enabled
  fromName
  fromEmail
  smtp {
    host
    port
    secure
    authentication
    username
    password
  }
}
*/

const node: ConcreteRequest = ({
    "kind": "Request",
    "fragment": {
        "kind": "Fragment",
        "name": "EmailConfigRouteQuery",
        "type": "Query",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "settings",
                "storageKey": null,
                "args": null,
                "concreteType": "Settings",
                "plural": false,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "email",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "EmailConfiguration",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "FragmentSpread",
                                "name": "EmailConfigContainer_email",
                                "args": null
                            }
                        ]
                    }
                ]
            }
        ]
    },
    "operation": {
        "kind": "Operation",
        "name": "EmailConfigRouteQuery",
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "settings",
                "storageKey": null,
                "args": null,
                "concreteType": "Settings",
                "plural": false,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "email",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "EmailConfiguration",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "enabled",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "fromName",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "fromEmail",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "smtp",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "SMTP",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "host",
                                        "args": null,
                                        "storageKey": null
                                    },
                                    {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "port",
                                        "args": null,
                                        "storageKey": null
                                    },
                                    {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "secure",
                                        "args": null,
                                        "storageKey": null
                                    },
                                    {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "authentication",
                                        "args": null,
                                        "storageKey": null
                                    },
                                    {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "username",
                                        "args": null,
                                        "storageKey": null
                                    },
                                    {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "password",
                                        "args": null,
                                        "storageKey": null
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "id",
                        "args": null,
                        "storageKey": null
                    }
                ]
            }
        ]
    },
    "params": {
        "operationKind": "query",
        "name": "EmailConfigRouteQuery",
        "id": "dc6bc4441ae80f452fd29730a1f50a34",
        "text": null,
        "metadata": {}
    }
} as any);
(node as any).hash = '763feade789971a9857e0681f1489e8c';
export default node;
