/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _OIDCConfig_formValues$ref: unique symbol;
export type OIDCConfig_formValues$ref = typeof _OIDCConfig_formValues$ref;
export type OIDCConfig_formValues = {
    readonly integrations: {
        readonly oidc: {
            readonly enabled: boolean;
            readonly allowRegistration: boolean;
            readonly targetFilter: {
                readonly admin: boolean;
                readonly stream: boolean;
            };
            readonly name: string | null;
            readonly clientID: string | null;
            readonly clientSecret: string | null;
            readonly authorizationURL: string | null;
            readonly tokenURL: string | null;
            readonly jwksURI: string | null;
            readonly issuer: string | null;
        };
    };
    readonly " $refType": OIDCConfig_formValues$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "OIDCConfig_formValues",
    "type": "Auth",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "integrations",
            "storageKey": null,
            "args": null,
            "concreteType": "AuthIntegrations",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "oidc",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "OIDCAuthIntegration",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "enabled",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "allowRegistration",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "targetFilter",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "AuthenticationTargetFilter",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "admin",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "stream",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "name",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "clientID",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "clientSecret",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "authorizationURL",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "tokenURL",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "jwksURI",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "issuer",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        }
    ]
} as any);
(node as any).hash = 'ceae0b79bf65f04ce02c8c1aa63b235b';
export default node;
