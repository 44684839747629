/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _CommentEditingConfig_formValues$ref: unique symbol;
export type CommentEditingConfig_formValues$ref = typeof _CommentEditingConfig_formValues$ref;
export type CommentEditingConfig_formValues = {
    readonly editCommentWindowLength: number;
    readonly " $refType": CommentEditingConfig_formValues$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "CommentEditingConfig_formValues",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "editCommentWindowLength",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = '398f915791fb7825278edba34c713c10';
export default node;
