/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type CreateModeratorNoteInput = {
    readonly clientMutationId: string;
    readonly body: string;
    readonly userID: string;
};
export type CreateModeratorNoteMutationVariables = {
    readonly input: CreateModeratorNoteInput;
};
export type CreateModeratorNoteMutationResponse = {
    readonly createModeratorNote: {
        readonly user: {
            readonly moderatorNotes: ReadonlyArray<{
                readonly id: string;
                readonly body: string;
                readonly createdBy: {
                    readonly username: string | null;
                    readonly id: string;
                };
                readonly createdAt: string;
            }>;
        };
        readonly clientMutationId: string;
    };
};
export type CreateModeratorNoteMutation = {
    readonly response: CreateModeratorNoteMutationResponse;
    readonly variables: CreateModeratorNoteMutationVariables;
};



/*
mutation CreateModeratorNoteMutation(
  $input: CreateModeratorNoteInput!
) {
  createModeratorNote(input: $input) {
    user {
      moderatorNotes {
        id
        body
        createdBy {
          username
          id
        }
        createdAt
      }
      id
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "CreateModeratorNoteInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "moderatorNotes",
        "storageKey": null,
        "args": null,
        "concreteType": "ModeratorNote",
        "plural": true,
        "selections": [
            (v2 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "body",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "createdBy",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "username",
                        "args": null,
                        "storageKey": null
                    },
                    (v2 /*: any*/)
                ]
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "createdAt",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "CreateModeratorNoteMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "createModeratorNote",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateModeratorNotePayload",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "User",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/)
                            ]
                        },
                        (v4 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "CreateModeratorNoteMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "createModeratorNote",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateModeratorNotePayload",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "User",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                (v2 /*: any*/)
                            ]
                        },
                        (v4 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "mutation",
            "name": "CreateModeratorNoteMutation",
            "id": "8df0e17e7cd08fd57a54f1b0314f1fee",
            "text": null,
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'a1b01a1fd061654b7a20941e83fbd6df';
export default node;
