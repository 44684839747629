/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { AccountCompletionContainer_auth$ref } from "./AccountCompletionContainer_auth.graphql";
import { AccountCompletionContainer_viewer$ref } from "./AccountCompletionContainer_viewer.graphql";
import { SignInContainer_auth$ref } from "./SignInContainer_auth.graphql";
export type LoginRouteQueryVariables = {};
export type LoginRouteQueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": AccountCompletionContainer_viewer$ref;
    } | null;
    readonly settings: {
        readonly auth: {
            readonly " $fragmentRefs": AccountCompletionContainer_auth$ref & SignInContainer_auth$ref;
        };
    };
};
export type LoginRouteQuery = {
    readonly response: LoginRouteQueryResponse;
    readonly variables: LoginRouteQueryVariables;
};



/*
query LoginRouteQuery {
  viewer {
    ...AccountCompletionContainer_viewer
    id
  }
  settings {
    auth {
      ...AccountCompletionContainer_auth
      ...SignInContainer_auth
    }
    id
  }
}

fragment AccountCompletionContainer_viewer on User {
  username
  email
  profiles {
    __typename
  }
}

fragment AccountCompletionContainer_auth on Auth {
  integrations {
    local {
      enabled
      targetFilter {
        admin
        stream
      }
    }
  }
}

fragment SignInContainer_auth on Auth {
  ...SignInWithOIDCContainer_auth
  ...SignInWithGoogleContainer_auth
  ...SignInWithFacebookContainer_auth
  integrations {
    local {
      enabled
      targetFilter {
        admin
      }
    }
    facebook {
      enabled
      targetFilter {
        admin
      }
    }
    google {
      enabled
      targetFilter {
        admin
      }
    }
    oidc {
      enabled
      targetFilter {
        admin
      }
    }
  }
}

fragment SignInWithOIDCContainer_auth on Auth {
  integrations {
    oidc {
      name
      redirectURL
    }
  }
}

fragment SignInWithGoogleContainer_auth on Auth {
  integrations {
    google {
      redirectURL
    }
  }
}

fragment SignInWithFacebookContainer_auth on Auth {
  integrations {
    facebook {
      redirectURL
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "enabled",
        "args": null,
        "storageKey": null
    } as any), v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "admin",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "redirectURL",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "targetFilter",
        "storageKey": null,
        "args": null,
        "concreteType": "AuthenticationTargetFilter",
        "plural": false,
        "selections": [
            (v2 /*: any*/)
        ]
    } as any), v5 = [
        (v3 /*: any*/),
        (v1 /*: any*/),
        (v4 /*: any*/)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "LoginRouteQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "viewer",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "AccountCompletionContainer_viewer",
                            "args": null
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "settings",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Settings",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "auth",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Auth",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "FragmentSpread",
                                    "name": "AccountCompletionContainer_auth",
                                    "args": null
                                },
                                {
                                    "kind": "FragmentSpread",
                                    "name": "SignInContainer_auth",
                                    "args": null
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "LoginRouteQuery",
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "viewer",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "username",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "email",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "profiles",
                            "storageKey": null,
                            "args": null,
                            "concreteType": null,
                            "plural": true,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "__typename",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        (v0 /*: any*/)
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "settings",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Settings",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "auth",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Auth",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "integrations",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "AuthIntegrations",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "local",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "LocalAuthIntegration",
                                            "plural": false,
                                            "selections": [
                                                (v1 /*: any*/),
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "targetFilter",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "AuthenticationTargetFilter",
                                                    "plural": false,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        {
                                                            "kind": "ScalarField",
                                                            "alias": null,
                                                            "name": "stream",
                                                            "args": null,
                                                            "storageKey": null
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "oidc",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "OIDCAuthIntegration",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "name",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                (v3 /*: any*/),
                                                (v1 /*: any*/),
                                                (v4 /*: any*/)
                                            ]
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "google",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "GoogleAuthIntegration",
                                            "plural": false,
                                            "selections": (v5 /*: any*/)
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "facebook",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "FacebookAuthIntegration",
                                            "plural": false,
                                            "selections": (v5 /*: any*/)
                                        }
                                    ]
                                }
                            ]
                        },
                        (v0 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "LoginRouteQuery",
            "id": "19b7f97cd6f09bd6909bcbe014a286ed",
            "text": null,
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'dd4a1dc4d408986aece3facb13bab79d';
export default node;
