/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { ModerateCardContainer_settings$ref } from "./ModerateCardContainer_settings.graphql";
declare const _UserHistoryDrawerAllComments_settings$ref: unique symbol;
export type UserHistoryDrawerAllComments_settings$ref = typeof _UserHistoryDrawerAllComments_settings$ref;
export type UserHistoryDrawerAllComments_settings = {
    readonly " $fragmentRefs": ModerateCardContainer_settings$ref;
    readonly " $refType": UserHistoryDrawerAllComments_settings$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "UserHistoryDrawerAllComments_settings",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "FragmentSpread",
            "name": "ModerateCardContainer_settings",
            "args": null
        }
    ]
} as any);
(node as any).hash = 'f3976ebccc7ad7e2b4c9e186f58513d5';
export default node;
