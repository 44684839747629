/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _RecentHistoryContainer_user$ref: unique symbol;
export type RecentHistoryContainer_user$ref = typeof _RecentHistoryContainer_user$ref;
export type RecentHistoryContainer_user = {
    readonly recentCommentHistory: {
        readonly statuses: {
            readonly NONE: number;
            readonly APPROVED: number;
            readonly REJECTED: number;
            readonly PREMOD: number;
            readonly SYSTEM_WITHHELD: number;
        };
    };
    readonly " $refType": RecentHistoryContainer_user$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "RecentHistoryContainer_user",
    "type": "User",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "recentCommentHistory",
            "storageKey": null,
            "args": null,
            "concreteType": "RecentCommentHistory",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "statuses",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CommentStatusCounts",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "NONE",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "APPROVED",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "REJECTED",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "PREMOD",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "SYSTEM_WITHHELD",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        }
    ]
} as any);
(node as any).hash = '23d775a09256b822aea9591cd335cc85';
export default node;
