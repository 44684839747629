/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { UserHistoryDrawerContainer_settings$ref } from "./UserHistoryDrawerContainer_settings.graphql";
import { UserHistoryDrawerContainer_user$ref } from "./UserHistoryDrawerContainer_user.graphql";
export type UserHistoryDrawerQueryVariables = {
    readonly userID: string;
};
export type UserHistoryDrawerQueryResponse = {
    readonly user: {
        readonly " $fragmentRefs": UserHistoryDrawerContainer_user$ref;
    } | null;
    readonly settings: {
        readonly " $fragmentRefs": UserHistoryDrawerContainer_settings$ref;
    };
};
export type UserHistoryDrawerQuery = {
    readonly response: UserHistoryDrawerQueryResponse;
    readonly variables: UserHistoryDrawerQueryVariables;
};



/*
query UserHistoryDrawerQuery(
  $userID: ID!
) {
  user(id: $userID) {
    ...UserHistoryDrawerContainer_user
    id
  }
  settings {
    ...UserHistoryDrawerContainer_settings
    id
  }
}

fragment UserHistoryDrawerContainer_user on User {
  ...UserBadgesContainer_user
  ...UserStatusChangeContainer_user
  ...UserStatusDetailsContainer_user
  ...RecentHistoryContainer_user
  moderatorNotes {
    id
  }
  id
  username
  email
  createdAt
}

fragment UserHistoryDrawerContainer_settings on Settings {
  ...RecentHistoryContainer_settings
  ...UserStatusChangeContainer_settings
  organization {
    name
  }
}

fragment RecentHistoryContainer_settings on Settings {
  recentCommentHistory {
    enabled
    timeFrame
    triggerRejectionRate
  }
}

fragment UserStatusChangeContainer_settings on Settings {
  organization {
    name
  }
}

fragment UserBadgesContainer_user on User {
  badges
}

fragment UserStatusChangeContainer_user on User {
  id
  role
  username
  status {
    ban {
      active
    }
    suspension {
      active
    }
    premod {
      active
    }
  }
  ...UserStatusContainer_user
}

fragment UserStatusDetailsContainer_user on User {
  status {
    ban {
      active
      history {
        active
        createdAt
        createdBy {
          username
          id
        }
      }
    }
    suspension {
      until
      active
      history {
        active
        from {
          start
          finish
        }
        createdBy {
          username
          id
        }
      }
    }
  }
}

fragment RecentHistoryContainer_user on User {
  recentCommentHistory {
    statuses {
      NONE
      APPROVED
      REJECTED
      PREMOD
      SYSTEM_WITHHELD
    }
  }
}

fragment UserStatusContainer_user on User {
  status {
    current
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "userID",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "userID"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "username",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "active",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "createdAt",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "createdBy",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
            (v3 /*: any*/),
            (v2 /*: any*/)
        ]
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "UserHistoryDrawerQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "UserHistoryDrawerContainer_user",
                            "args": null
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "settings",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Settings",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "UserHistoryDrawerContainer_settings",
                            "args": null
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "UserHistoryDrawerQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "badges",
                            "args": null,
                            "storageKey": null
                        },
                        (v2 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "role",
                            "args": null,
                            "storageKey": null
                        },
                        (v3 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "status",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "UserStatus",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "ban",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "BanStatus",
                                    "plural": false,
                                    "selections": [
                                        (v4 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "history",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "BanStatusHistory",
                                            "plural": true,
                                            "selections": [
                                                (v4 /*: any*/),
                                                (v5 /*: any*/),
                                                (v6 /*: any*/)
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "suspension",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "SuspensionStatus",
                                    "plural": false,
                                    "selections": [
                                        (v4 /*: any*/),
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "until",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "history",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "SuspensionStatusHistory",
                                            "plural": true,
                                            "selections": [
                                                (v4 /*: any*/),
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "from",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "TimeRange",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "kind": "ScalarField",
                                                            "alias": null,
                                                            "name": "start",
                                                            "args": null,
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "kind": "ScalarField",
                                                            "alias": null,
                                                            "name": "finish",
                                                            "args": null,
                                                            "storageKey": null
                                                        }
                                                    ]
                                                },
                                                (v6 /*: any*/)
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "premod",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "PremodStatus",
                                    "plural": false,
                                    "selections": [
                                        (v4 /*: any*/)
                                    ]
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "current",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "recentCommentHistory",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "RecentCommentHistory",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "statuses",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "CommentStatusCounts",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "NONE",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "APPROVED",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "REJECTED",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "PREMOD",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "SYSTEM_WITHHELD",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "moderatorNotes",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ModeratorNote",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/)
                            ]
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "email",
                            "args": null,
                            "storageKey": null
                        },
                        (v5 /*: any*/)
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "settings",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Settings",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "recentCommentHistory",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "RecentCommentHistoryConfiguration",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "enabled",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "timeFrame",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "triggerRejectionRate",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "organization",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Organization",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "name",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        (v2 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "UserHistoryDrawerQuery",
            "id": "4153035be8ada095094bb7eac0898ab0",
            "text": null,
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'cdef4b2be8adf3d7ee36137a1a9510c8';
export default node;
