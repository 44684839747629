/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MODERATOR" | "STAFF" | "%future added value";
declare const _UserRoleChangeContainer_viewer$ref: unique symbol;
export type UserRoleChangeContainer_viewer$ref = typeof _UserRoleChangeContainer_viewer$ref;
export type UserRoleChangeContainer_viewer = {
    readonly id: string;
    readonly role: USER_ROLE;
    readonly " $refType": UserRoleChangeContainer_viewer$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "UserRoleChangeContainer_viewer",
    "type": "User",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "role",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = '3856a31926eb0c243a29f1df50e0c5c9';
export default node;
