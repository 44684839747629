/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _ClosedStreamMessageConfig_formValues$ref: unique symbol;
export type ClosedStreamMessageConfig_formValues$ref = typeof _ClosedStreamMessageConfig_formValues$ref;
export type ClosedStreamMessageConfig_formValues = {
    readonly closeCommenting: {
        readonly message: string;
    };
    readonly " $refType": ClosedStreamMessageConfig_formValues$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "ClosedStreamMessageConfig_formValues",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "closeCommenting",
            "storageKey": null,
            "args": null,
            "concreteType": "CloseCommenting",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "message",
                    "args": null,
                    "storageKey": null
                }
            ]
        }
    ]
} as any);
(node as any).hash = '7ea81234f7b89eda94b8b21b649e0b8d';
export default node;
