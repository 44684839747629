/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _ModeratedByContainer_comment$ref: unique symbol;
export type ModeratedByContainer_comment$ref = typeof _ModeratedByContainer_comment$ref;
export type ModeratedByContainer_comment = {
    readonly id: string;
    readonly statusLiveUpdated: boolean | null;
    readonly statusHistory: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly moderator: {
                    readonly id: string;
                    readonly username: string | null;
                } | null;
            };
        }>;
    };
    readonly " $refType": ModeratedByContainer_comment$ref;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Fragment",
        "name": "ModeratedByContainer_comment",
        "type": "Comment",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            (v0 /*: any*/),
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "statusHistory",
                "storageKey": "statusHistory(first:1)",
                "args": [
                    {
                        "kind": "Literal",
                        "name": "first",
                        "value": 1
                    }
                ],
                "concreteType": "CommentModerationActionConnection",
                "plural": false,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CommentModerationActionEdge",
                        "plural": true,
                        "selections": [
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "CommentModerationAction",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "moderator",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "User",
                                        "plural": false,
                                        "selections": [
                                            (v0 /*: any*/),
                                            {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "username",
                                                "args": null,
                                                "storageKey": null
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "kind": "ClientExtension",
                "selections": [
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "statusLiveUpdated",
                        "args": null,
                        "storageKey": null
                    }
                ]
            }
        ]
    } as any;
})();
(node as any).hash = '78bcc52dda1fceb4128f484835e268b6';
export default node;
