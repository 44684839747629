/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MODERATOR" | "STAFF" | "%future added value";
declare const _UserRoleChangeContainer_user$ref: unique symbol;
export type UserRoleChangeContainer_user$ref = typeof _UserRoleChangeContainer_user$ref;
export type UserRoleChangeContainer_user = {
    readonly id: string;
    readonly role: USER_ROLE;
    readonly " $refType": UserRoleChangeContainer_user$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "UserRoleChangeContainer_user",
    "type": "User",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "role",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = '0f31bddb289d80087bc981edd5452d0f';
export default node;
