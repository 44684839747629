/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { CommentRevisionContainer_comment$ref } from "./CommentRevisionContainer_comment.graphql";
import { FlagDetailsContainer_comment$ref } from "./FlagDetailsContainer_comment.graphql";
import { LinkDetailsContainer_comment$ref } from "./LinkDetailsContainer_comment.graphql";
declare const _ModerateCardDetailsContainer_comment$ref: unique symbol;
export type ModerateCardDetailsContainer_comment$ref = typeof _ModerateCardDetailsContainer_comment$ref;
export type ModerateCardDetailsContainer_comment = {
    readonly " $fragmentRefs": FlagDetailsContainer_comment$ref & CommentRevisionContainer_comment$ref & LinkDetailsContainer_comment$ref;
    readonly " $refType": ModerateCardDetailsContainer_comment$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "ModerateCardDetailsContainer_comment",
    "type": "Comment",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "FragmentSpread",
            "name": "FlagDetailsContainer_comment",
            "args": null
        },
        {
            "kind": "FragmentSpread",
            "name": "CommentRevisionContainer_comment",
            "args": null
        },
        {
            "kind": "FragmentSpread",
            "name": "LinkDetailsContainer_comment",
            "args": null
        }
    ]
} as any);
(node as any).hash = '13226f3eb96db6ba2cfab9b9425d3b56';
export default node;
