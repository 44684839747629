/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { UserHistoryDrawerRejectedComments_user$ref } from "./UserHistoryDrawerRejectedComments_user.graphql";
export type UserHistoryDrawerRejectedCommentsPaginationQueryVariables = {
    readonly userID: string;
    readonly count: number;
    readonly cursor?: unknown | null;
};
export type UserHistoryDrawerRejectedCommentsPaginationQueryResponse = {
    readonly user: {
        readonly " $fragmentRefs": UserHistoryDrawerRejectedComments_user$ref;
    } | null;
};
export type UserHistoryDrawerRejectedCommentsPaginationQuery = {
    readonly response: UserHistoryDrawerRejectedCommentsPaginationQueryResponse;
    readonly variables: UserHistoryDrawerRejectedCommentsPaginationQueryVariables;
};



/*
query UserHistoryDrawerRejectedCommentsPaginationQuery(
  $userID: ID!
  $count: Int!
  $cursor: Cursor
) {
  user(id: $userID) {
    ...UserHistoryDrawerRejectedComments_user_1G22uz
    id
  }
}

fragment UserHistoryDrawerRejectedComments_user_1G22uz on User {
  username
  rejectedComments(first: $count, after: $cursor) {
    edges {
      node {
        id
        ...ModerateCardContainer_comment
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment ModerateCardContainer_comment on Comment {
  id
  author {
    id
    username
    status {
      current
    }
  }
  createdAt
  body
  tags {
    code
  }
  status
  revision {
    id
  }
  editing {
    edited
  }
  parent {
    author {
      id
      username
    }
    id
  }
  story {
    id
    metadata {
      title
    }
  }
  permalink
  deleted
  ...MarkersContainer_comment
  ...ModeratedByContainer_comment
  ...CommentAuthorContainer_comment
}

fragment MarkersContainer_comment on Comment {
  ...ModerateCardDetailsContainer_comment
  status
  editing {
    edited
  }
  revision {
    actionCounts {
      flag {
        reasons {
          COMMENT_DETECTED_TOXIC
          COMMENT_DETECTED_SPAM
          COMMENT_DETECTED_RECENT_HISTORY
          COMMENT_DETECTED_LINKS
          COMMENT_DETECTED_BANNED_WORD
          COMMENT_DETECTED_SUSPECT_WORD
          COMMENT_REPORTED_OFFENSIVE
          COMMENT_REPORTED_SPAM
          COMMENT_DETECTED_NEW_COMMENTER
          COMMENT_DETECTED_REPEAT_POST
        }
      }
    }
    metadata {
      perspective {
        score
      }
    }
    id
  }
}

fragment ModeratedByContainer_comment on Comment {
  id
  statusHistory(first: 1) {
    edges {
      node {
        moderator {
          id
          username
        }
        id
      }
    }
  }
}

fragment CommentAuthorContainer_comment on Comment {
  author {
    id
    username
    status {
      ban {
        active
      }
    }
  }
}

fragment ModerateCardDetailsContainer_comment on Comment {
  ...FlagDetailsContainer_comment
  ...CommentRevisionContainer_comment
  ...LinkDetailsContainer_comment
}

fragment FlagDetailsContainer_comment on Comment {
  flags {
    nodes {
      flagger {
        username
        id
      }
      reason
      additionalDetails
    }
  }
  revision {
    metadata {
      perspective {
        score
      }
    }
    id
  }
}

fragment CommentRevisionContainer_comment on Comment {
  revision {
    id
  }
  revisionHistory {
    id
    body
    createdAt
  }
}

fragment LinkDetailsContainer_comment on Comment {
  id
  story {
    id
    url
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "userID",
            "type": "ID!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "count",
            "type": "Int!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "cursor",
            "type": "Cursor",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "userID"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "username",
        "args": null,
        "storageKey": null
    } as any), v3 = [
        ({
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any),
        ({
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any)
    ], v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "createdAt",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "body",
        "args": null,
        "storageKey": null
    } as any), v7 = [
        (v4 /*: any*/),
        (v2 /*: any*/)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "UserHistoryDrawerRejectedCommentsPaginationQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "UserHistoryDrawerRejectedComments_user",
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "count",
                                    "variableName": "count"
                                },
                                {
                                    "kind": "Variable",
                                    "name": "cursor",
                                    "variableName": "cursor"
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "UserHistoryDrawerRejectedCommentsPaginationQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "rejectedComments",
                            "storageKey": null,
                            "args": (v3 /*: any*/),
                            "concreteType": "CommentsConnection",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "edges",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "CommentEdge",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "node",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "Comment",
                                            "plural": false,
                                            "selections": [
                                                (v4 /*: any*/),
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "author",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "User",
                                                    "plural": false,
                                                    "selections": [
                                                        (v4 /*: any*/),
                                                        (v2 /*: any*/),
                                                        {
                                                            "kind": "LinkedField",
                                                            "alias": null,
                                                            "name": "status",
                                                            "storageKey": null,
                                                            "args": null,
                                                            "concreteType": "UserStatus",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "kind": "ScalarField",
                                                                    "alias": null,
                                                                    "name": "current",
                                                                    "args": null,
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "kind": "LinkedField",
                                                                    "alias": null,
                                                                    "name": "ban",
                                                                    "storageKey": null,
                                                                    "args": null,
                                                                    "concreteType": "BanStatus",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        {
                                                                            "kind": "ScalarField",
                                                                            "alias": null,
                                                                            "name": "active",
                                                                            "args": null,
                                                                            "storageKey": null
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                (v5 /*: any*/),
                                                (v6 /*: any*/),
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "tags",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "Tag",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "kind": "ScalarField",
                                                            "alias": null,
                                                            "name": "code",
                                                            "args": null,
                                                            "storageKey": null
                                                        }
                                                    ]
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "status",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "revision",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "CommentRevision",
                                                    "plural": false,
                                                    "selections": [
                                                        (v4 /*: any*/),
                                                        {
                                                            "kind": "LinkedField",
                                                            "alias": null,
                                                            "name": "metadata",
                                                            "storageKey": null,
                                                            "args": null,
                                                            "concreteType": "CommentRevisionMetadata",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "kind": "LinkedField",
                                                                    "alias": null,
                                                                    "name": "perspective",
                                                                    "storageKey": null,
                                                                    "args": null,
                                                                    "concreteType": "CommentRevisionPerspectiveMetadata",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        {
                                                                            "kind": "ScalarField",
                                                                            "alias": null,
                                                                            "name": "score",
                                                                            "args": null,
                                                                            "storageKey": null
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "kind": "LinkedField",
                                                            "alias": null,
                                                            "name": "actionCounts",
                                                            "storageKey": null,
                                                            "args": null,
                                                            "concreteType": "ActionCounts",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "kind": "LinkedField",
                                                                    "alias": null,
                                                                    "name": "flag",
                                                                    "storageKey": null,
                                                                    "args": null,
                                                                    "concreteType": "FlagActionCounts",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        {
                                                                            "kind": "LinkedField",
                                                                            "alias": null,
                                                                            "name": "reasons",
                                                                            "storageKey": null,
                                                                            "args": null,
                                                                            "concreteType": "FlagReasonActionCounts",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                {
                                                                                    "kind": "ScalarField",
                                                                                    "alias": null,
                                                                                    "name": "COMMENT_DETECTED_TOXIC",
                                                                                    "args": null,
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "kind": "ScalarField",
                                                                                    "alias": null,
                                                                                    "name": "COMMENT_DETECTED_SPAM",
                                                                                    "args": null,
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "kind": "ScalarField",
                                                                                    "alias": null,
                                                                                    "name": "COMMENT_DETECTED_RECENT_HISTORY",
                                                                                    "args": null,
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "kind": "ScalarField",
                                                                                    "alias": null,
                                                                                    "name": "COMMENT_DETECTED_LINKS",
                                                                                    "args": null,
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "kind": "ScalarField",
                                                                                    "alias": null,
                                                                                    "name": "COMMENT_DETECTED_BANNED_WORD",
                                                                                    "args": null,
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "kind": "ScalarField",
                                                                                    "alias": null,
                                                                                    "name": "COMMENT_DETECTED_SUSPECT_WORD",
                                                                                    "args": null,
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "kind": "ScalarField",
                                                                                    "alias": null,
                                                                                    "name": "COMMENT_REPORTED_OFFENSIVE",
                                                                                    "args": null,
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "kind": "ScalarField",
                                                                                    "alias": null,
                                                                                    "name": "COMMENT_REPORTED_SPAM",
                                                                                    "args": null,
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "kind": "ScalarField",
                                                                                    "alias": null,
                                                                                    "name": "COMMENT_DETECTED_NEW_COMMENTER",
                                                                                    "args": null,
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "kind": "ScalarField",
                                                                                    "alias": null,
                                                                                    "name": "COMMENT_DETECTED_REPEAT_POST",
                                                                                    "args": null,
                                                                                    "storageKey": null
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "editing",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "EditInfo",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "kind": "ScalarField",
                                                            "alias": null,
                                                            "name": "edited",
                                                            "args": null,
                                                            "storageKey": null
                                                        }
                                                    ]
                                                },
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "parent",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "Comment",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "kind": "LinkedField",
                                                            "alias": null,
                                                            "name": "author",
                                                            "storageKey": null,
                                                            "args": null,
                                                            "concreteType": "User",
                                                            "plural": false,
                                                            "selections": (v7 /*: any*/)
                                                        },
                                                        (v4 /*: any*/)
                                                    ]
                                                },
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "story",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "Story",
                                                    "plural": false,
                                                    "selections": [
                                                        (v4 /*: any*/),
                                                        {
                                                            "kind": "LinkedField",
                                                            "alias": null,
                                                            "name": "metadata",
                                                            "storageKey": null,
                                                            "args": null,
                                                            "concreteType": "StoryMetadata",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "kind": "ScalarField",
                                                                    "alias": null,
                                                                    "name": "title",
                                                                    "args": null,
                                                                    "storageKey": null
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "kind": "ScalarField",
                                                            "alias": null,
                                                            "name": "url",
                                                            "args": null,
                                                            "storageKey": null
                                                        }
                                                    ]
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "permalink",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "deleted",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "flags",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "FlagsConnection",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "kind": "LinkedField",
                                                            "alias": null,
                                                            "name": "nodes",
                                                            "storageKey": null,
                                                            "args": null,
                                                            "concreteType": "Flag",
                                                            "plural": true,
                                                            "selections": [
                                                                {
                                                                    "kind": "LinkedField",
                                                                    "alias": null,
                                                                    "name": "flagger",
                                                                    "storageKey": null,
                                                                    "args": null,
                                                                    "concreteType": "User",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v2 /*: any*/),
                                                                        (v4 /*: any*/)
                                                                    ]
                                                                },
                                                                {
                                                                    "kind": "ScalarField",
                                                                    "alias": null,
                                                                    "name": "reason",
                                                                    "args": null,
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "kind": "ScalarField",
                                                                    "alias": null,
                                                                    "name": "additionalDetails",
                                                                    "args": null,
                                                                    "storageKey": null
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "revisionHistory",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "CommentRevision",
                                                    "plural": true,
                                                    "selections": [
                                                        (v4 /*: any*/),
                                                        (v6 /*: any*/),
                                                        (v5 /*: any*/)
                                                    ]
                                                },
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "statusHistory",
                                                    "storageKey": "statusHistory(first:1)",
                                                    "args": [
                                                        {
                                                            "kind": "Literal",
                                                            "name": "first",
                                                            "value": 1
                                                        }
                                                    ],
                                                    "concreteType": "CommentModerationActionConnection",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "kind": "LinkedField",
                                                            "alias": null,
                                                            "name": "edges",
                                                            "storageKey": null,
                                                            "args": null,
                                                            "concreteType": "CommentModerationActionEdge",
                                                            "plural": true,
                                                            "selections": [
                                                                {
                                                                    "kind": "LinkedField",
                                                                    "alias": null,
                                                                    "name": "node",
                                                                    "storageKey": null,
                                                                    "args": null,
                                                                    "concreteType": "CommentModerationAction",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        {
                                                                            "kind": "LinkedField",
                                                                            "alias": null,
                                                                            "name": "moderator",
                                                                            "storageKey": null,
                                                                            "args": null,
                                                                            "concreteType": "User",
                                                                            "plural": false,
                                                                            "selections": (v7 /*: any*/)
                                                                        },
                                                                        (v4 /*: any*/)
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "__typename",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ClientExtension",
                                                    "selections": [
                                                        {
                                                            "kind": "ScalarField",
                                                            "alias": null,
                                                            "name": "statusLiveUpdated",
                                                            "args": null,
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "kind": "ScalarField",
                                                            "alias": null,
                                                            "name": "enteredLive",
                                                            "args": null,
                                                            "storageKey": null
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "cursor",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "pageInfo",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "PageInfo",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "endCursor",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "hasNextPage",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "kind": "LinkedHandle",
                            "alias": null,
                            "name": "rejectedComments",
                            "args": (v3 /*: any*/),
                            "handle": "connection",
                            "key": "UserHistoryDrawer_rejectedComments",
                            "filters": null
                        },
                        (v4 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "UserHistoryDrawerRejectedCommentsPaginationQuery",
            "id": "bc0a1f6dc928ab36118a05d4946e62f2",
            "text": null,
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'a70277c6b2f6fb36ae97a2127dd2bc4a';
export default node;
