/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _InviteCompleteFormContainer_settings$ref: unique symbol;
export type InviteCompleteFormContainer_settings$ref = typeof _InviteCompleteFormContainer_settings$ref;
export type InviteCompleteFormContainer_settings = {
    readonly organization: {
        readonly name: string;
    };
    readonly " $refType": InviteCompleteFormContainer_settings$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "InviteCompleteFormContainer_settings",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "organization",
            "storageKey": null,
            "args": null,
            "concreteType": "Organization",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                }
            ]
        }
    ]
} as any);
(node as any).hash = 'd72f26616c149132a394c66e462a1420';
export default node;
