/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _AccountCompletionContainer_viewer$ref: unique symbol;
export type AccountCompletionContainer_viewer$ref = typeof _AccountCompletionContainer_viewer$ref;
export type AccountCompletionContainer_viewer = {
    readonly username: string | null;
    readonly email: string | null;
    readonly profiles: ReadonlyArray<{
        readonly __typename: string;
    }>;
    readonly " $refType": AccountCompletionContainer_viewer$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "AccountCompletionContainer_viewer",
    "type": "User",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "username",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "profiles",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": true,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                }
            ]
        }
    ]
} as any);
(node as any).hash = 'a3e771ee684c5125ec927b005f4ec829';
export default node;
