/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _InviteUsersContainer_settings$ref: unique symbol;
export type InviteUsersContainer_settings$ref = typeof _InviteUsersContainer_settings$ref;
export type InviteUsersContainer_settings = {
    readonly email: {
        readonly enabled: boolean;
    };
    readonly auth: {
        readonly integrations: {
            readonly local: {
                readonly enabled: boolean;
                readonly allowRegistration: boolean;
                readonly targetFilter: {
                    readonly admin: boolean;
                };
            };
        };
    };
    readonly " $refType": InviteUsersContainer_settings$ref;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "enabled",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Fragment",
        "name": "InviteUsersContainer_settings",
        "type": "Settings",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "email",
                "storageKey": null,
                "args": null,
                "concreteType": "EmailConfiguration",
                "plural": false,
                "selections": [
                    (v0 /*: any*/)
                ]
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "auth",
                "storageKey": null,
                "args": null,
                "concreteType": "Auth",
                "plural": false,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "integrations",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AuthIntegrations",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "local",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "LocalAuthIntegration",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "allowRegistration",
                                        "args": null,
                                        "storageKey": null
                                    },
                                    {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "targetFilter",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "AuthenticationTargetFilter",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "admin",
                                                "args": null,
                                                "storageKey": null
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    } as any;
})();
(node as any).hash = '3c8c802456b96355bd0c4d317a21439e';
export default node;
