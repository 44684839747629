/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { UserRoleChangeContainer_user$ref } from "./UserRoleChangeContainer_user.graphql";
import { UserStatusChangeContainer_user$ref } from "./UserStatusChangeContainer_user.graphql";
declare const _UserRowContainer_user$ref: unique symbol;
export type UserRowContainer_user$ref = typeof _UserRowContainer_user$ref;
export type UserRowContainer_user = {
    readonly id: string;
    readonly username: string | null;
    readonly email: string | null;
    readonly createdAt: string;
    readonly deletedAt: string | null;
    readonly " $fragmentRefs": UserStatusChangeContainer_user$ref & UserRoleChangeContainer_user$ref;
    readonly " $refType": UserRowContainer_user$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "UserRowContainer_user",
    "type": "User",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "username",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "deletedAt",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "FragmentSpread",
            "name": "UserStatusChangeContainer_user",
            "args": null
        },
        {
            "kind": "FragmentSpread",
            "name": "UserRoleChangeContainer_user",
            "args": null
        }
    ]
} as any);
(node as any).hash = '59b2c5233c4e95c6c849c1eefb6aff22';
export default node;
