/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { SSOKeyFieldContainer_sso$ref } from "./SSOKeyFieldContainer_sso.graphql";
declare const _SSOConfigContainer_auth$ref: unique symbol;
export type SSOConfigContainer_auth$ref = typeof _SSOConfigContainer_auth$ref;
export type SSOConfigContainer_auth = {
    readonly integrations: {
        readonly sso: {
            readonly " $fragmentRefs": SSOKeyFieldContainer_sso$ref;
        };
    };
    readonly " $refType": SSOConfigContainer_auth$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "SSOConfigContainer_auth",
    "type": "Auth",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "integrations",
            "storageKey": null,
            "args": null,
            "concreteType": "AuthIntegrations",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "sso",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SSOAuthIntegration",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "SSOKeyFieldContainer_sso",
                            "args": null
                        }
                    ]
                }
            ]
        }
    ]
} as any);
(node as any).hash = '67e8cf9332fe4b4a16e70ec932b5c9ab';
export default node;
