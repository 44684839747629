/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type RegenerateSSOKeyInput = {
    readonly clientMutationId: string;
};
export type RegenerateSSOKeyMutationVariables = {
    readonly input: RegenerateSSOKeyInput;
};
export type RegenerateSSOKeyMutationResponse = {
    readonly regenerateSSOKey: {
        readonly settings: {
            readonly auth: {
                readonly integrations: {
                    readonly sso: {
                        readonly key: string | null;
                        readonly keyGeneratedAt: string | null;
                    };
                };
            };
        } | null;
        readonly clientMutationId: string;
    };
};
export type RegenerateSSOKeyMutation = {
    readonly response: RegenerateSSOKeyMutationResponse;
    readonly variables: RegenerateSSOKeyMutationVariables;
};



/*
mutation RegenerateSSOKeyMutation(
  $input: RegenerateSSOKeyInput!
) {
  regenerateSSOKey(input: $input) {
    settings {
      auth {
        integrations {
          sso {
            key
            keyGeneratedAt
          }
        }
      }
      id
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "RegenerateSSOKeyInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any)
    ], v2 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "auth",
        "storageKey": null,
        "args": null,
        "concreteType": "Auth",
        "plural": false,
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "integrations",
                "storageKey": null,
                "args": null,
                "concreteType": "AuthIntegrations",
                "plural": false,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "sso",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "SSOAuthIntegration",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "key",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "keyGeneratedAt",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    }
                ]
            }
        ]
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "RegenerateSSOKeyMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "regenerateSSOKey",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "RegenerateSSOKeyPayload",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "settings",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Settings",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/)
                            ]
                        },
                        (v3 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "RegenerateSSOKeyMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "regenerateSSOKey",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "RegenerateSSOKeyPayload",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "settings",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Settings",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "id",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        (v3 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "mutation",
            "name": "RegenerateSSOKeyMutation",
            "id": "f8b5f594ef3e8edf5a50c82fefbaa764",
            "text": null,
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'c6fa634d46c7333f154354a7c2e7400a';
export default node;
