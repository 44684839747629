/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { SlackConfigContainer_settings$ref } from "./SlackConfigContainer_settings.graphql";
export type SlackConfigRouteQueryVariables = {};
export type SlackConfigRouteQueryResponse = {
    readonly settings: {
        readonly " $fragmentRefs": SlackConfigContainer_settings$ref;
    };
};
export type SlackConfigRouteQuery = {
    readonly response: SlackConfigRouteQueryResponse;
    readonly variables: SlackConfigRouteQueryVariables;
};



/*
query SlackConfigRouteQuery {
  settings {
    ...SlackConfigContainer_settings
    id
  }
}

fragment SlackConfigContainer_settings on Settings {
  slack {
    channels {
      enabled
      name
      hookURL
      triggers {
        reportedComments
        pendingComments
        featuredComments
      }
    }
  }
}
*/

const node: ConcreteRequest = ({
    "kind": "Request",
    "fragment": {
        "kind": "Fragment",
        "name": "SlackConfigRouteQuery",
        "type": "Query",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "settings",
                "storageKey": null,
                "args": null,
                "concreteType": "Settings",
                "plural": false,
                "selections": [
                    {
                        "kind": "FragmentSpread",
                        "name": "SlackConfigContainer_settings",
                        "args": null
                    }
                ]
            }
        ]
    },
    "operation": {
        "kind": "Operation",
        "name": "SlackConfigRouteQuery",
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "settings",
                "storageKey": null,
                "args": null,
                "concreteType": "Settings",
                "plural": false,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "slack",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "SlackConfiguration",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "channels",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "SlackChannel",
                                "plural": true,
                                "selections": [
                                    {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "enabled",
                                        "args": null,
                                        "storageKey": null
                                    },
                                    {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "name",
                                        "args": null,
                                        "storageKey": null
                                    },
                                    {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "hookURL",
                                        "args": null,
                                        "storageKey": null
                                    },
                                    {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "triggers",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "SlackChannelTriggers",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "reportedComments",
                                                "args": null,
                                                "storageKey": null
                                            },
                                            {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "pendingComments",
                                                "args": null,
                                                "storageKey": null
                                            },
                                            {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "featuredComments",
                                                "args": null,
                                                "storageKey": null
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "id",
                        "args": null,
                        "storageKey": null
                    }
                ]
            }
        ]
    },
    "params": {
        "operationKind": "query",
        "name": "SlackConfigRouteQuery",
        "id": "526fe71c64527fbd12b936a747584d45",
        "text": null,
        "metadata": {}
    }
} as any);
(node as any).hash = 'ae3af33bb97800be86a85968106d6e81';
export default node;
