/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _LocalAuthConfig_formValues$ref: unique symbol;
export type LocalAuthConfig_formValues$ref = typeof _LocalAuthConfig_formValues$ref;
export type LocalAuthConfig_formValues = {
    readonly integrations: {
        readonly local: {
            readonly enabled: boolean;
            readonly allowRegistration: boolean;
            readonly targetFilter: {
                readonly admin: boolean;
                readonly stream: boolean;
            };
        };
    };
    readonly " $refType": LocalAuthConfig_formValues$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "LocalAuthConfig_formValues",
    "type": "Auth",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "integrations",
            "storageKey": null,
            "args": null,
            "concreteType": "AuthIntegrations",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "local",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LocalAuthIntegration",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "enabled",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "allowRegistration",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "targetFilter",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "AuthenticationTargetFilter",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "admin",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "stream",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
} as any);
(node as any).hash = '79895e5398d18c5216df57c0963e1dd1';
export default node;
