/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
import { CommentAuthorContainer_comment$ref } from "./CommentAuthorContainer_comment.graphql";
import { MarkersContainer_comment$ref } from "./MarkersContainer_comment.graphql";
import { ModeratedByContainer_comment$ref } from "./ModeratedByContainer_comment.graphql";
export type COMMENT_STATUS = "APPROVED" | "NONE" | "PREMOD" | "REJECTED" | "SYSTEM_WITHHELD" | "%future added value";
export type TAG = "FEATURED" | "STAFF" | "%future added value";
export type USER_STATUS = "ACTIVE" | "BANNED" | "PREMOD" | "SUSPENDED" | "%future added value";
declare const _ModerateCardContainer_comment$ref: unique symbol;
export type ModerateCardContainer_comment$ref = typeof _ModerateCardContainer_comment$ref;
export type ModerateCardContainer_comment = {
    readonly id: string;
    readonly author: {
        readonly id: string;
        readonly username: string | null;
        readonly status: {
            readonly current: ReadonlyArray<USER_STATUS>;
        };
    } | null;
    readonly statusLiveUpdated: boolean | null;
    readonly createdAt: string;
    readonly body: string | null;
    readonly tags: ReadonlyArray<{
        readonly code: TAG;
    }>;
    readonly status: COMMENT_STATUS;
    readonly revision: {
        readonly id: string;
    } | null;
    readonly editing: {
        readonly edited: boolean;
    };
    readonly parent: {
        readonly author: {
            readonly id: string;
            readonly username: string | null;
        } | null;
    } | null;
    readonly story: {
        readonly id: string;
        readonly metadata: {
            readonly title: string | null;
        } | null;
    };
    readonly permalink: string;
    readonly enteredLive: boolean | null;
    readonly deleted: boolean | null;
    readonly " $fragmentRefs": MarkersContainer_comment$ref & ModeratedByContainer_comment$ref & CommentAuthorContainer_comment$ref;
    readonly " $refType": ModerateCardContainer_comment$ref;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "username",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Fragment",
        "name": "ModerateCardContainer_comment",
        "type": "Comment",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            (v0 /*: any*/),
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "author",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    (v1 /*: any*/),
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "status",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserStatus",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "current",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    }
                ]
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "createdAt",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "body",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "tags",
                "storageKey": null,
                "args": null,
                "concreteType": "Tag",
                "plural": true,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "code",
                        "args": null,
                        "storageKey": null
                    }
                ]
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "status",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "revision",
                "storageKey": null,
                "args": null,
                "concreteType": "CommentRevision",
                "plural": false,
                "selections": [
                    (v0 /*: any*/)
                ]
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "editing",
                "storageKey": null,
                "args": null,
                "concreteType": "EditInfo",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "edited",
                        "args": null,
                        "storageKey": null
                    }
                ]
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "parent",
                "storageKey": null,
                "args": null,
                "concreteType": "Comment",
                "plural": false,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "author",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            (v1 /*: any*/)
                        ]
                    }
                ]
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "story",
                "storageKey": null,
                "args": null,
                "concreteType": "Story",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "metadata",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StoryMetadata",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "title",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    }
                ]
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "permalink",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "deleted",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "FragmentSpread",
                "name": "MarkersContainer_comment",
                "args": null
            },
            {
                "kind": "FragmentSpread",
                "name": "ModeratedByContainer_comment",
                "args": null
            },
            {
                "kind": "FragmentSpread",
                "name": "CommentAuthorContainer_comment",
                "args": null
            },
            {
                "kind": "ClientExtension",
                "selections": [
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "statusLiveUpdated",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "enteredLive",
                        "args": null,
                        "storageKey": null
                    }
                ]
            }
        ]
    } as any;
})();
(node as any).hash = 'decae21f5a19fa3478a99721d314f9e3';
export default node;
