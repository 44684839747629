/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _GuidelinesConfig_formValues$ref: unique symbol;
export type GuidelinesConfig_formValues$ref = typeof _GuidelinesConfig_formValues$ref;
export type GuidelinesConfig_formValues = {
    readonly communityGuidelines: {
        readonly enabled: boolean;
        readonly content: string | null;
    };
    readonly " $refType": GuidelinesConfig_formValues$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "GuidelinesConfig_formValues",
    "type": "Settings",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "communityGuidelines",
            "storageKey": null,
            "args": null,
            "concreteType": "CommunityGuidelines",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "enabled",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "content",
                    "args": null,
                    "storageKey": null
                }
            ]
        }
    ]
} as any);
(node as any).hash = 'd954220afa0c8b7fc5fb2d3a7d2bb8e9';
export default node;
