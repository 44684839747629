/* tslint:disable */

import { ReaderFragment } from "relay-runtime";
declare const _UserBadgesContainer_user$ref: unique symbol;
export type UserBadgesContainer_user$ref = typeof _UserBadgesContainer_user$ref;
export type UserBadgesContainer_user = {
    readonly badges: ReadonlyArray<string> | null;
    readonly " $refType": UserBadgesContainer_user$ref;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "UserBadgesContainer_user",
    "type": "User",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "badges",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = '4c39ffc767e945d17d222882f6c0d51e';
export default node;
